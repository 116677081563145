import React, { useState, useEffect } from 'react';
import styles from './add.module.css';
import { Breadcrumbs } from '@mui/material';
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import axios from 'axios';
import { toast } from 'react-smart-toaster';
import { FromLoader } from '../../components/LoaderC';
import Loader from '../../components/Loader';
import withAdminAuth from "../../components/withAdminAuth";
import { authData } from "../../components/getAuth";
import { Editor } from '@tinymce/tinymce-react';

function About({ history, match }) {
  const auth = authData();
  const audience = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(true);
  const [editorStoryVal, setEditorStoryVal] = useState('');
  const [editorMissionVal, setEditorMissionVal] = useState('');
  const [editorVissionVal, setEditorVissionVal] = useState('');
  const schema = yup.object().shape({
    story_title: yup.string().required('This field is Required'),
    story_description: yup.string().required('This field is Required'),
    mission_title: yup.string().required('This field is Required'),
    mission_description: yup.string().required('This field is Required'),
    vission_title: yup.string().required('This field is Required'),
    vission_description: yup.string().required('This field is Required'),
  });

  const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm({
    resolver: yupResolver(schema), mode: 'onChange'
  });

  useEffect(() => {
    if (contentLoading) {
      axios.get(audience + 'v1/page-manager/about-us', {
        headers: {
          Authorization: `Bearer ${auth?.api_token}`,
        }
      }).then(response => {
        setContentLoading(false);
        let itemData = response.data.data;
        itemData = JSON.parse(itemData.description);
        setValue('story_title', itemData.story_title);
        setEditorStoryVal(itemData.story_description);
        setValue('story_description', itemData.story_description);

        setValue('mission_title', itemData.mission_title);
        setEditorMissionVal(itemData.mission_description);
        setValue('mission_description', itemData.story_description);

        setValue('vission_title', itemData.vission_title);
        setEditorVissionVal(itemData.vission_description);
        setValue('vission_description', itemData.story_description);

      }).catch(error => {
        setContentLoading(false);
      });
    }

  }, [audience, setValue, contentLoading, auth]);

  async function onSubmit(data) {
    let postData = {
      title: 'about-us',
      description: JSON.stringify(data),
    }
    setLoading(true);
    return axios.post(audience + 'v1/page-manager', postData, {
      headers: {
        Authorization: `Bearer ${auth?.api_token}`,
      }
    }).then(response => {
      setLoading(false);
      toast.success(response.data.message);
      navigate('/admin/page-manager/about');
    }).catch(error => {
      setLoading(false);
    });

  }
  const handleEditorStoryChange = (content, editor) => {
    setEditorStoryVal(content);
    setValue('story_description', content);
  }
  const handleEditorMissionChange = (content, editor) => {
    setEditorMissionVal(content);
    setValue('mission_description', content);
  }
  const handleEditorVissionChange = (content, editor) => {
    setEditorVissionVal(content);
    setValue('vission_description', content);
  }

  return (<React.Fragment>

    {loading && <Loader />}

    {contentLoading && <div className="LoaderCard"><div className="Loader FromLoader"><FromLoader /></div></div>}

    {!contentLoading && <div className={`${styles.MainDiv}`}>
      <div className={`${styles.PageTitleWrap}`}>
        <p className={`${styles.PageTitle}`}>About Us</p>
        <Breadcrumbs aria-label="breadcrumb" className='breadcrumb'>
          <Link underline="hover" color="inherit" to="/admin/dashboard">Dashboard</Link>
        </Breadcrumbs>
      </div>

      <div className={`${styles.MainCard}`}>
        <div className={`${styles.AddUserMain}`}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroupFull}`}>
                <label className={`${styles.FormLabel}`}>Story Title*</label>
                <input {...register("story_title")} className={`${styles.FormControl}`} />
                {errors.story_title && !watch().story_title && <span className={`${styles.ErrorM}`}>{errors.story_title.message}</span>}
              </div>
            </div>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroupFull}`}>
                <label className={`${styles.FormLabel}`}>Story Description*</label>
                <input type="hidden" {...register("story_description")} className={`${styles.FormControl}`} />
                <Editor onEditorChange={handleEditorStoryChange} value={editorStoryVal}
                  apiKey='esnq02ea10s4qrcjx6m5z9h03c3iln9jfml86ysvw8legkpo'
                  init={{
                    height: 400,
                    menubar: false,
                    plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
                    toolbar:
                      'undo redo | bold italic underline strikethrough | fontselect fontsizeselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat |  fullscreen  | image media link | ltr rtl | code'
                  }}
                />
                {errors.story_description && !watch().story_description && <span className={`${styles.ErrorM}`}>{errors.story_description.message}</span>}
              </div>
            </div>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroupFull}`}>
                <label className={`${styles.FormLabel}`}>Mission Title*</label>
                <input {...register("mission_title")} className={`${styles.FormControl}`} />
                {errors.mission_title && !watch().mission_title && <span className={`${styles.ErrorM}`}>{errors.mission_title.message}</span>}
              </div>
            </div>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroupFull}`}>
                <label className={`${styles.FormLabel}`}>Mission Description*</label>
                <input type="hidden" {...register("mission_description")} className={`${styles.FormControl}`} />
                <Editor onEditorChange={handleEditorMissionChange} value={editorMissionVal}
                  apiKey='esnq02ea10s4qrcjx6m5z9h03c3iln9jfml86ysvw8legkpo'
                  init={{
                    height: 400,
                    menubar: false,
                    plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
                    toolbar:
                      'undo redo | bold italic underline strikethrough | fontselect fontsizeselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat |  fullscreen  | image media link | ltr rtl | code'
                  }}
                />
                {errors.mission_description && !watch().mission_description && <span className={`${styles.ErrorM}`}>{errors.mission_description.message}</span>}
              </div>
            </div>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroupFull}`}>
                <label className={`${styles.FormLabel}`}>Vission Title*</label>
                <input {...register("vission_title")} className={`${styles.FormControl}`} />

                {errors.vission_title && !watch().vission_title && <span className={`${styles.ErrorM}`}>{errors.vission_title.message}</span>}
              </div>
            </div>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroupFull}`}>
                <label className={`${styles.FormLabel}`}>Vission Description*</label>
                <input type="hidden" {...register("vission_description")} className={`${styles.FormControl}`} />
                <Editor onEditorChange={handleEditorVissionChange} value={editorVissionVal}
                  apiKey='esnq02ea10s4qrcjx6m5z9h03c3iln9jfml86ysvw8legkpo'
                  init={{
                    height: 400,
                    menubar: false,
                    plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
                    toolbar:
                      'undo redo | bold italic underline strikethrough | fontselect fontsizeselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat |  fullscreen  | image media link | ltr rtl | code'
                  }}
                />
                {errors.vission_description && !watch().vission_description && <span className={`${styles.ErrorM}`}>{errors.vission_description.message}</span>}
              </div>
            </div>
            <div className={`${styles.FormButtonArea}`}>
              <input className={`${styles.SubmitBU}`} value="Update" type="submit" />
            </div>
          </form>
        </div>
      </div>
    </div>}
  </React.Fragment>)
}

export default withAdminAuth(About);