import React, { useState, useEffect } from 'react';
import styles from './contactus.module.css';
import { Parallax } from 'react-parallax';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import axios from 'axios';
import { toast } from 'react-smart-toaster';
import ReCAPTCHA from "react-google-recaptcha";
import { Clock, Phone } from 'react-feather';
import Iframe from '../../components/iframe2';

const phoneRegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
const schema = yup.object().shape({
  name: yup.string().required('This field is Required'),
  subject: yup.string().required('This field is Required'),
  email: yup.string().required('This field is required').email(('Invalid E-mail')),
  phone: yup.string().required('This field is required').matches(phoneRegExp, { message: "Please enter a valid phone number", excludeEmptyString: true }),
  message: yup.string().required('This field is Required'),
});

export default function ContactUs() {
  const audience = process.env.REACT_APP_API_URL;
  const CAPTCHAKEY = process.env.REACT_APP_CAPTCHA_SITE_KEY;
  const [captchaValue, setCaptchaValue] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [isMessage, setIsMessage] = useState(false);
  const [contentList, setContentList] = React.useState([]);
  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    resolver: yupResolver(schema), mode: 'onChange'
  });

  useEffect(() => {
    axios(process.env.REACT_APP_API_URL + 'v1/page-manager/contact').then(response => {
      let itemData = response.data.data;
      itemData = JSON.parse(itemData.description);
      setContentList(itemData);
    }).catch(error => {
      setContentList([]);
    });
  }, []);

  const recaptchaRef = React.useRef();
  function onChange2(value) {
    setCaptchaValue(value);
  }

  async function onSubmit(data) {
    if (captchaValue === '') {
      setIsValid(true);
      return;
    }
    let postData = data;

    return axios.post(audience + 'v1/contact-us', postData).then(response => {
      toast.success(response.data.message);
      setIsMessage(true);
      reset({ name: "", email: "", phone: "", subject: "", message: "" });
      recaptchaRef.current.reset();
      setIsValid(false);
      setTimeout(() => {
        setIsMessage(false);
      }, 8000);
    });
  }

  return (<React.Fragment>
    <div className="InnerBanner">
      <Parallax bgImage="/images/contactus-banner.jpg" strength={400}>
        <div style={{ height: 450 }} className={`${styles.BannerConArea}`}>
          <div className='container'>
            <div className={`${styles.InnerBannerRow}`}>
              <h2 className={`${styles.InnerBannerTitle}`}>Contact Us</h2>
            </div>
          </div>
        </div>
      </Parallax>
    </div>

    <div className={`${styles.ServiceSec}`}>
      <div className={`${styles.ContactImg}`}>
        <Iframe source={'https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d33796.64732039928!2d-172.0176135010255!3d-13.841572878534288!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sSecond%20Floor%2C%20Booth%20N%C5%8D.55%2C%20Faleolo%20International%20Airport%20%2C%20Samoa!5e0!3m2!1sen!2sin!4v1675973381523!5m2!1sen!2sin'} />
      </div>
      <Fade bottom>
        <div className={`${styles.CusCon}`}>
          <div className={`${styles.ContactRow}`}>
            <div className={`${styles.ContactForm}`}>
              {isMessage && <div className={`${styles.SubmittedMessage}`}>Thanks for contacting us! We will get in touch with you shortly.</div>}
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className={`${styles.FormRow}`}>
                  <div className={`${styles.FormGroup}`}>
                    <label className={`${styles.FormLabel}`}>Name*</label>
                    <input type="text" {...register("name")} className={`${styles.FormControl}`} />
                    {errors.name && <span className={`${styles.ErrorM}`}>{errors.name.message}</span>}
                  </div>
                  <div className={`${styles.FormGroup}`}>
                    <label className={`${styles.FormLabel}`}>Phone Number*</label>
                    <input type="number" {...register("phone")} className={`${styles.FormControl}`} />
                    {errors.phone && <span className={`${styles.ErrorM}`}>{errors.phone.message}</span>}
                  </div>
                  <div className={`${styles.FormGroup}`}>
                    <label className={`${styles.FormLabel}`}>Email*</label>
                    <input type="email" {...register("email")} className={`${styles.FormControl}`} />
                    {errors.email && <span className={`${styles.ErrorM}`}>{errors.email.message}</span>}
                  </div>
                  <div className={`${styles.FormGroup}`}>
                    <label className={`${styles.FormLabel}`}>Subject*</label>
                    <input type="text" {...register("subject")} className={`${styles.FormControl}`} />
                    {errors.subject && <span className={`${styles.ErrorM}`}>{errors.subject.message}</span>}
                  </div>
                  <div className={`${styles.FormGroup}`}>
                    <label className={`${styles.FormLabel}`}>Type message here*</label>
                    <textarea className={`${styles.FormControlTextarea}`} {...register("message")} />
                    {errors.message && <span className={`${styles.ErrorM}`}>{errors.message.message}</span>}
                  </div>
                  <div className={`${styles.FormGroup}`}>
                    <ReCAPTCHA sitekey={CAPTCHAKEY} onChange={onChange2} ref={recaptchaRef} />
                    {isValid && <p className={`${styles.ErrorM}`}>Please fill the captcha</p>}
                  </div>
                  <div className={`${styles.ContactFormBuSec}`}>
                    <button type='submit' className={`${styles.HeaderBU}`}><span>Submit</span></button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Fade>
    </div>

    <div className={`${styles.ConCardSec}`}>
      <div className='container'>
        <div className={`${styles.CardRow}`}>
          <Zoom>
            <div className={`${styles.contactTab}`}>
              <div className={`${styles.contactTabInside}`}>
                <div className={`${styles.ContactIcon}`}><Phone /></div>
                <h5>{contentList?.office_time_title}</h5>
                <a className={`${styles.InsideDes}`} href={`mailto:${contentList?.email}`}>{contentList?.email}</a>
                <a className={`${styles.InsideDes}`} href={`tel:${contentList?.phone_1}`}>{contentList?.phone_1} (SAM)</a>
                <a className={`${styles.InsideDes}`} href={`tel:${contentList?.phone_2}`}>{contentList?.phone_2} (US)</a>
              </div>
            </div>
          </Zoom>
          <Zoom>
            <div className={`${styles.contactTab}`}>
              <div className={`${styles.contactTabInside}`}>
                <div className={`${styles.ContactIcon}`}><Clock /></div>
                <h5>OFFICE HOURS</h5>
                <p className={`${styles.InsideDes}`}>Mon - Fri<br />Open 8:30 am - 5:30 pm</p>
                <p className={`${styles.InsideDes}`}>Sat<br />Open 8:30 am - 3:30 pm</p>
                <p className={`${styles.InsideDes}`}>Closed Sundays</p>
              </div>
            </div>
          </Zoom>
        </div>
      </div>
    </div>
  </React.Fragment>)
}