import { Navigate } from "react-router-dom";

const withAdminAuth = (WrappedComponent) => {
  return (props) => {
    if (typeof window !== "undefined") {
      const authData = localStorage.getItem("authData");
      if (authData) {
        let authDataArr = JSON.parse(authData);
        return ((authDataArr?.role === 1) ? <WrappedComponent {...props} /> : <Navigate to={'/customer/my-bookings'} />);
      } else {
        return <Navigate to={'/sign-in'} />
      }
    }
    return null;
  };
};

export default withAdminAuth;