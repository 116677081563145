import React, { useState, useEffect } from "react";
import styles from "./add.module.css";
import { Typography, Breadcrumbs, Button, Checkbox, FormControlLabel } from "@mui/material";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { NumericFormat } from "react-number-format";
import { toast } from "react-smart-toaster";
import { FromLoader } from "../../components/LoaderC";
import Loader from "../../components/Loader";
import withAdminAuth from "../../components/withAdminAuth";
import { authData } from "../../components/getAuth";
import Select from "react-select";

const TypeOptions = [
  { value: "Pick-Up Only", label: "Pick-Up Only" },
  { value: "Drop-Off Only", label: "Drop-Off Only" },
  { value: "Both", label: "Both" },
];

function LocationAdd({ history, match }) {
  const auth = authData();
  const audience = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  let { id } = useParams();
  const isAddMode = !id;
  const [loading, setLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(true);

  const schema = yup.object().shape({
    name: yup.string().required("This field is Required").max(100, "Name cannot be more than 100 characters"),
    delivery_fee: yup.string().required("This field is Required"),
    type: yup.object().required("This field is Required"),
  });

  const { register, handleSubmit, control, watch, setValue, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      is_airport: false
    }
  });

  useEffect(() => {
    if (!isAddMode) {
      if (contentLoading) {
        axios.get(audience + "v1/locations/" + id, {
          headers: {
            Authorization: `Bearer ${auth?.api_token}`,
          }
        }).then((response) => {
          setContentLoading(false);
          let itemData = response.data.data;
          setValue("name", itemData.name);
          setValue("type", { value: itemData.type, label: itemData.type });
          setValue("delivery_fee", itemData.delivery_fee);
          setValue("is_airport", itemData?.is_airport === 1 ? true : false);
        }).catch((error) => {
          setContentLoading(false);
        });
      }
    } else {
      setContentLoading(false);
    }
  }, [isAddMode, audience, id, setValue, contentLoading, auth]);

  async function onSubmit(data) {
    data = { ...data, type: data?.type?.value, is_airport: data?.is_airport ? 1 : 0 };
    let formData = new FormData();
    Object.keys(data).map((key) => {
      if (key !== "imagefile") {
        formData.append(key, data[key]);
      }
      return true;
    });

    const postData = data;
    setLoading(true);

    if (isAddMode) {
      return axios.post(audience + "v1/locations", postData, {
        headers: {
          Authorization: `Bearer ${auth?.api_token}`,
        }
      }).then((response) => {
        setLoading(false);
        toast.success(response.data.message);
        navigate("/admin/location/list");
      }).catch((error) => {
        setLoading(false);
      });
    } else {
      return axios.post(audience + "v1/locations/" + id, postData, {
        headers: {
          Authorization: `Bearer ${auth?.api_token}`,
        }
      }).then((response) => {
        setLoading(false);
        toast.success(response.data.message);
        navigate("/admin/location/list");
      }).catch((error) => {
        setLoading(false);
      });
    }
  }

  const TypeField = React.forwardRef((field, ref) => {
    return (<Controller
      control={control}
      options={TypeOptions}
      {...register("type")}
      render={({ field }) => <Select {...field} options={TypeOptions} />}
    />);
  });


  return (<React.Fragment>

    {loading && <Loader />}

    {contentLoading && (<div className="LoaderCard"><div className="Loader FromLoader"><FromLoader /></div></div>)}

    {!contentLoading && (<div className={`${styles.MainDiv}`}>
      <div className={`${styles.PageTitleWrap}`}>
        <p className={`${styles.PageTitle}`}>Location {isAddMode ? "Add" : "Edit"}</p>
        <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
          <Link underline="hover" color="inherit" to="/admin/dashboard">Dashboard</Link>
          <Link underline="hover" color="inherit" to={"/admin/location/list"}>Locations</Link>
          <Typography color="text.primary">{isAddMode ? "Add" : "Edit"}</Typography>
        </Breadcrumbs>
      </div>

      <div className={`${styles.MainCard}`}>
        <div className={`${styles.AddUserMain}`}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroup}`}>
                <label className={`${styles.FormLabel}`}>Name*</label>
                <input {...register("name")} className={`${styles.FormControl}`} />
                {errors.name && (<span className={`${styles.ErrorM}`}>{errors.name.message}</span>)}
              </div>
              <div className={`${styles.FormGroup}`}>
                <label className={`${styles.FormLabel}`}>Type*</label>
                <TypeField />
                {errors.type && !watch().type && (<span className={`${styles.ErrorM}`}>{errors.type.message}</span>)}
              </div>
            </div>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroup}`}>
                <label className={`${styles.FormLabel}`}>Delivery Fee(WST)*</label>
                <Controller
                  control={control}
                  name="delivery_fee"
                  className={`${styles.FormControl}`}
                  render={({ field: { onChange, name, value } }) => (
                    <NumericFormat
                      decimalScale={2}
                      name={name}
                      value={value}
                      onChange={onChange}
                      className={`${styles.FormControl}`}
                    />
                  )}
                />
                {errors.delivery_fee && (<span className={`${styles.ErrorM}`}>{errors.delivery_fee.message}</span>)}
              </div>
              <div className={`${styles.FormGroup}`}>
                <label className={`${styles.FormLabel}`}>Airport?</label>
                <Controller
                  control={control}
                  name="is_airport"
                  render={({ field }) => (<div><FormControlLabel {...field} control={<Checkbox checked={field?.value} />} label="Yes" /></div>)}
                />
              </div>
            </div>
            <div className={`${styles.FormButtonArea}`}>
              <Link to={`/admin/location/list`} as={Button} className={`${styles.CancelBU}`}>Cancel</Link>
              <input className={`${styles.SubmitBU}`} value="Submit" type="submit" />
            </div>
          </form>
        </div>
      </div>
    </div>)}
  </React.Fragment>);
}

export default withAdminAuth(LocationAdd);