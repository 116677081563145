import React, { useState } from 'react';
import styles from './signup.module.css';
import { useNavigate, Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import axios from 'axios';
import Loader from '../../../admin/components/Loader';
import { Home } from 'react-feather';
import { PatternFormat } from 'react-number-format';
import Captcha from "demos-react-captcha";

function Signup() {
  const [loading, setLoading] = useState(false);
  const [errorMsg, seterrorMsg] = useState('');
  const [captchaData, setCaptchaData] = useState({ loading: false, success: false });
  const audience = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const schema = yup.object().shape({
    first_name: yup.string().required('This field is Required'),
    last_name: yup.string().required('This field is Required'),
    email: yup.string().required('This field is Required').email('Invalid E-mail'),
    phone: yup.string().required('This field is Required'),
    password: yup.string().required('This field is Required').min(6, 'Password length should be 6-15').max(15, 'Password length should be 6-15'),
    conf_password: yup.string().when('password', (password, field) =>
      password ? field.oneOf([yup.ref('password')], 'Passwords must match') : field
    ),
    chooseCb: yup.bool().oneOf([true], 'Please accept agreement.'),
  });
  const { register, handleSubmit, formState: { errors }, control } = useForm({
    resolver: yupResolver(schema), mode: 'onChange'
  });

  function onError(data) {
    setCaptchaData({ ...captchaData, loading: true });
  }

  async function onSubmit(data) {
    setCaptchaData({ ...captchaData, loading: true });
    if (!captchaData.success) {
      return false;
    }
    const token = '';
    delete data.conf_password;
    data = { ...data, role: 2 };
    const postData = data;

    setLoading(true);
    seterrorMsg('');

    return axios.post(audience + 'v1/signup', postData, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }).then(response => {
      const resData = response.data.data;
      localStorage.setItem('authData', JSON.stringify(resData));
      setLoading(false);

      let reDirectUrl = localStorage.getItem("reDirectUrl");
      if (reDirectUrl) {
        localStorage.removeItem("reDirectUrl")
        navigate(reDirectUrl);
      } else {
        navigate('/');
      }

    }).catch(error => {
      if (error && error?.response && error?.response?.data && error?.response?.data?.message) {
        seterrorMsg(error?.response?.data?.message);
      }
      setLoading(false);
    });
  }

  function captchaChange(e) {
    setCaptchaData({ ...captchaData, success: e });
  }

  function captchaRefresh() {
    setCaptchaData({ ...captchaData, success: false });
  }

  return (<React.Fragment>

    {loading && <Loader />}

    <div className={`${styles.MainDiv}`}>
      <div className={`${styles.LoginBGSec}`}>
        <img src="/images/loginBG.jpg" alt="" />
        <img src="/images/moto-logo.svg" alt="" className={`${styles.LoginLogo}`} />
      </div>

      <div className={`${styles.LoginFormSec}`}>
        <Link to={'/'} className={`${styles.BackToHome}`}><Home /> Back to home</Link>
        <div className={`${styles.LohinForm}`}>
          <img src="/images/moto-logo.svg" alt="" className={`${styles.LoginLogoSmRe}`} />
          <h2 className={`${styles.BookingFormTitle}`}>Sign Up</h2>
          <p className={`${styles.LoginformSmTitle}`}>Create an account</p>
          <form onSubmit={handleSubmit(onSubmit, onError)} >
            <p className={`${styles.ErrorM}`}>{errorMsg}</p>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroup}`}>
                <label class={`${styles.FormLabel}`}>First Name*</label>
                <input {...register("first_name")} className={`${styles.FormControl}`} />
                {errors.first_name && <span className={`${styles.ErrorM}`}>{errors.first_name.message}</span>}
              </div>
              <div className={`${styles.FormGroup}`}>
                <label class={`${styles.FormLabel}`}>Last Name*</label>
                <input {...register("last_name")} className={`${styles.FormControl}`} />
                {errors.last_name && <span className={`${styles.ErrorM}`}>{errors.last_name.message}</span>}
              </div>
            </div>

            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroup}`}>
                <label class={`${styles.FormLabel}`}>Email*</label>
                <input {...register("email")} className={`${styles.FormControl}`} />
                {errors.email && <span className={`${styles.ErrorM}`}>{errors.email.message}</span>}
              </div>
              <div className={`${styles.FormGroup}`}>
                <label class={`${styles.FormLabel}`}>Phone Number*</label>
                <Controller
                  control={control}
                  name="phone"
                  {...register("phone")}
                  className={`${styles.FormControl}`}
                  render={({ field: { onChange, name, value } }) => (
                    <PatternFormat
                      format="+############"
                      name={name}
                      value={value}
                      onChange={onChange}
                      className={`${styles.FormControl}`}
                    />
                  )}
                />
                {errors.phone && <span className={`${styles.ErrorM}`}>{errors.phone.message}</span>}
              </div>
            </div>

            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroup}`}>
                <label class={`${styles.FormLabel}`}>Password</label>
                <input {...register("password")} type="password" className={`${styles.FormControl}`} />
                {errors.password && <span className={`${styles.ErrorM}`}>{errors.password.message}</span>}
              </div>
              <div className={`${styles.FormGroup}`}>
                <label class={`${styles.FormLabel}`}>Confirm Password</label>
                <input {...register("conf_password")} type="password" className={`${styles.FormControl}`} />
                {errors.conf_password && <span className={`${styles.ErrorM}`}>{errors.conf_password.message}</span>}
              </div>
            </div>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroup}`}>
                <Captcha placeholder="Enter captcha" onChange={captchaChange} onRefresh={captchaRefresh} className={`${styles.FormControl}`} />
                {(captchaData.loading && !captchaData.success) && <span className={`${styles.ErrorM}`}>Please enter valid captcha</span>}
              </div>
            </div>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.CheckField}`}>
                <input type="checkbox" name="selectCheckbox" id="selectCheckbox" {...register('chooseCb')} className={`form-check-input ${errors.chooseCb ? 'is-invalid' : ''}`} />
                <p className={`${styles.LoginFormSText}`}>By registering, you agree to Motu Car Rentals <Link to={'/terms'} target="_blank">Terms</Link> and <Link to={'/privacy'} target="_blank">Privacy Policy</Link>.</p>
              </div>
              {errors.chooseCb && <span className={`${styles.ErrorM}`}>{errors.chooseCb.message}</span>}
            </div>
            <button type="submit" className={`${styles.LoginBU}`}><span>Register</span></button>
            <p className={`${styles.LoginFormSText}`}>Already have an account? <Link to={'/sign-in'}>LogIn</Link></p>
          </form>
        </div>
      </div>

    </div>
  </React.Fragment>)
}

export default Signup;