import React, { useEffect, useState } from "react";
import styles from "./ferry-ticket.module.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment/moment";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { SmartToaster, toast } from "react-smart-toaster";
import Loader from "../../../admin/components/Loader";
import { Button, Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { X } from "react-feather";
import Select from "react-select";
import { PayPalButtons } from "@paypal/react-paypal-js";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const brandColor = '#cccccc';
const customStyles = {
  control: (base, state) => ({
    ...base,
    boxShadow: state.isFocused ? 0 : 0,
    borderColor: state.isFocused
      ? brandColor
      : base.borderColor,
    '&:hover': {
      borderColor: state.isFocused
        ? brandColor
        : base.borderColor,
    }
  })
};

const FerryTicket = () => {
  const navigate = useNavigate();
  const [bookingId, setBookingId] = useState("");
  const [bookingDetails, setBookingDetails] = useState(null);
  const [ferryList, setFerryList] = useState([]);
  const [ferryTicketList, setFerryTicketList] = useState([]);
  const [selectedFerryData, setSelectedFerryData] = useState(null);
  const [dateRanges, setDateRanges] = useState({ start: null, end: null });
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [totalAmountConverted, setTotalAmountConverted] = useState(0);
  const [events, setEvents] = useState([]);
  const [addFerry, setAddFerry] = useState(false);
  const [currency, setCurrency] = useState("");
  const [currencyRate, setCurrencyRate] = useState(1);
  const [currencyLoading, setCurrencyLoading] = useState(false);
  const [selectedDate1, setSelectedDate1] = useState(null);
  const [selectedDate2, setSelectedDate2] = useState(null);
  const [slotList1, setSlotList1] = useState([]);
  const [slotList2, setSlotList2] = useState([]);
  const [selectedSlot1, setSelectedSlot1] = useState(null);
  const [selectedSlot2, setSelectedSlot2] = useState(null);
  let { bookId } = useParams();

  useEffect(() => {
    window.addEventListener("click", handleDocumentClick);
    return () => window.removeEventListener("click", handleDocumentClick);
  });

  useEffect(() => {
    let currentCurrency = localStorage.getItem("currentCurrency");
    if (currentCurrency) {
      setCurrency(currentCurrency);
      getCurrencyRate(currentCurrency);
    } else {
      setCurrency("USD");
      getCurrencyRate("USD");
    }
  }, []);

  const handleDocumentClick = (event) => {
    if (event.target.getAttribute("cngCurrency")) {
      let currentCurrency = localStorage.getItem("currentCurrency");
      if (currentCurrency) {
        setCurrency(currentCurrency);
        getCurrencyRate(currentCurrency);
      } else {
        setCurrency("USD");
        getCurrencyRate("USD");
      }
    }
  };

  const getCurrencyRate = (selectcurrency) => {
    setCurrencyLoading(true);
    axios(process.env.REACT_APP_API_URL + "v1/currency-convertter/WST/" + selectcurrency).then((response) => {
      setCurrencyRate(response.data.data);
      setCurrencyLoading(false);
    }).catch((error) => {
      setCurrencyLoading(false);
    });
  }

  useEffect(() => {
    if (bookId) {
      setBookingId(bookId);
      setLoading(true);
      axios(process.env.REACT_APP_API_URL + "v1/booking/" + bookId).then((response) => {
        setLoading(false);
        let bookingDataTemp = response.data.data;
        if (!bookingDataTemp) {
          toast.error("Booking Id is not found!");
          return false;
        }
        if (bookingDataTemp && moment() > moment(bookingDataTemp?.start_date)) {
          toast.error("Date is not available!");
          return false;
        }
        setBookingDetails(response.data.data);
      }).catch((error) => {
        setLoading(false);
      });
    } else {
      setBookingId("");
      setBookingDetails(null);
    }
  }, [bookId]);

  useEffect(() => {
    axios(process.env.REACT_APP_API_URL + "v1/ferry-list").then((response) => {
      setFerryList(response.data.data);
    });
  }, []);



  useEffect(() => {
    if (bookingDetails) {
      setDateRanges({ start: bookingDetails?.start_date, end: moment(bookingDetails?.end_date).format("YYYY-MM-DD") });

      const transformedEvents = bookingDetails?.ferry_info.map((booking) => ({
        title: booking.ferry_name,
        start: booking.ferry_booking_date,
        extendedProps: {
          ferry_booking_slot: booking.ferry_booking_slot,
          amount: booking.amount,
          transaction_id: booking.transaction_id,
        },
      }));
      setEvents(transformedEvents);
    } else {
      setDateRanges({ start: null, end: null });
      setEvents([]);
    }
  }, [bookingDetails]);

  useEffect(() => {
    let feryTicketListTemp = ferryList.map((item) => {
      return { ...item, value: item?.id, label: item?.destination_name1 + ' - ' + item?.destination_name2 };
    });
    setFerryTicketList(feryTicketListTemp);
  }, [ferryList]);

  const getBookingDetails = () => {
    if (bookingId !== "") {
      setLoading(true);
      axios(process.env.REACT_APP_API_URL + "v1/booking/" + bookingId).then((response) => {
        setLoading(false);
        let bookingDataTemp = response.data.data;
        if (!bookingDataTemp) {
          toast.error("Booking Id is not found!");
          return false;
        }
        if (bookingDataTemp && moment() > moment(bookingDataTemp?.start_date)) {
          toast.error("Date is not available!");
          return false;
        }
        setBookingDetails(response.data.data);
      }).catch((error) => {
        setLoading(false);
      });
    }
  }

  const renderEventContent = (eventInfo) => {
    return (<>
      <div>{eventInfo.event.title}</div>
      <div>Slot: {moment(eventInfo.event.extendedProps.ferry_booking_slot, "HH:mm:ss").format("hh:mm A")}</div>
      <div>Amount: {!currencyLoading && currency + " " + (eventInfo.event.extendedProps.amount * currencyRate).toFixed(2)}</div>
    </>);
  }

  const cancelFerryPopup = () => {
    setSelectedFerryData(null);
    setAddFerry(false);
    localStorage.removeItem("slotBookingData");
    setModalShow(false);
  }

  const getdFerryData = (e) => {
    setSelectedFerryData(e);
    setSelectedDate1(null);
    setSelectedDate2(null);
    setSelectedSlot1(null);
    setSelectedSlot2(null);
    axios(process.env.REACT_APP_API_URL + "v1/currency-convertter/WST/USD").then((response) => {
      let cRate = response.data.data;
      let amountTemp = cRate * e?.amount;
      amountTemp = amountTemp.toFixed(2);
      setTotalAmountConverted(amountTemp);
    });
  }

  function addSubmit() {
    setAddFerry(true);
  }

  function createOrder(data, actions) {
    return actions.order.create({
      purchase_units: [{
        description: "Ferry slot booking on Motu Car Rental",
        amount: {
          currency_code: "USD",
          value: totalAmountConverted,
        }
      }],
      application_context: {
        shipping_preference: "NO_SHIPPING",
      },
    }).then((orderID) => {
      return orderID;
    });
  }

  const onApprove = (data1, actions) => {
    setLoading(true);
    return actions.order.capture().then(function (details) {
      let purchase_units = details.purchase_units;
      purchase_units = purchase_units[0];
      onSubmit(purchase_units?.payments?.captures[0]?.id);
    });
  };

  const onSubmit = (paymentIdNew) => {
    setModalShow(false);
    let postData = { transaction_id: paymentIdNew, ferry_id: selectedFerryData.id, amount: selectedFerryData?.amount, amount_usd: totalAmountConverted, name1: selectedFerryData?.destination_name1 + ' -> ' + selectedFerryData?.destination_name2, name2: selectedFerryData?.destination_name2 + ' -> ' + selectedFerryData?.destination_name1, slot1: selectedSlot1, slot2: selectedSlot2, date1: moment(selectedDate1).format('YYYY-MM-DD'), date2: moment(selectedDate2).format('YYYY-MM-DD'), booking_id: bookingDetails?.id };

    axios.post(process.env.REACT_APP_API_URL + "v1/ferry-slot-book", postData).then((response) => {
      setLoading(false);
      setSelectedFerryData(null);
      setSelectedDate1(null);
      setSelectedDate2(null);
      setSelectedSlot1(null);
      setSelectedSlot2(null);
      navigate("/ferry-booking-success");
    }).catch((error) => {
      setLoading(false);
      navigate("/ferry-booking-failed");
    });
  }

  const bookFerry = () => {
    if (bookingDetails)
      setModalShow(true);

    return false;
  }

  useEffect(() => {
    if (selectedDate1 && selectedFerryData) {
      let slotDay = moment(selectedDate1).day();

      let slots = selectedFerryData?.available_days1.filter((item, index) => index === slotDay);

      setSlotList1(slots[0]);
    }

  }, [selectedDate1, selectedFerryData]);

  useEffect(() => {
    if (selectedDate2 && selectedFerryData) {
      let slotDay = moment(selectedDate2).day();

      let slots = selectedFerryData?.available_days2.filter((item, index) => index === slotDay);

      setSlotList2(slots[0]);
    }

  }, [selectedDate2, selectedFerryData]);

  return (<React.Fragment>

    {loading && <Loader />}

    <div className={`${styles.BookingDone}`}>
      <div className={`${styles.FerryIDSec}`}>
        <p className={`${styles.Title}`}>Please enter booking id</p>
        <div>
          <input className={`${styles.TextField}`} placeholder="XXXXX052X9XXXXX" type="text" value={bookingId} onChange={(e) => setBookingId(e.target.value)} />
          <input className={`${styles.SubmitBu}`} type="button" value="Search" onClick={(e) => getBookingDetails()} />
        </div>
      </div>
    </div>

    <div className="container">
      <div className={`${styles.card}`}>
        <div className={`${styles.FullCalendar} FullCalendar`}>
          <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            validRange={(e) => {
              return dateRanges;
            }}
            events={events}
            eventContent={renderEventContent}
            customButtons={{
              myCustomButton: {
                text: 'Book Ferry Ticket',
                click: function () {
                  bookFerry()
                },
              }
            }}
            headerToolbar={{
              left: 'title prev,next',
              right: 'myCustomButton'
            }}
          />
        </div>
      </div>
    </div>

    <Dialog
      open={modalShow}
      fullWidth
      maxWidth="sm"
      onClose={cancelFerryPopup}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp CustomWidthPopUs"
    >
      <DialogTitle id="alert-dialog-title">
        <span>Book Ferry Ticket</span>
        <button onClick={cancelFerryPopup}><X /></button>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div className={`${styles.MainCard}`}>
            {!addFerry && (<div className={`${styles.AddUserMain}`}>
              <div className={`${styles.FormRow}`}>
                <div className={`${styles.FormGroup} ${styles.Select}`}>
                  <label className={`${styles.FormLabel}`}>Ferry Ticket</label>
                  <Select styles={customStyles} className={`${styles.CustomSelect}`} placeholder='Select' onChange={getdFerryData} options={ferryTicketList} />
                </div>
              </div>
              {selectedFerryData && (<div>
                <div className={`${styles.FormRow}`}>
                  <div className={`${styles.FormGroup}`}>
                    <label className={`${styles.FormLabel}`}>Amount</label>
                    <p className={`${styles.FerryAmount}`}>{!currencyLoading && currency + " " + (selectedFerryData?.amount * currencyRate).toFixed(2)}</p>
                  </div>
                </div>
                <p>Select Available Slots</p>
                <div>
                  <div>
                    <label className={`${styles.FormLabel}`}>{selectedFerryData?.destination_name1 + ' -> ' + selectedFerryData?.destination_name2}</label>
                    <div className={`${styles.SlotSecMain}`}>
                      <div className={`${styles.WeeklySlotSec}`}>
                        <div className={`${styles.FormGroup} ${styles.Select}`}>
                          <label className={`${styles.FormLabel}`}>Select Date</label>
                          <ReactDatePicker
                            className={`${styles.FormControl}`}
                            selected={selectedDate1}
                            onChange={(e) => setSelectedDate1(e)}
                            minDate={moment(bookingDetails?.start_date).valueOf()}
                            maxDate={moment(bookingDetails?.end_date).subtract(1, 'd').valueOf()}
                          />
                        </div>
                        <div className={`${styles.SelectSec}`}>
                          {slotList1?.map((item, index) => {
                            return (<div onClick={(e) => setSelectedSlot1(item)} key={index} value={item} className={selectedSlot1 === item ? `${styles.SelectBoxSelected}` : `${styles.SelectBox}`}>{moment(item, "HH:mm:ss").format("hh:mm A")}</div>);
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <label className={`${styles.FormLabel}`}>{selectedFerryData?.destination_name2 + ' -> ' + selectedFerryData?.destination_name1}</label>
                    <div className={`${styles.SlotSecMain}`}>
                      <div className={`${styles.WeeklySlotSec}`}>
                        <div className={`${styles.FormGroup} ${styles.Select}`}>
                          <label className={`${styles.FormLabel}`}>Select Date {bookingDetails?.start_date}</label>
                          <ReactDatePicker
                            className={`${styles.FormControl}`}
                            selected={selectedDate2}
                            onChange={(e) => setSelectedDate2(e)}
                            minDate={moment(bookingDetails?.start_date).valueOf()}
                            maxDate={moment(bookingDetails?.end_date).subtract(1, 'd').valueOf()}
                          />
                        </div>
                        <div className={`${styles.SelectSec}`}>
                          {slotList2?.map((item, index) => {
                            return (<div onClick={(e) => setSelectedSlot2(item)} key={index} value={item} className={selectedSlot2 === item ? `${styles.SelectBoxSelected}` : `${styles.SelectBox}`}>{moment(item, "HH:mm:ss").format("hh:mm A")}</div>);
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>)}
              <div className={`${styles.ButtonAction}`}>
                <Button onClick={cancelFerryPopup} className="CancelPopupBU">Cancel</Button>
                <Button className="SubmitPopupBU" disabled={!selectedFerryData || !selectedDate1 || !selectedDate2 || !selectedSlot1 || !selectedSlot2} onClick={addSubmit}>Book</Button>
              </div>
            </div>)}
            {addFerry && (<div className={`${styles.AddUserMain2}`}>
              <p className={`${styles.PayPalAmountTitle}`}>Amount to pay: {!currencyLoading && currency + " " + (selectedFerryData?.amount * currencyRate).toFixed(2)}</p>
              <PayPalButtons style={{ layout: "vertical" }} createOrder={createOrder} onApprove={onApprove} />
            </div>)}
          </div>
        </DialogContentText>
      </DialogContent>
    </Dialog>

    <SmartToaster
      store={toast}
      lightBackground={true}
      position={"top_center"}
    />
  </React.Fragment>);
};

export default FerryTicket;