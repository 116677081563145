import React from 'react';
import './App.css';
import MainRoutes from './routes/Routes';
import "react-multi-carousel/lib/styles.css";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

function App() {
  return (<React.Fragment>
    <PayPalScriptProvider options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID }}>
      {/*<PayPalScriptProvider options={{ "client-id":"AWSxyDxX8WxO7TLJw1hzgNjCtE3V-D91WK72kc4tYujSk4tPiA_ZjXLe976CRw1DTvWHwAAaRD4HU2sk" }}>*/}
      <MainRoutes />
    </PayPalScriptProvider>
  </React.Fragment>);
}

export default App;
