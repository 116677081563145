import React from "react";
import Footer from "../customer/components/footer";
import Header from "../customer/components/header";

const CustomerLayout = ({ children }) => {
  return (<React.Fragment>
    <Header />
    {children}
    <Footer />
  </React.Fragment>);
};

export default CustomerLayout;