import React from "react";
import styles from "./services.module.css";
import { Parallax } from "react-parallax";
import withNavigateHook from "../../../customer/components/withNavigateHook";
import { Link } from "react-router-dom";

const Services = (props) => {

  return (<React.Fragment>
    <div className="InnerBanner">
      <Parallax bgImage="/images/car-service-banner.jpg" strength={300}>
        <div style={{ height: 350 }} className={`${styles.BannerConArea}`}>
          <div className="container">
            <div className={`${styles.InnerBannerRow}`}>
              <h2 className={`${styles.InnerBannerTitle}`}>Services</h2>
            </div>
          </div>
        </div>
      </Parallax>
    </div>
    <div className={`${styles.CarsAboutSec}`}>
      <div className="container">
        <p className={`${styles.AboutSubTitle}`}>Motu Car Rentals offers a range of add-on services for your convenience and to enhance your trip while exploring beautiful Samoa.</p>
      </div>
    </div>
    <div className={`${styles.AddOnSec}`}>
      <div className="container">
        <p className={`${styles.AboutTitle}`}>Recommended ADD-ONS</p>
        <div className={`${styles.ServicesRow}`}>
          <div className={`${styles.ServicesCard}`}>
            <img src="/images/driver-license.svg" alt="" />
            <p className={`${styles.ServiceCardTitle}`}>Samoa Temporary Driver’s License</p>
            <p className={`${styles.ServiceText}`}>Ensure legally driving on the road, we provide temporary driving licenses for all our customers. The cost for this license is WST21, and each driver must get their own.</p>
            <Link className={`${styles.ServiceBU}`} to={"/reservation"}>BOOK NOW</Link>
          </div>
          <div className={`${styles.ServicesCard}`}>
            <img src="/images/baby-car-seat.png" alt="" />
            <p className={`${styles.ServiceCardTitle}`}>Baby Car Seats</p>
            <p className={`${styles.ServiceText}`}>Ensure your child's safety during your car rental by choosing from our selection of All-in-One Convertible Car Seats, capable of accommodating rear-facing weights of 5-40 pounds, forward-facing weights of 22-65 pounds, and belt-positioning booster weights of 40-100 pounds. In addition, we also offer booster seats designed specifically for kids.</p>
          </div>
          <div className={`${styles.ServicesCard}`}>
            <img src="/images/cruise.svg" alt="" />
            <p className={`${styles.ServiceCardTitle}`}>Ferry Tickets Reservations</p>
            <p className={`${styles.ServiceText}`}>When booking a rental car, schedule your ferry tickets with us for your convenience. We can handle your ferry tickets inquiries and reservations in advance, ensuring a stress-free travel experience. Let us take care of the details so you can focus on your journey. Please note that service fees apply for ferry ticket bookings.</p>
            <button className={`${styles.ServiceBU}`}>SCHEDULE NOW</button>
          </div>
          <div className={`${styles.ServicesCard}`}>
            <img src="/images/insurance.svg" alt="" />
            <p className={`${styles.ServiceCardTitle}`}>Insurance & Coverage</p>
            <p className={`${styles.ServiceText}`}>Motu Car Rentals will provide liability insurance coverage for personal injury and property damage claims by a third party if you are involved in an accident as required by Samoa law. We will cover every driver that is in compliance with our Rental Agreement. Motu Car Rentals customers receive liability coverage of (WST 50,000 personal injury maximum) and are responsible for the first WST 1,000 in damage. For more information, please read our <Link to={"/terms"}>Rental Agreement</Link>.</p>
          </div>
        </div>
      </div>
    </div>
    <div className={`${styles.AddOnSec}`}>
      <div className="container">
        <p className={`${styles.AboutTitle}`}>OTHER EXTRAS</p>
        <div className={`${styles.ServicesRow}`}>
          <div className={`${styles.ServicesCard}`}>
            <img src="/images/sunbed.png" alt="" />
            <p className={`${styles.ServiceCardTitle}`}>Beach Gear</p>
            <p className={`${styles.ServiceText}`}>Looking to enhance your outdoor experience with Motu Car Rentals in Samoa? Check out our selection of beach gear available for rent! While our selection is limited, we offer great gear that will make your time at the beach more enjoyable.</p>
          </div>
          <div className={`${styles.ServicesCard}`}>
            <img src="/images/power-bank.png" alt="" />
            <p className={`${styles.ServiceCardTitle}`}>Portable Gadgets</p>
            <p className={`${styles.ServiceText}`}>Rent from our selection of portable chargers and USA-to-NZ plug adapters, designed to keep you connected and powered up on the go. Our gadgets are both portable and convenient, making them an ideal choice for travelers who want to stay connected and powered up no matter where they are.</p>
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>);
};

export default withNavigateHook(Services);