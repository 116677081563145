import { useEffect, useState } from "react";
import styles from "../reservation.module.css";
import Select from "react-select";
import { Button, ButtonGroup } from "@mui/material";
import { CheckCircle, Minus, Plus, X } from "react-feather";
import LeftPanelBlock from "./LeftPanelBlock";

const addOnOptions = [
  { value: "Beach Accessories", label: "Beach Accessories" },
  { value: "Tech Accessories", label: "Tech Accessories" },
];

const Step2 = ({ handleSelect, bookingData, carList, pickupLocationList, dropoffLocationList, addOnList, selectAddOn, updateAddOn, removeAddOn, currency, currencyRate, currencyLoading, bookingAddOns, updateReservationInfo }) => {
  const [tempAddOns, setTempAddOns] = useState([]);
  const [currentType, setCurrentType] = useState("Beach Accessories");
  const [addOnWithQuantity, setAddOnWithQuantity] = useState([]);

  useEffect(() => {
    let addOnWithQuantityTemp = addOnList?.map((item) => {
      if (item?.quantity > 0) {
        let quantityTemp = item?.quantity;
        if (bookingAddOns?.length > 0) {
          let bookingAddOnstemp = bookingAddOns?.filter((i) => i?.id === item?.id);
          if (bookingAddOnstemp?.length > 0) {
            quantityTemp = quantityTemp - bookingAddOnstemp[0]?.total;
          }
        }
        return { id: item.id, quantity: quantityTemp };
      }
      return null;
    });

    addOnWithQuantityTemp = addOnWithQuantityTemp?.filter((i) => i !== null);

    setAddOnWithQuantity(addOnWithQuantityTemp);
  }, [bookingAddOns, addOnList]);

  useEffect(() => {
    if (bookingData?.addOns && bookingData?.addOns?.length) {
      let addOnListTemp = addOnList.map((item) => {
        let isAddOn = bookingData?.addOns.find((i) => i.addon_id === item.id);
        if (isAddOn) {
          return { ...item, selected: true, quantity: isAddOn.quantity };
        }
        return { ...item, selected: false, quantity: 0 };
      });
      setTempAddOns(addOnListTemp);
    } else {
      let addOnListTemp = addOnList.map((item) => {
        return { ...item, selected: false };
      });
      setTempAddOns(addOnListTemp);
    }
  }, [bookingData, addOnList]);

  const typeChange = (selected) => {
    setCurrentType(selected?.value);
  };

  return (<div className={`${styles.SelectCarSec}`}>
    <LeftPanelBlock
      handleSelect={handleSelect}
      bookingData={bookingData}
      carList={carList}
      pickupLocationList={pickupLocationList}
      dropoffLocationList={dropoffLocationList}
      addOnList={addOnList}
      currency={currency}
      currencyRate={currencyRate}
      currencyLoading={currencyLoading}
      currentStep={2}
      updateReservationInfo={updateReservationInfo}
    />

    <div className={`${styles.AddonsBigSide}`}>
      <h2 className={`${styles.AddonsBodyTitle}`}>Recommended Add-Ons</h2>
      <div>
        {tempAddOns?.map((item, index) => {
          let isSoldOut = false;
          let addQuantity = 0;
          let soldOutTemp = addOnWithQuantity?.filter(
            (i) => i?.id === item?.id
          );
          if (soldOutTemp?.length) {
            addQuantity = soldOutTemp[0]?.quantity;
            if (soldOutTemp[0]?.quantity === 0) {
              isSoldOut = true;
            }
          }
          return item.type === "Recomended Addons" ? (<div className={`${styles.AddOnsList}`} key={index}>
            <div className={`${styles.AddOnsListBody}`} style={{ background: "#afe1af" }}>
              <div className={`${styles.BodySec}`}>
                <img src={item.image_url !== "" ? item.image_url : "/images/noImage.jpg"} alt="" />
                <div className={`${styles.AddOnsListBodyText}`}>
                  {item.payment_type === "Day Wise" ? (<p className={`${styles.AccordingBodyTitle}`}>{item?.title} <span>{!currencyLoading && currency + " " + (item.price * currencyRate).toFixed(2) + "/Day"}</span></p>) : (<p className={`${styles.AccordingBodyTitle}`} style={{ color: "#008000" }}>{item?.title} <span style={{ color: "#008000" }}>{!currencyLoading && currency + " " + (item.price * currencyRate).toFixed(2)}</span></p>)}
                  <p className={`${styles.AccordingBodyText}`} dangerouslySetInnerHTML={{ __html: item?.description }} style={{ color: "#333" }}></p>
                  {item?.selected && (<ButtonGroup className={`${styles.ButtonGroupNew}`} size="small" aria-label="small outlined button group">
                    <Button className={`${styles.ButtonNew} ${styles.ButtonNew1}`} disabled={item?.quantity === 1} onClick={updateAddOn.bind(this, item, "sub")}><Minus /></Button>
                    <Button className={`${styles.ButtonNew} ${styles.ButtonNew2}`} disabled>{item?.quantity}</Button>
                    <Button className={`${styles.ButtonNew} ${styles.ButtonNew3}`} onClick={updateAddOn.bind(this, item, "add")} disabled={addQuantity > 0 && addQuantity <= item?.quantity}><Plus /></Button>
                  </ButtonGroup>)}
                  {isSoldOut && (<div className={`${styles.AccordingBUSec}`}>
                    <button className={`${styles.AddBU} ${styles.AddBUDisabled}`} disabled>Sold Out</button>
                  </div>)}
                  {!isSoldOut && (<div className={`${styles.AccordingBUSec}`}>
                    {item?.selected ? (<>
                      <button className={`${styles.AddedBU}`}><CheckCircle /> Added</button>
                      <button className={`${styles.RemoveBU}`} onClick={removeAddOn.bind(this, item)}><X /> Remove</button>
                    </>) : (<button className={`${styles.AddBU}`} onClick={selectAddOn.bind(this, item)}><Plus /> Add</button>)}
                  </div>)}
                </div>
              </div>
            </div>
          </div>) : null;
        })}
      </div>
      <h2 className={`${styles.AddonsBodyTitle}`}>Additional Add-Ons</h2>
      <p className={`${styles.AddonsBodySTitle}`}>Included in the price</p>
      <ul className={`${styles.AddonsBodyList}`}>
        <li>Free cancellation up to 48 hours before pick up</li>
        <li>Unlimited mileage</li>
      </ul>
      <div>
        {tempAddOns?.map((item, index) => {
          let isSoldOut = false;
          let addQuantity = 0;
          let soldOutTemp = addOnWithQuantity?.filter(
            (i) => i?.id === item?.id
          );
          if (soldOutTemp?.length) {
            addQuantity = soldOutTemp[0]?.quantity;
            if (soldOutTemp[0]?.quantity === 0) {
              isSoldOut = true;
            }
          }
          return item.type === "Primary Addons" ? (<div className={`${styles.AddOnsList}`} key={index}>
            <div className={`${styles.AddOnsListBody}`}>
              <div className={`${styles.BodySec}`}>
                <img src={item.image_url !== "" ? item.image_url : "/images/noImage.jpg"} alt="" />
                <div className={`${styles.AddOnsListBodyText}`}>
                  {item.payment_type === "Day Wise" ? (<p className={`${styles.AccordingBodyTitle}`}>{item?.title} <span>{!currencyLoading && currency + " " + (item.price * currencyRate).toFixed(2) + "/Day"}</span></p>) : (<p className={`${styles.AccordingBodyTitle}`}>{item?.title} <span>{!currencyLoading && currency + " " + (item.price * currencyRate).toFixed(2)}</span></p>)}
                  <p className={`${styles.AccordingBodyText}`} dangerouslySetInnerHTML={{ __html: item?.description }}></p>
                  {item?.selected && (<ButtonGroup className={`${styles.ButtonGroupNew}`} size="small" aria-label="small outlined button group">
                    <Button className={`${styles.ButtonNew} ${styles.ButtonNew1}`} disabled={item?.quantity === 1} onClick={updateAddOn.bind(this, item, "sub")}><Minus /></Button>
                    <Button className={`${styles.ButtonNew} ${styles.ButtonNew2}`} disabled>{item?.quantity}</Button>
                    <Button className={`${styles.ButtonNew} ${styles.ButtonNew3}`} onClick={updateAddOn.bind(this, item, "add")} disabled={addQuantity > 0 && addQuantity <= item?.quantity}><Plus /></Button>
                  </ButtonGroup>)}
                  {isSoldOut && (<div className={`${styles.AccordingBUSec}`}>
                    <button className={`${styles.AddBU} ${styles.AddBUDisabled}`} disabled>Sold Out</button>
                  </div>)}
                  {!isSoldOut && (<div className={`${styles.AccordingBUSec}`}>
                    {item?.selected ? (<>
                      <button className={`${styles.AddedBU}`}><CheckCircle /> Added</button>
                      <button className={`${styles.RemoveBU}`} onClick={removeAddOn.bind(this, item)}><X /> Remove</button>
                    </>) : (<button className={`${styles.AddBU}`} onClick={selectAddOn.bind(this, item)}><Plus /> Add</button>)}
                  </div>)}
                </div>
              </div>
            </div>
          </div>) : null;
        })}
      </div>

      <div>
        {tempAddOns?.map((item, index) => {
          let isSoldOut = false;
          let soldOutTemp = addOnWithQuantity?.filter(
            (i) => i?.id === item?.id
          );
          if (soldOutTemp?.length) {
            if (soldOutTemp[0]?.quantity === 0) {
              isSoldOut = true;
            }
          }
          return item.type === "Insurance Service" ? (<div className={`${styles.AddOnsList}`} key={index}>
            <div className={`${styles.AddOnsListBody}`}>
              <div className={`${styles.BodySec}`}>
                <img src={item.image_url !== "" ? item.image_url : "/images/noImage.jpg"} alt="" />
                <div className={`${styles.AddOnsListBodyText}`}>
                  {item.payment_type === "Day Wise" ? (<p className={`${styles.AccordingBodyTitle}`}>{item?.title} <span>{!currencyLoading && currency + " " + (item.price * currencyRate).toFixed(2) + "/Day"}</span></p>) : (<p className={`${styles.AccordingBodyTitle}`}>{item?.title} <span>{!currencyLoading && currency + " " + (item.price * currencyRate).toFixed(2)}</span></p>)}
                  <p className={`${styles.AccordingBodyText}`} dangerouslySetInnerHTML={{ __html: item?.description }}></p>
                  {isSoldOut && (<div className={`${styles.AccordingBUSec}`}>
                    <button className={`${styles.AddBU} ${styles.AddBUDisabled}`} disabled>Sold Out</button>
                  </div>)}
                  {!isSoldOut && (<div className={`${styles.AccordingBUSec}`}>
                    {item?.selected ? (<>
                      <button className={`${styles.AddedBU}`}><CheckCircle /> Added</button>
                      <button className={`${styles.RemoveBU}`} onClick={removeAddOn.bind(this, item)}><X /> Remove</button>
                    </>) : (<button className={`${styles.AddBU}`} onClick={selectAddOn.bind(this, item)}><Plus /> Add</button>)}
                  </div>)}
                </div>
              </div>
            </div>
          </div>) : null;
        })}
      </div>

      <div>
        <div className={`${styles.AddonDropdown}`}>
          <p className={`${styles.AddonDropdownTitle}`}>View more of our extras:</p>
          <Select
            className={`${styles.AddonDropdownFormInput}`}
            options={addOnOptions}
            defaultValue={{
              value: "Beach Accessories",
              label: "Beach Accessories",
            }}
            onChange={typeChange}
          />
        </div>
        {tempAddOns?.map((item, index) => {
          let isSoldOut = false;
          let addQuantity = 0;
          let soldOutTemp = addOnWithQuantity?.filter(
            (i) => i?.id === item?.id
          );
          if (soldOutTemp?.length) {
            addQuantity = soldOutTemp[0]?.quantity;
            if (soldOutTemp[0]?.quantity === 0) {
              isSoldOut = true;
            }
          }
          return currentType === item.type ? (<div className={`${styles.AddOnsList}`} key={index}>
            <div className={`${styles.AddOnsListBody}`}>
              <div className={`${styles.BodySec}`}>
                <img src={item.image_url !== "" ? item.image_url : "/images/noImage.jpg"} alt="" />
                <div className={`${styles.AddOnsListBodyText}`}>
                  {item.payment_type === "Day Wise" ? (<p className={`${styles.AccordingBodyTitle}`}>{item?.title}<span>{!currencyLoading && currency + " " + (item.price * currencyRate).toFixed(2) + "/Day"}</span></p>) : (<p className={`${styles.AccordingBodyTitle}`}>{item?.title}<span>{!currencyLoading && currency + " " + (item.price * currencyRate).toFixed(2)}</span></p>)}
                  <p className={`${styles.AccordingBodyText}`} dangerouslySetInnerHTML={{ __html: item?.description }}></p>
                  {item?.selected && (<ButtonGroup className={`${styles.ButtonGroupNew}`} size="small" aria-label="small outlined button group">
                    <Button className={`${styles.ButtonNew} ${styles.ButtonNew1}`} disabled={item?.quantity === 1} onClick={updateAddOn.bind(this, item, "sub")}><Minus /></Button>
                    <Button className={`${styles.ButtonNew} ${styles.ButtonNew2}`} disabled>{item?.quantity}</Button>
                    <Button className={`${styles.ButtonNew} ${styles.ButtonNew3}`} onClick={updateAddOn.bind(this, item, "add")} disabled={addQuantity > 0 && addQuantity <= item?.quantity}><Plus /></Button>
                  </ButtonGroup>)}
                  {isSoldOut && (<div className={`${styles.AccordingBUSec}`}>
                    <button className={`${styles.AddBU} ${styles.AddBUDisabled}`} disabled>Sold Out</button>
                  </div>)}
                  {!isSoldOut && (<div className={`${styles.AccordingBUSec}`}>
                    {item?.selected ? (<>
                      <button className={`${styles.AddedBU}`}><CheckCircle /> Added</button>
                      <button className={`${styles.RemoveBU}`} onClick={removeAddOn.bind(this, item)}><X /> Remove</button>
                    </>) : (<button className={`${styles.AddBU}`} onClick={selectAddOn.bind(this, item)}><Plus /> Add</button>)}
                  </div>)}
                </div>
              </div>
            </div>
          </div>) : null;
        })}
      </div>
    </div>
  </div>);
};

export default Step2;