import React, { useState } from "react";
import styles from "./header.module.css";
import { Link as RouterLink } from "react-router-dom";
import { Menu as MenuIcon, User, LogOut, ChevronDown, Bookmark } from "react-feather";
import { Menu, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { authData } from "../../admin/components/getAuth";
import { Link as MUILink } from '@mui/material';

function Header() {
  const auth = authData();
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const navigate = useNavigate();
  const [anchorEl2, setAnchorEl2] = useState(null);
  const open2 = Boolean(anchorEl2);
  const [currency, setCurrency] = useState('USD');
  const [currencyTitle, setCurrencyTitle] = useState('US Dollars');

  const handleClose2 = () => {
    setAnchorEl2(null);
  }

  const logout = () => {
    localStorage.removeItem("authData");
    navigate("/");
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  }
  const goToReservation = () => {
    navigate("/reservation");
  };

  const cngCurrency = (item) => {
    setAnchorEl2(null);
    localStorage.setItem('currentCurrency', item);
    setCurrency(item);
    if (item === 'WST') {
      setCurrencyTitle('Samoan Tala');
    }
    if (item === 'AUD') {
      setCurrencyTitle('AUS Dollars');
    }
    if (item === 'NZD') {
      setCurrencyTitle('NZ Dollars');
    }
    if (item === 'USD') {
      setCurrencyTitle('US Dollars');
    }
  }

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  return (<header className={`${styles.HeaderMain}`}>
    <div className="container">
      <div className={`${styles.HeaderRow}`}>
        <div className={`${styles.HeadLogo}`}>
          <img src="/images/moto-logo.svg" alt="" />
        </div>
        <div className={`${styles.HeadMenu} ${showMobileMenu ? styles.Open : ""}`}>
          <ul>
            <li>
              <RouterLink to={"/"}>Home</RouterLink>
            </li>
            <li>
              <RouterLink to={"/reservation"}>Reservation</RouterLink>
            </li>
            <li>
              <RouterLink to={"/cars"}>Cars</RouterLink>
            </li>
            <li>
              <RouterLink to={"/services"}>Services</RouterLink>
            </li>
            {/*<li>
              <RouterLink to={'/book-ferry-ticket'}>Ferry Ticket</RouterLink>
            </li>*/}
            <li>
              <RouterLink onClick={handleClick}>
                <User /> <span className="UserName">{auth?.name}</span>{" "}
                <ChevronDown className={`${styles.Chev}`} />
              </RouterLink>
            </li>
            <li>
              <MUILink onClick={handleClick2}>
                {(currency === 'WST') && <img src="/images/Flag-Samoa.svg" alt="" className={`${styles.MenuFlag}`} />}
                {(currency === 'AUD') && <img src="/images/Flag-Australia.svg" alt="" className={`${styles.MenuFlag}`} />}
                {(currency === 'NZD') && <img src="/images/Flag-NewZealand.svg" alt="" className={`${styles.MenuFlag}`} />}
                {(currency === 'USD') && <img src="/images/Flag-USA.svg" alt="" className={`${styles.MenuFlag}`} />}
                {currencyTitle} <ChevronDown className={`${styles.Chev}`} />
              </MUILink>
            </li>
          </ul>

          <Menu
            className={`${styles.UserDropMenu}`}
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{ "aria-labelledby": "basic-button" }}
          >
            <MenuItem onClick={handleClose}>
              <RouterLink to={"/customer/my-bookings"} className={`${styles.UserProfile}`}>
                <Bookmark /> My Bookings
              </RouterLink>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <RouterLink to={"/customer/my-profile"} className={`${styles.UserProfile}`}>
                <User /> My Profile
              </RouterLink>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <button nClick={logout.bind(this)} className={`${styles.UserLogoutBU}`}>
                <LogOut />Logout
              </button>
            </MenuItem>
          </Menu>
          <Menu className={`${styles.UserDropMenu}`} id="basic-menu-2" anchorEl={anchorEl2} open={open2} onClose={handleClose2} MenuListProps={{ 'aria-labelledby': 'basic-menu-2', }}>
            {(currency !== 'WST') && <MenuItem className={`${styles.USDCurrency}`} onClick={cngCurrency.bind(this, 'WST')} cngCurrency="1">WST - Samoan Tala</MenuItem>}
            {(currency !== 'AUD') && <MenuItem className={`${styles.USDCurrency}`} onClick={cngCurrency.bind(this, 'AUD')} cngCurrency="1">AUD - Australian Dollars</MenuItem>}
            {(currency !== 'NZD') && <MenuItem className={`${styles.USDCurrency}`} onClick={cngCurrency.bind(this, 'NZD')} cngCurrency="1">NZD - New Zealand Dollars</MenuItem>}
            {(currency !== 'USD') && <MenuItem className={`${styles.USDCurrency}`} onClick={cngCurrency.bind(this, 'USD')} cngCurrency="1">USD - US Dollars</MenuItem>}
          </Menu>
          <button className={`${styles.HeaderBU}`} onClick={goToReservation}>
            <span>Book Now</span>
          </button>
        </div>
        <button onClick={() => setShowMobileMenu((v) => !v)} className={`${styles.MenuHamBU}`}><MenuIcon /></button>
      </div>
    </div>
  </header>);
}

export default Header;