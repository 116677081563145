import React, { useEffect, useState } from "react";
import styles from "./calendar.module.css";
import { Link } from "react-router-dom";
import { Typography, Breadcrumbs, Button } from "@mui/material";
import { X } from "react-feather";
import axios from "axios";
import { TableLoader } from "../../components/LoaderC";
import withAdminAuth from "../../components/withAdminAuth";
import { authData } from "../../components/getAuth";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";

const BookingCalendar = () => {
  const auth = authData();
  const [contentLoading, setContentLoading] = useState(true);
  const [calendarList, setCalendarList] = useState([]);
  const [dateRange, setDateRange] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    const getEvents = () => {
      setContentLoading(true);
      axios(process.env.REACT_APP_API_URL + "v1/booking-list-calendar", {
        headers: {
          Authorization: `Bearer ${auth?.api_token}`,
        }
      }).then((response) => {
        setContentLoading(false);
        setCalendarList(response.data.data);
      }).catch((error) => {
        setContentLoading(false);
      });
    }

    if (auth?.api_token)
      getEvents();
  }, [auth?.api_token]);

  useEffect(() => {
    const getEvents = () => {
      setCalendarList([]);
      axios(process.env.REACT_APP_API_URL + "v1/booking-list-calendar?start=" + moment(dateRange?.start).format("YYYY-MM-DD") + "&end=" + moment(dateRange?.end).format("YYYY-MM-DD"), {
        headers: {
          Authorization: `Bearer ${auth?.api_token}`,
        }
      }).then((response) => {
        setCalendarList(response.data.data);
      }).catch((error) => {
        setContentLoading(false);
        setCalendarList([]);
      });
    }

    if (auth?.api_token && dateRange?.start && dateRange?.end)
      getEvents();
  }, [auth?.api_token, dateRange]);

  return (<>
    {contentLoading && (<div className="LoaderCard"><div className="Loader TableLoader"><TableLoader /></div></div>)}

    {!contentLoading && (<div className={`${styles.MainDiv}`}>
      <div className={`${styles.PageTitleWrap}`}>
        <p className={`${styles.PageTitle}`}>Bookings List</p>
        <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
          <Link color="inherit" to="/admin/dashboard">Dashboard</Link>
          <Link color="inherit" to={"/admin/booking/list"}>Bookings</Link>
          <Typography color="text.primary">List</Typography>
        </Breadcrumbs>
      </div>

      <div className={`${styles.MainCard}`}>
        <div className="TableContent">
          <FullCalendar
            plugins={[dayGridPlugin]}
            initialView="dayGridMonth"
            headerToolbar={{
              start: "title", // will normally be on the left. if RTL, will be on the right
              center: "",
              end: "prev,next", // will normally be on the right. if RTL, will be on the left
            }}
            events={calendarList}
            datesSet={(e) => setDateRange(e)}
            eventClick={(e) => {
              setSelectedItem(e?.event?.extendedProps);
              setModalShow(true);
            }}
          />
        </div>
      </div>
    </div>)}

    <Modal
      show={modalShow}
      onHide={(e) => setModalShow(false)}
      centered
      className="CusModal"
      size="lg"
    >
      <Modal.Body>
        <p className={`${styles.RefID}`}>
          <span>Reference Id:</span> {selectedItem?.reference_id}
        </p>
        <div className={`${styles.PopBodyRow}`}>
          <div className={`${styles.PopFullSec}`}>
            <p className={`${styles.PopHalfSecTitle}`}>Customer Details</p>
            <p className={`${styles.PopHalfSecText}`}>
              <span>Name:</span>
              {selectedItem?.user_info?.first_name} {selectedItem?.user_info?.last_name}
            </p>
            <p className={`${styles.PopHalfSecText}`}>
              <span>Address:</span>
              {selectedItem?.user_info?.address}, {selectedItem?.user_info?.city}, {selectedItem?.user_info?.state} {selectedItem?.user_info?.zip}
            </p>
            <p className={`${styles.PopHalfSecText}`}>
              <span>Contact no.:</span>
              {selectedItem?.user_info?.phone}
            </p>
            <p className={`${styles.PopHalfSecText}`}>
              <span>Email:</span>
              {selectedItem?.user_info?.email}
            </p>
            <p className={`${styles.PopHalfSecText}`}>
              <span>No of Passenger(s):</span>
              {selectedItem?.user_info?.no_of_people} People
            </p>
            <p className={`${styles.PopHalfSecText}`}>
              <span>Driver's Age:</span>
              {selectedItem?.user_info?.age} yrs
            </p>
            <p className={`${styles.PopHalfSecText}`}>
              <span>Arrival Airline Carrier:</span>
              {selectedItem?.user_info?.airline_carrier !== "" ? selectedItem?.user_info?.airline_carrier : "N/A"}
            </p>
            <p className={`${styles.PopHalfSecText}`}>
              <span>Arrival Vessel Number:</span>
              {selectedItem?.user_info?.vessel_number !== "" ? selectedItem?.user_info?.vessel_number : "N/A"}
            </p>
            <p className={`${styles.PopHalfSecText}`}>
              <span>Departure Airline Carrier:</span>
              {selectedItem?.user_info?.departure_airline_carrier !== "" ? selectedItem?.user_info?.departure_airline_carrier : "N/A"}
            </p>
            <p className={`${styles.PopHalfSecText}`}>
              <span>Departure Vessel Number:</span>
              {selectedItem?.user_info?.departure_vessel_number !== "" ? selectedItem?.user_info?.departure_vessel_number : "N/A"}
            </p>
          </div>
        </div>
        {selectedItem?.ferry_info && selectedItem?.ferry_info.length !== 0 && (<div className={`${styles.PopBodyRow}`}>
          <div className={`${styles.PopFullSec}`}>
            <p className={`${styles.PopHalfSecTitle}`}>Ferry Details</p>
            <table>
              <tr>
                <td>
                  <p className={`${styles.PopHalfSecTitle}`}>Description</p>
                </td>
                <td>
                  <p className={`${styles.PopHalfSecTitle}`}>Date</p>
                </td>
                <td>
                  <p className={`${styles.PopHalfSecTitle}`}>Time</p>
                </td>
              </tr>
              {selectedItem?.ferry_info?.map((itemFerry, indexFerry) => {
                return (<tr index={indexFerry}>
                  <td>
                    <p className={`${styles.PopHalfSecText}`}>{itemFerry?.ferry_name}</p>
                  </td>
                  <td>
                    <p className={`${styles.PopHalfSecText}`}>{moment(itemFerry?.ferry_booking_date).format("L")}</p>
                  </td>
                  <td>
                    <p className={`${styles.PopHalfSecText}`}>
                      <strong>{moment(itemFerry?.ferry_booking_slot, "HH:mm:ss").format("hh:mm A")}</strong>
                    </p>
                  </td>
                </tr>);
              })}
            </table>
          </div>
        </div>)}
        <div className={`${styles.PopBodyRow}`}>
          <div className={`${styles.PopHalfSec}`}>
            <p className={`${styles.PopHalfSecTitle}`}>Pick up location</p>
            <p className={`${styles.PopHalfSecText}`}>{selectedItem?.location}</p>
            <p className={`${styles.PopHalfSecText}`}>{moment(selectedItem?.start_date).format("L")} {moment(selectedItem?.start_date + " " + selectedItem?.start_time).format("hh:mm A")}</p>
          </div>
          <div className={`${styles.PopHalfSec}`}>
            <p className={`${styles.PopHalfSecTitle}`}>Drop off location</p>
            <p className={`${styles.PopHalfSecText}`}>{selectedItem?.dropoff_location}</p>
            <p className={`${styles.PopHalfSecText}`}>{moment(selectedItem?.end_date).format("L")} {moment(selectedItem?.end_date + " " + selectedItem?.end_time).format("hh:mm A")}</p>
          </div>
        </div>
        <div className={`${styles.PopBodyRow}`}>
          <div className={`${styles.PopFullSec}`}>
            <table>
              <tr>
                <td>
                  <p className={`${styles.PopHalfSecTitle}`}>Description</p>
                </td>
                <td>
                  <p className={`${styles.PopHalfSecTitle}`}>Qtn</p>
                </td>
                <td>
                  <p className={`${styles.PopHalfSecTitle}`}>Price</p>
                </td>
              </tr>
              {selectedItem?.cars?.map((item, index) => {
                return (<tr key={index}>
                  <td>
                    <p className={`${styles.PopHalfSecText}`}>{item?.title}</p>
                  </td>
                  <td>
                    <p className={`${styles.PopHalfSecText}`}>{item?.quantity}</p>
                  </td>
                  <td>
                    <p className={`${styles.PopHalfSecText}`}><strong>WST {item?.sub_amount.toFixed(2)}</strong></p>
                  </td>
                </tr>);
              })}
              {selectedItem?.addons?.map((item, index) => {
                return (<tr key={index}>
                  <td>
                    <p className={`${styles.PopHalfSecText}`}>{item?.title}</p>
                  </td>
                  <td>
                    <p className={`${styles.PopHalfSecText}`}>{item?.quantity}</p>
                  </td>
                  <td>
                    <p className={`${styles.PopHalfSecText}`}><strong>WST {item?.sub_amount.toFixed(2)}</strong></p>
                  </td>
                </tr>);
              })}
              <tr>
                <td colSpan="2">
                  <p className={`${styles.PopHalfSecTitle} ${styles.BorderTop} ${styles.PopHalfSecTitleNew} ${styles.PopHalfSecTitleNew3}`}>Sub Total</p>
                </td>
                <td>
                  <p className={`${styles.PopHalfSecTitle} ${styles.BorderTop}  ${styles.PopHalfSecTitleNew2} ${styles.PopHalfSecTitleNew3}`}>WST {selectedItem?.amount?.toFixed(2)}</p>
                </td>
              </tr>
              {selectedItem?.discount_amount > 0 && (<tr>
                <td colSpan="2">
                  <p className={`${styles.PopHalfSecTitle} ${styles.BorderTop} ${styles.PopHalfSecTitleNew}`}>Discount</p>
                </td>
                <td>
                  <p className={`${styles.PopHalfSecTitle} ${styles.BorderTop} ${styles.PopHalfSecTitleNew2}`}>WST {selectedItem?.discount_amount?.toFixed(2)}</p>
                </td>
              </tr>)}
              {selectedItem?.promo_discount_amount > 0 && (<tr>
                <td colSpan="2">
                  <p className={`${styles.PopHalfSecTitle} ${styles.BorderTop} ${styles.PopHalfSecTitleNew}`}>Promotional Code ({selectedItem?.promotional_code})</p>
                </td>
                <td>
                  <p className={`${styles.PopHalfSecTitle} ${styles.BorderTop} ${styles.PopHalfSecTitleNew2}`}>WST -{selectedItem?.promo_discount_amount?.toFixed(2)}</p>
                </td>
              </tr>)}
              {selectedItem?.dropoff_amount > 0 && (<tr>
                <td colSpan="2">
                  <p className={`${styles.PopHalfSecTitle} ${styles.BorderTop} ${styles.PopHalfSecTitleNew}`}>Delivery Fee</p>
                </td>
                <td>
                  <p className={`${styles.PopHalfSecTitle} ${styles.BorderTop} ${styles.PopHalfSecTitleNew2}`}>WST {selectedItem?.dropoff_amount?.toFixed(2)}</p>
                </td>
              </tr>)}
              <tr>
                <td colSpan="2">
                  <p className={`${styles.PopHalfSecTitle} ${styles.BorderTop} ${styles.PopHalfSecTitleNew}`}>Security Amount</p>
                </td>
                <td>
                  <p className={`${styles.PopHalfSecTitle} ${styles.BorderTop} ${styles.PopHalfSecTitleNew2}`}>WST {selectedItem?.security_amount?.toFixed(2)}</p>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <p className={`${styles.PopHalfSecTitle} ${styles.BorderTop} ${styles.PopHalfSecTitleNew}`}>Payment Fee (3%)</p>
                </td>
                <td>
                  <p className={`${styles.PopHalfSecTitle} ${styles.BorderTop} ${styles.PopHalfSecTitleNew2}`}>WST {selectedItem?.payment_fee?.toFixed(2)}</p>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <p className={`${styles.PopHalfSecTitle} ${styles.BorderTop} ${styles.PopHalfSecTitleNew5}`}>Grand Total</p>
                </td>
                <td>
                  <p className={`${styles.PopHalfSecTitle} ${styles.BorderTop} ${styles.PopHalfSecTitleNew6}`}>WST {selectedItem?.total_amount?.toFixed(2)}</p>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <Button className={`${styles.PopUpCanBU}`} onClick={(e) => setModalShow(false)}><X /></Button>
      </Modal.Body>
    </Modal>
  </>);
};

export default withAdminAuth(BookingCalendar);