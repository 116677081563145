import React, { useState, useEffect } from "react";
import styles from "./add.module.css";
import { Typography, Breadcrumbs, Button } from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import Select from "react-select";
import { NumericFormat, PatternFormat } from "react-number-format";
import { toast } from "react-smart-toaster";
import { FromLoader } from "../../components/LoaderC";
import Loader from "../../components/Loader";
import withAdminAuth from "../../components/withAdminAuth";
import { authData } from "../../components/getAuth";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const addressTypeOptions = [
  { value: "Faleolo International Airport", label: "Faleolo International Airport" },
];

function QuickBooking({ history, match }) {
  const auth = authData();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(true);
  const [carList, setCarList] = useState([]);
  const [addOnList, setAddOnList] = useState([]);
  const [pickupLocationList, setPickupLocationList] = useState([]);
  const [dropoffLocationList, setDropoffLocationList] = useState([]);
  const [selectedCar, setSelectedCar] = useState({});
  const [selectedAddOns, setSelectedAddOns] = useState([]);
  const [dayDiff, setDayDiff] = useState(1);
  const [subTotal, setSubTotal] = useState(0);
  const [securityAmount, setSecurityAmount] = useState(0);
  const [dropOffAmount, setDropOffAmount] = useState(0);
  const [deliveryAmount, setDeliveryAmount] = useState(0);
  const [isAirport, setIsAirport] = useState(0);
  const [discoutAmount, setDiscoutAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [cRate, setcRate] = useState(1);
  const schema = yup.object().shape({
    location: yup.object().required("This field is Required"),
    dropoff_location: yup.object().required("This field is Required"),
    start_date: yup.string().required("This field is Required"),
    start_time: yup.string().required("This field is Required"),
    end_date: yup.string().required("This field is Required"),
    end_time: yup.string().required("This field is Required"),
    car: yup.object().required("This field is Required"),
    addons: yup.mixed(),
    first_name: yup.string().required("This field is Required"),
    last_name: yup.string().required("This field is Required"),
    airline_carrier: yup.string().required("This field is Required"),
    vessel_number: yup.string().required("This field is Required"),
    phone: yup.string().required("This field is Required"),
    email: yup.string().required("This field is Required").email("Please enter valid email"),
    address: yup.string().required("This field is Required"),
    city: yup.string().required("This field is Required"),
    state: yup.string().required("This field is Required"),
    zip: yup.string().required("This field is Required"),
    no_of_people: yup.number().required("This field is Required").min(1, "Number of Passenger(s) should be grater than 0"),
    age: yup.number().required("This field is Required").min(25, "Driver's Age should be grater than 24"),
    discount: yup.string(),
  });

  const { register, watch, handleSubmit, control, getValues, formState: { errors } } = useForm({
    defaultValues: {
      start_date: moment().toDate(),
      start_time: moment().set("hour", 9).set("minute", 0).set("second", 0).set("millisecond", 0).toDate(),
      end_date: moment().add(1, "d").toDate(),
      end_time: moment().set("hour", 9).set("minute", 0).set("second", 0).set("millisecond", 0).toDate(),
      addons: [],
    },
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  useEffect(() => {
    axios(process.env.REACT_APP_API_URL + "v1/car-list").then((response) => {
      setContentLoading(false);
      let allData = response.data.data;
      let carListTemp = allData.map((item) => {
        return { value: item.id, label: item.title, price: item.price, security_amount: item.security_amount };
      });
      setCarList(carListTemp);
    }).catch((error) => {
      setContentLoading(false);
      setCarList([]);
    });
  }, []);

  useEffect(() => {
    axios(process.env.REACT_APP_API_URL + "v1/add-on-list").then((response) => {
      let allData = response.data.data;
      let addOnListTemp = allData.map((item) => {
        return { value: item.id, label: item.title, price: item.price, payment_type: item.payment_type };
      });
      setAddOnList(addOnListTemp);
    }).catch((error) => {
      setAddOnList([]);
    });
  }, []);

  useEffect(() => {
    axios(process.env.REACT_APP_API_URL + "v1/pickup-location-list").then((response) => {
      let pickupList = response.data.data.map((i) => {
        return { value: i.id, label: i.name, delivery_fee: i.delivery_fee, is_airport: i.is_airport };
      });
      setPickupLocationList(pickupList);
    }).catch((error) => {
      setPickupLocationList([]);
    });
  }, []);

  useEffect(() => {
    axios(process.env.REACT_APP_API_URL + "v1/dropoff-location-list").then((response) => {
      let dropoffList = response.data.data.map((i) => {
        return { value: i.id, label: i.name, delivery_fee: i.delivery_fee, is_airport: i.is_airport };
      });
      setDropoffLocationList(dropoffList);
    }).catch((error) => {
      setDropoffLocationList([]);
    });
  }, []);

  useEffect(() => {
    let totalTemp = 0;
    let subTotalTemp = 0;

    if (selectedCar?.price) {
      subTotalTemp += selectedCar.price * dayDiff;
      totalTemp += selectedCar.price * dayDiff;
      setSecurityAmount(selectedCar.security_amount);
      totalTemp += selectedCar.security_amount;
    }

    if (selectedAddOns.length) {
      selectedAddOns.forEach((item) => {
        subTotalTemp += item.payment_type === "Day Wise" ? item.price * dayDiff : item.price;
        totalTemp += item.payment_type === "Day Wise" ? item.price * dayDiff : item.price;
      });
    }

    if (isAirport === 0 || dayDiff < 7) {
      setDropOffAmount(deliveryAmount);
      totalTemp += dropOffAmount;
    } else {
      setDropOffAmount(0);
    }

    if (discoutAmount > 0) {
      totalTemp -= discoutAmount;
    }

    setSubTotal(subTotalTemp);
    setTotalAmount(totalTemp);
  }, [selectedCar, selectedAddOns, discoutAmount, dayDiff, dropOffAmount, deliveryAmount, isAirport]);

  useEffect(() => {
    axios(process.env.REACT_APP_API_URL + "v1/currency-convertter/WST/USD").then((response) => {
      let cRateTemp = response.data.data;
      setcRate(cRateTemp);
    });
  }, []);

  async function onSubmit(data) {
    setLoading(true);

    let postData = data;
    postData = {
      ...postData,
      days: dayDiff,
      location_id: postData.location.value,
      location: postData.location.label,
      dropoff_location_id: postData.dropoff_location.value,
      dropoff_location: postData.dropoff_location.label,
      start_date: moment(postData.start_date).format("YYYY-MM-DD"),
      end_date: moment(postData.end_date).format("YYYY-MM-DD"),
      start_time: moment(postData.start_time).format("HH:mm:ss"),
      end_time: moment(postData.end_time).format("HH:mm:ss"),
      amount: subTotal,
      security_amount: securityAmount,
      dropoff_amount: dropOffAmount,
      discount_amount: discoutAmount,
      total_amount: totalAmount,
      total_amount_usd: totalAmount * cRate,
      payable_amount: totalAmount,
      currency: "WST",
    };

    return axios.post(process.env.REACT_APP_API_URL + "v1/quick-booking", postData, {
      headers: {
        Authorization: `Bearer ${auth?.api_token}`,
      }
    }).then((response) => {
      setLoading(false);
      toast.success(response.data.message);
      navigate("/admin/booking/list");
    }).catch((error) => {
      setLoading(false);
      toast.error("Error occured. Try Again!");
      navigate("/admin/booking/list");
    });
  }

  function dateChangeNew() {
    let startDate = moment(getValues("start_date")).format("YYYY-MM-DD") + " " + moment(getValues("start_time")).format("HH:mm:ss");
    let endDate = moment(getValues("end_date")).format("YYYY-MM-DD") + " " + moment(getValues("end_time")).format("HH:mm:ss");
    let dayDiffTemp = moment(endDate, "YYYY-MM-DD HH:mm:ss").diff(moment(startDate, "YYYY-MM-DD HH:mm:ss"), "minutes");
    dayDiffTemp = dayDiffTemp / (24 * 60);
    if (dayDiffTemp > parseInt(dayDiffTemp)) {
      dayDiffTemp = parseInt(dayDiffTemp) + 1;
    }
    if (dayDiffTemp < 1) {
      dayDiffTemp = 1;
    }

    setDayDiff(dayDiffTemp);
  }

  const changeDropOffLocation = (event) => {
    setDeliveryAmount(event.delivery_fee);
    setIsAirport(event?.is_airport);
  };

  return (<React.Fragment>

    {loading && <Loader />}

    {contentLoading && (<div className="LoaderCard"><div className="Loader FromLoader"><FromLoader /></div></div>)}

    {!contentLoading && (<div className={`${styles.MainDiv}`}>
      <div className={`${styles.PageTitleWrap}`}>
        <p className={`${styles.PageTitle}`}>Quick Booking</p>
        <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
          <Link underline="hover" color="inherit" to="/admin/dashboard">Dashboard</Link>
          <Typography color="text.primary">Quick Booking</Typography>
        </Breadcrumbs>
      </div>

      <div className={`${styles.MainCard}`}>
        <div className={`${styles.PriceSec}`}>
          {selectedCar?.value && (<div className={`${styles.Width400}`}>
            <p className={`${styles.QuickBookingTitle}`}>Overview</p>
            <div className={`${styles.CarDeSec}`}>
              <p className={`${styles.CarDeTitle}`}>{selectedCar?.label}</p>
              <div className={`${styles.SideCarDeBodyRow}`}>
                <p className={`${styles.SmallCardDEList}`}>Time {dayDiff} Day(s) @ WST {selectedCar.price.toFixed(2)}/Day</p>
                <p className={`${styles.SmallCardDEList}`}>WST {(selectedCar.price * dayDiff).toFixed(2)}</p>
              </div>
              <div className={`${styles.SideCarDeBodyRow}`}>
                <p className={`${styles.SmallCardDEList}`}>Unlimited Mileage</p>
                <p className={`${styles.SmallCardDEList}`}>Included</p>
              </div>
            </div>
            {selectedAddOns.length > 0 && (<div className={`${styles.CarDeSec}`}>
              <p className={`${styles.CarDeTitle}`}>Extras</p>
              {selectedAddOns.map((item, index) => {
                return (<div className={`${styles.SideCarDeBodyRow}`} key={index}>
                  {item.payment_type === "Day Wise" ? (<p className={`${styles.SmallCardDEList}`}>{item.label} {dayDiff} Day(s) @ WST {item.price.toFixed(2)}/Day</p>) : (<p className={`${styles.SmallCardDEList}`}>{item.label} @ WST {item.price.toFixed(2)}</p>)}
                  {item.payment_type === "Day Wise" ? (<p className={`${styles.SmallCardDEList} ${styles.ExtraAmmo}`}>WST {(item.price * dayDiff).toFixed(2)}</p>) : (<p className={`${styles.SmallCardDEList} ${styles.ExtraAmmo}`}>WST {item.price.toFixed(2)}</p>)}
                </div>);
              })}
            </div>)}
            <div className={`${styles.SmallCardTaxSec} ${styles.SmallCardTaxSec2}`}>
              <p class={`${styles.SmallCardTaxTitle}`}>Sub Total</p>
              <p class={`${styles.SmallCardTaxAmm}`}>WST {subTotal.toFixed(2)}</p>
            </div>
            {discoutAmount > 0 && (<div className={`${styles.SmallCardTaxSec}`}>
              <p class={`${styles.SmallCardTaxTitle}`}>Discount</p>
              <p class={`${styles.SmallCardTaxAmm}`}>WST -{discoutAmount.toFixed(2)}</p>
            </div>)}
            <div className={`${styles.SmallCardTaxSec}`}>
              <p class={`${styles.SmallCardTaxTitle}`}>Delivery Fee</p>
              <p class={`${styles.SmallCardTaxAmm}`}>WST {dropOffAmount.toFixed(2)}</p>
            </div>
            <div className={`${styles.SmallCardTaxSec}`}>
              <p class={`${styles.SmallCardTaxTitle}`}>Security Amount</p>
              <p class={`${styles.SmallCardTaxAmm}`}>WST {securityAmount.toFixed(2)}</p>
            </div>
            <div className={`${styles.SmallCardTotal}`}>
              <p class={`${styles.SmallCardTaxTitle}`}>Grand Total</p>
              <p class={`${styles.SmallCardTOtalAmm}`}>WST {totalAmount.toFixed(2)}</p>
            </div>
          </div>)}
        </div>
        <div className={`${styles.AddUserMain}`}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={`${styles.FromSecStep}`}>
              <p className={`${styles.QuickBookingTitle}`}>Booking Informarion</p>
              <div className={`${styles.FormRow}`}>
                <div className={`${styles.FormRowHalf}`}>
                  <div className={`${styles.FormGroup}`}>
                    <label className={`${styles.FormLabel}`}>Pick Up Location*</label>
                    <Controller
                      control={control}
                      options={pickupLocationList}
                      {...register("location")}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={pickupLocationList}
                          onChange={(e) => {
                            field.onChange(e);
                            changeDropOffLocation(e);
                          }}
                        />
                      )}
                    />
                    {errors.location && !watch().location && (<span className={`${styles.ErrorM}`}>{errors.location.message}</span>)}
                  </div>
                  <div className={`${styles.FormGroup}`}>
                    <label className={`${styles.FormLabel}`}>Drop Off Location*</label>
                    <Controller
                      control={control}
                      options={addressTypeOptions}
                      {...register("dropoff_location")}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={dropoffLocationList}
                        />
                      )}
                    />
                    {errors.dropoff_location && !watch().dropoff_location && (<span className={`${styles.ErrorM}`}>{errors.dropoff_location.message}</span>)}
                  </div>
                </div>
                <div className={`${styles.FormRowHalf}`}>
                  <div className={`${styles.FormRowHalf}`}>
                    <div className={`${styles.FormGroup}`}>
                      <label className={`${styles.FormLabel}`}>Pick-Up Date*</label>
                      <Controller
                        control={control}
                        {...register("start_date")}
                        render={({ field }) => (
                          <DatePicker
                            {...field}
                            onChange={(date) => {
                              field.onChange(date);
                              dateChangeNew();
                            }}
                            selected={field.value}
                            className={`${styles.FormControl}`}
                            dateFormat="eee, d MMM y"
                            autoComplete="off"
                          />
                        )}
                      />
                      {errors.start_date && !watch().start_date && (<span className={`${styles.ErrorM}`}>{errors.start_date.message}</span>)}
                    </div>
                    <div className={`${styles.FormGroup}`}>
                      <label className={`${styles.FormLabel}`}>Drop-Off Date*</label>
                      <Controller
                        control={control}
                        {...register("end_date")}
                        render={({ field }) => (
                          <DatePicker
                            {...field}
                            onChange={(date) => {
                              field.onChange(date);
                              dateChangeNew();
                            }}
                            selected={field.value}
                            className={`${styles.FormControl}`}
                            dateFormat="eee, d MMM y"
                            autoComplete="off"
                          />
                        )}
                      />
                      {errors.end_date && !watch().end_date && (<span className={`${styles.ErrorM}`}>{errors.end_date.message}</span>)}
                    </div>
                  </div>
                  <div className={`${styles.FormRowHalf}`}>
                    <div className={`${styles.FormGroup}`}>
                      <label className={`${styles.FormLabel}`}>Pick-Up Time*</label>
                      <Controller
                        control={control}
                        {...register("start_time")}
                        render={({ field }) => (
                          <DatePicker
                            {...field}
                            onChange={(date) => {
                              field.onChange(date);
                              dateChangeNew();
                            }}
                            selected={field.value}
                            className={`${styles.FormControl}`}
                            dateFormat="h:mm aa"
                            showTimeSelect
                            timeFormat="p"
                            timeIntervals={30}
                            showTimeSelectOnly
                          />
                        )}
                      />
                      {errors.start_time && !watch().start_time && (<span className={`${styles.ErrorM}`}>{errors.start_time.message}</span>)}
                    </div>
                    <div className={`${styles.FormGroup}`}>
                      <label className={`${styles.FormLabel}`}>Drop-Off Time*</label>
                      <Controller
                        control={control}
                        {...register("end_time")}
                        render={({ field }) => (
                          <DatePicker
                            {...field}
                            onChange={(date) => {
                              field.onChange(date);
                              dateChangeNew();
                            }}
                            selected={field.value}
                            className={`${styles.FormControl}`}
                            dateFormat="h:mm aa"
                            showTimeSelect
                            timeFormat="p"
                            timeIntervals={30}
                            showTimeSelectOnly
                          />
                        )}
                      />
                      {errors.end_time && !watch().end_time && (<span className={`${styles.ErrorM}`}>{errors.end_time.message}</span>)}
                    </div>
                  </div>
                </div>
              </div>
              <div className={`${styles.FormRow}`}>
                <div className={`${styles.FormRowHalf}`}>
                  <div className={`${styles.FormGroup}`}>
                    <label className={`${styles.FormLabel}`}>Choose a Vehicle*</label>
                    <Controller
                      control={control}
                      options={carList}
                      {...register("car")}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={carList}
                          onChange={(e) => {
                            field.onChange(e);
                            setSelectedCar(e);
                          }}
                        />
                      )}
                    />
                    {errors.car && !watch().car && (<span className={`${styles.ErrorM}`}>{errors.car.message}</span>)}
                  </div>
                </div>
              </div>
              <div className={`${styles.FormRow}`}>
                <div className={`${styles.FormGroup}`}>
                  <label className={`${styles.FormLabel}`}>Choose Add-Ons</label>
                  <Controller
                    control={control}
                    options={addOnList}
                    {...register("addons")}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={addOnList}
                        isMulti
                        onChange={(e) => {
                          field.onChange(e);
                          setSelectedAddOns(e);
                        }}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className={`${styles.FromSecStep}`}>
              <p className={`${styles.QuickBookingTitle}`}>User Informarion</p>
              <div className={`${styles.FormRow}`}>
                <div className={`${styles.FormRowHalf}`}>
                  <div className={`${styles.FormGroup}`}>
                    <label className={`${styles.FormLabel}`}>First Name*</label>
                    <input className={`${styles.FormControl}`} {...register("first_name")} />
                    {errors.first_name && !watch().first_name && (<span className={`${styles.ErrorM}`}>{errors.first_name.message}</span>)}
                  </div>
                </div>
                <div className={`${styles.FormRowHalf}`}>
                  <div className={`${styles.FormGroup}`}>
                    <label className={`${styles.FormLabel}`}>Last Name*</label>
                    <input className={`${styles.FormControl}`} {...register("last_name")} />
                    {errors.last_name && !watch().last_name && (<span className={`${styles.ErrorM}`}>{errors.last_name.message}</span>)}
                  </div>
                </div>
              </div>
              <div className={`${styles.FormRow}`}>
                <div className={`${styles.FormRowHalf}`}>
                  <div className={`${styles.FormGroup}`}>
                    <label className={`${styles.FormLabel}`}>Phone Number*</label>
                    <Controller
                      control={control}
                      {...register("phone")}
                      className={`${styles.FormControl}`}
                      render={({ field: { onChange, name, value } }) => (
                        <PatternFormat
                          format="+############"
                          name={name}
                          value={value}
                          onChange={onChange}
                          className={`${styles.FormControl}`}
                        />
                      )}
                    />
                    {errors.phone && !watch().phone && (<span className={`${styles.ErrorM}`}>{errors.phone.message}</span>)}
                  </div>
                </div>
                <div className={`${styles.FormRowHalf}`}>
                  <div className={`${styles.FormGroup}`}>
                    <label className={`${styles.FormLabel}`}>Email*</label>
                    <input type="email" className={`${styles.FormControl}`} {...register("email")} />
                    {errors.email && (<span className={`${styles.ErrorM}`}>{errors.email.message}</span>)}
                  </div>
                </div>
              </div>
              <div className={`${styles.FormRow}`}>
                <div className={`${styles.FormGroup}`}>
                  <label className={`${styles.FormLabel}`}>Address*</label>
                  <input className={`${styles.FormControl}`} {...register("address")} />
                  {errors.address && (<span className={`${styles.ErrorM}`}>{errors.address.message}</span>)}
                </div>
              </div>
              <div className={`${styles.FormRow}`}>
                <div className={`${styles.FormRowHalf2}`}>
                  <div className={`${styles.FormGroup}`}>
                    <label className={`${styles.FormLabel}`}>Country/State*</label>
                    <input className={`${styles.FormControl}`} {...register("state")} />
                    {errors.state && (<span className={`${styles.ErrorM}`}>{errors.state.message}</span>)}
                  </div>
                </div>
                <div className={`${styles.FormRowHalf2}`}>
                  <div className={`${styles.FormGroup}`}>
                    <label className={`${styles.FormLabel}`}>City*</label>
                    <input className={`${styles.FormControl}`} {...register("city")} />
                    {errors.city && (<span className={`${styles.ErrorM}`}>{errors.city.message}</span>)}
                  </div>
                </div>
                <div className={`${styles.FormRowHalf2}`}>
                  <div className={`${styles.FormGroup}`}>
                    <label className={`${styles.FormLabel}`}>Zip/Postal Code*</label>
                    <Controller
                      control={control}
                      {...register("zip", { required: true })}
                      className={`${styles.FormControl}`}
                      render={({ field: { onChange, name, value } }) => (
                        <PatternFormat
                          format="######"
                          name={name}
                          value={value}
                          onChange={onChange}
                          className={`${styles.FormControl}`}
                        />
                      )}
                    />
                    {errors.zip && (<span className={`${styles.ErrorM}`}>{errors.zip.message}</span>)}
                  </div>
                </div>
              </div>
              <div className={`${styles.FormRow}`}>
                <div className={`${styles.FormRowHalf}`}>
                  <div className={`${styles.FormGroup}`}>
                    <label className={`${styles.FormLabel}`}>Number of Passenger(s)*</label>
                    <Controller
                      control={control}
                      name="no_of_people"
                      {...register("no_of_people")}
                      className={`${styles.FormControl}`}
                      render={({ field: { onChange, name, value } }) => (
                        <NumericFormat
                          isAllowed={(values, sourceInfo) => {
                            const { value } = values;
                            return value < 100;
                          }}
                          name={name}
                          value={value}
                          onChange={onChange}
                          className={`${styles.FormControl}`}
                        />
                      )}
                    />
                    {errors.no_of_people && (<span className={`${styles.ErrorM}`}>{errors.no_of_people.message}</span>)}
                  </div>
                </div>
                <div className={`${styles.FormRowHalf}`}>
                  <div className={`${styles.FormGroup}`}>
                    <label className={`${styles.FormLabel}`}>Driver's Age*</label>
                    <Controller
                      control={control}
                      name="age"
                      {...register("age")}
                      className={`${styles.FormControl}`}
                      render={({ field: { onChange, name, value } }) => (
                        <NumericFormat
                          isAllowed={(values, sourceInfo) => {
                            const { value } = values;
                            return value < 100;
                          }}
                          name={name}
                          value={value}
                          onChange={onChange}
                          className={`${styles.FormControl}`}
                        />
                      )}
                    />
                    {errors.age && (<span className={`${styles.ErrorM}`}>{errors.age.message}</span>)}
                  </div>
                </div>
              </div>
              <div className={`${styles.FormRow}`}>
                <div className={`${styles.FormRowHalf}`}>
                  <div className={`${styles.FormGroup}`}>
                    <label className={`${styles.FormLabel}`}>Arrival Airline Carrier*</label>
                    <input className={`${styles.FormControl}`} {...register("airline_carrier")} />
                    {errors.airline_carrier && (<span className={`${styles.ErrorM}`}>{errors.airline_carrier.message}</span>)}
                  </div>
                </div>
                <div className={`${styles.FormRowHalf}`}>
                  <div className={`${styles.FormGroup}`}>
                    <label className={`${styles.FormLabel}`}>Arrival Vessel Number*</label>
                    <input className={`${styles.FormControl}`} {...register("vessel_number")} />
                    {errors.vessel_number && (<span className={`${styles.ErrorM}`}>{errors.vessel_number.message}</span>)}
                  </div>
                </div>
              </div>
              <div className={`${styles.FormRow}`}>
                <div className={`${styles.FormRowHalf}`}>
                  <div className={`${styles.FormGroup}`}>
                    <label className={`${styles.FormLabel}`}>Departure Airline Carrier*</label>
                    <input className={`${styles.FormControl}`} {...register("departure_airline_carrier")} />
                    {errors.departure_airline_carrier && (<span className={`${styles.ErrorM}`}>{errors.departure_airline_carrier.message}</span>)}
                  </div>
                </div>
                <div className={`${styles.FormRowHalf}`}>
                  <div className={`${styles.FormGroup}`}>
                    <label className={`${styles.FormLabel}`}>Departure Vessel Number*</label>
                    <input className={`${styles.FormControl}`} {...register("departure_vessel_number")} />
                    {errors.departure_vessel_number && (<span className={`${styles.ErrorM}`}>{errors.departure_vessel_number.message}</span>)}
                  </div>
                </div>
              </div>
            </div>
            <div className={`${styles.FromSecStep}`}>
              <p className={`${styles.QuickBookingTitle}`}>Discount</p>
              <div className={`${styles.FormRow}`}>
                <div className={`${styles.FormRowHalf}`}>
                  <div className={`${styles.FormGroup}`}>
                    <label className={`${styles.FormLabel}`}>Discount (WST)</label>
                    <Controller
                      control={control}
                      {...register("discount")}
                      className={`${styles.FormControl}`}
                      render={({ field: { onChange, name, value } }) => (
                        <NumericFormat
                          name={name}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            e.target.value !== "" ? setDiscoutAmount(parseFloat(e.target.value)) : setDiscoutAmount(0);
                          }}
                          className={`${styles.FormControl}`}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={`${styles.FormButtonArea}`}>
              <Link to={`/admin/dashboard`} as={Button} className={`${styles.CancelBU}`}>Cancel</Link>
              <input className={`${styles.SubmitBU}`} value="Submit" type="submit" />
            </div>
          </form>
        </div>
      </div>
    </div>)}
  </React.Fragment>);
}

export default withAdminAuth(QuickBooking);