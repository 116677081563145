import { useEffect, useState } from "react";
import styles from "../reservation.module.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Calendar, Clock, MapPin, X } from "react-feather";
import moment from "moment";
import Select from "react-select";
import { Button, Dialog, DialogContent } from "@mui/material";

const LeftPanelBlock = ({ handleSelect, bookingData, carList, pickupLocationList, dropoffLocationList, addOnList, currency, currencyRate, currencyLoading, currentStep, updateReservationInfo }) => {
  const [selectedItem, setSelectedItem] = useState([]);
  const [selectedAddOns, setSelectedAddOns] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [subTotalAmount, setSubTotalAmount] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [discountAmount2, setDiscountAmount2] = useState(0);
  const [paymentFee, setPaymentFee] = useState(0);
  const [dropOffAmount, setDropOffAmount] = useState(0);
  const [openReser, setOpenReser] = useState(false);
  const [reservationData, setReservationData] = useState({ location: null, dropOffLocation: null, startDate: moment().toDate(), startTime: moment().toDate(), endDate: moment().toDate(), endTime: moment().toDate(), isDiscount: 0 });

  useEffect(() => {
    let carAmountTemp = 0;
    let totalAmountTemp = 0;
    let subTotalAmountTemp = 0;
    if (bookingData?.dropOffLocation_delivery_fee) {
      setDropOffAmount(bookingData?.dropOffLocation_delivery_fee);
    }

    if (bookingData?.securityDeposit) {
      totalAmountTemp += bookingData?.securityDeposit;
    }

    if (bookingData?.cars && bookingData?.cars?.length) {
      let carListTemp = carList.map((item) => {
        let isCar = bookingData?.cars.find((i) => i.car_id === item.id);
        if (isCar) {
          subTotalAmountTemp += bookingData?.days * item?.price * isCar?.quantity;
          carAmountTemp += bookingData?.days * item?.price * isCar?.quantity;
          item = { ...item, quantity: isCar.quantity };
          return item;
        }
        return null;
      });
      carListTemp = carListTemp.filter((el) => {
        return el != null;
      });
      setSelectedItem(carListTemp);
    }
    if (bookingData?.addOns && bookingData?.addOns?.length) {
      let addOnListTemp = addOnList.map((item) => {
        let isAddOn = bookingData?.addOns.find((i) => i.addon_id === item.id);
        if (isAddOn) {
          subTotalAmountTemp += item.payment_type === "Day Wise" ? bookingData?.days * item?.price * isAddOn?.quantity : item?.price * isAddOn?.quantity;
          item = { ...item, quantity: isAddOn.quantity };
          return item;
        }
        return null;
      });
      addOnListTemp = addOnListTemp.filter((el) => {
        return el != null;
      });
      setSelectedAddOns(addOnListTemp);
    } else {
      setSelectedAddOns([]);
    }
    totalAmountTemp += subTotalAmountTemp;
    let discountAmountTemp = 0;
    let discountAmountTemp2 = 0;
    if (bookingData?.promotionalCode?.code) {
      let disPercent = bookingData?.promotionalCode?.discount;
      disPercent = parseInt(disPercent);
      if (bookingData?.promotionalCode?.item_type === "") {
        discountAmountTemp2 = (subTotalAmountTemp * disPercent) / 100;
      }
      if (bookingData?.promotionalCode?.item_type === "car") {
        discountAmountTemp2 = (carAmountTemp * disPercent) / 100;
      }
      if (bookingData?.promotionalCode?.item_type === "addOn") {
        let addOnAmoutTemp = 0;
        addOnList.forEach((item) => {
          let isAddOn = bookingData?.addOns.find((i) => i.addon_id === item.id);
          if (isAddOn && item.id === bookingData?.promotionalCode?.item_id) {
            addOnAmoutTemp += item.payment_type === "Day Wise" ? bookingData?.days * item?.price * isAddOn?.quantity : item?.price * isAddOn?.quantity;
          }
        });
        discountAmountTemp2 = (addOnAmoutTemp * disPercent) / 100;
      }

      setDiscountAmount2(discountAmountTemp2);
      setDiscountAmount(0);
      totalAmountTemp -= discountAmountTemp2;
    } else {
      if (bookingData?.isDiscount === 1) {
        discountAmountTemp = (subTotalAmountTemp * 10) / 100;
        setDiscountAmount(discountAmountTemp);
        totalAmountTemp -= discountAmountTemp;
      }
    }

    let dropOffAmountTemp = 0;
    if (bookingData?.dropOffLocation_is_airport === 0 || bookingData?.days < 7) {
      totalAmountTemp += dropOffAmount;
      dropOffAmountTemp = dropOffAmount;
    }

    if (currentStep === 4) {
      let paymentFeeTemp = ((subTotalAmountTemp + dropOffAmountTemp - discountAmountTemp) * 3) / 100;
      paymentFeeTemp = paymentFeeTemp.toFixed(2);
      paymentFeeTemp = parseFloat(paymentFeeTemp);
      setPaymentFee(paymentFeeTemp);
      totalAmountTemp += paymentFeeTemp;
    }

    setSubTotalAmount(subTotalAmountTemp);
    setTotalAmount(totalAmountTemp);
  }, [bookingData, carList, pickupLocationList, dropoffLocationList, dropOffAmount, addOnList, currentStep]);

  useEffect(() => {
    if (bookingData) {
      let pickUpTime = moment().format("YYYY-MM-DD") + " " + bookingData.pickUpTime;
      let dropOffTime = moment().format("YYYY-MM-DD") + " " + bookingData.dropOffTime;

      setReservationData({
        location: {
          value: bookingData?.location,
          label: bookingData?.location_name,
          delivery_fee: bookingData?.dropOffLocation_delivery_fee,
          is_airport: bookingData?.dropOffLocation_is_airport,
        },
        dropOffLocation: {
          value: bookingData?.dropOffLocation,
          label: bookingData?.dropOffLocation_name,
        },
        startDate: moment(bookingData.pickUpDate).toDate(),
        endDate: moment(bookingData.dropOffDate).toDate(),
        startTime: moment(pickUpTime).toDate(),
        endTime: moment(dropOffTime).toDate(),
        isDiscount: bookingData.isDiscount,
      });
    }
  }, [bookingData]);

  const handleCloseReser = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setOpenReser(false);
  };

  const changeLocation = (event) => {
    setReservationData({ ...reservationData, location: event });
  };

  const changeDropOffLocation = (event) => {
    setReservationData({ ...reservationData, dropOffLocation: event });
  };

  const setStartDate = (event, date, type) => {
    if (type === "startDate") {
      let dayDiff2 = moment(date).diff(moment(), "days");
      let endDate = new Date(
        new Date(date).setDate(new Date(date).getDate() + 1)
      );
      if (dayDiff2 > 9) {
        setReservationData({ ...reservationData, startDate: date, endDate: endDate, isDiscount: 1 });
      } else {
        setReservationData({ ...reservationData, startDate: date, endDate: endDate, isDiscount: 0 });
      }
    }
    if (type === "endDate") {
      setReservationData({ ...reservationData, endDate: date });
    }
    if (type === "startTime") {
      setReservationData({ ...reservationData, startTime: date });
    }
    if (type === "endTime") {
      setReservationData({ ...reservationData, endTime: date });
    }
  };

  return (<>
    <div className={`${styles.SmallSecSide}`}>
      <div className={`${styles.SmallSecSideCard}`}>
        <p className={`${styles.SmallSecSideTitle}`}>Rental Details</p>
        <div className={`${styles.SmallCardDESec}`}>
          <div className={`${styles.SmallCardDESecHead}`}>
            <p className={`${styles.SmallCardDESecTitle}`}>Dates & Times</p>
            <button className={`${styles.SideEditBU}`} onClick={(e) => setOpenReser(true)}>Edit</button>
          </div>
          <p className={`${styles.SmallCardDEList}`}>{moment(bookingData?.pickUpDate).format("ddd, MMM D, YYYY")} @ {moment(bookingData?.pickUpDate + " " + bookingData?.pickUpTime).format("hh:mm A")}</p>
          <p className={`${styles.SmallCardDEList}`}>{moment(bookingData?.dropOffDate).format("ddd, MMM D, YYYY")} @ {moment(bookingData?.dropOffDate + " " + bookingData?.dropOffTime).format("hh:mm A")}</p>
        </div>
        <div className={`${styles.SmallCardDESec}`}>
          <div className={`${styles.SmallCardDESecHead}`}>
            <p className={`${styles.SmallCardDESecTitle}`}>Pick-up Location</p>
            <button className={`${styles.SideEditBU}`} onClick={(e) => setOpenReser(true)}>Edit</button>
          </div>
          <p className={`${styles.SmallCardDEList}`}>{bookingData?.location_name}</p>
        </div>
        <div className={`${styles.SmallCardDESec}`}>
          <div className={`${styles.SmallCardDESecHead}`}>
            <p className={`${styles.SmallCardDESecTitle}`}>Drop-off Location</p>
            <button className={`${styles.SideEditBU}`} onClick={(e) => setOpenReser(true)}>Edit</button>
          </div>
          <p className={`${styles.SmallCardDEList}`}>{bookingData?.dropOffLocation_name}</p>
        </div>
      </div>

      {bookingData?.cars && bookingData?.cars?.length > 0 && (<div className={`${styles.SmallSecSideCard}`}>
        {selectedItem?.map((carItem, index1) => {
          return (<div key={index1}>
            <div className={`${styles.SideCarDe}`}>
              <div className={`${styles.CarDetailsSec}`}>
                <p className={`${styles.CarDetailsSecTitle}`}>{carItem?.title}</p>
                <p className={`${styles.CarDetailsSecSText}`}>{carItem?.transmission} {(carItem?.air_conditioning === 1 || carItem?.air_conditioning === "1") && ", A/C"}</p>
                <p className={`${styles.CarDetailsSecSeat}`}>
                  <img src="/images/user.png" alt="" />{" "}
                  {carItem?.passengers} People
                </p>
              </div>
              <div className={`${styles.SideCarIMG}`}>
                <img src={carItem?.image_url} alt="" />
              </div>
            </div>
            <div className={`${styles.SmallCardDESec}`}>
              <div className={`${styles.SmallCardDESecHead}`}>
                <p className={`${styles.SmallCardDESecTitle}`}>{carItem?.quantity} Vehicle</p>
                <button className={`${styles.SideEditBU}`} onClick={handleSelect.bind(this, 0)}>Edit</button>
              </div>
              <div className={`${styles.SideCarDeBodyRow}`}>
                <p className={`${styles.SmallCardDEList}`}>Time {bookingData?.days} Day(s) @ {!currencyLoading && currency + " " + (carItem?.price * currencyRate).toFixed(2) + "/Day"}</p>
                <p className={`${styles.SmallCardDEList}`}> {!currencyLoading && currency + " " + (bookingData?.days * carItem?.price * carItem?.quantity * currencyRate).toFixed(2)}</p>
              </div>
              <div className={`${styles.SideCarDeBodyRow}`}>
                <p className={`${styles.SmallCardDEList}`}>Unlimited Mileage</p>
                <p className={`${styles.SmallCardDEList}`}>Included</p>
              </div>
            </div>
          </div>);
        })}
        {selectedAddOns.length > 0 && (<div className={`${styles.SmallCardDESec}`}>
          <div className={`${styles.SmallCardDESecHead}`}>
            <p className={`${styles.SmallCardDESecTitle}`}>Extras</p>
            <button className={`${styles.SideEditBU}`} onClick={handleSelect.bind(this, 1)}>Edit</button>
          </div>
          {selectedAddOns?.map((item, index) => {
            return (<div className={`${styles.SideCarDeBodyRow}`} key={index}>
              {item.payment_type === "Day Wise" ? (<p className={`${styles.SmallCardDEList} ${styles.ExtraLong}`}>{item?.quantity} X {item?.title} {bookingData?.days} Day(s) @ {!currencyLoading && currency + " " + (item?.price * currencyRate).toFixed(2) + "/Day"}</p>) : (<p className={`${styles.SmallCardDEList} ${styles.ExtraLong}`}>{item?.quantity} X {item?.title} @ {!currencyLoading && currency + " " + (item?.price * currencyRate).toFixed(2)}</p>)}
              {item.payment_type === "Day Wise" ? (<p className={`${styles.SmallCardDEList} ${styles.ExtraAmmo}`}>{!currencyLoading && currency + " " + (bookingData?.days * item?.price * item?.quantity * currencyRate).toFixed(2)}</p>) : (<p className={`${styles.SmallCardDEList} ${styles.ExtraAmmo}`}>{!currencyLoading && currency + " " + (item?.price * item?.quantity * currencyRate).toFixed(2)}</p>)}
            </div>);
          })}
        </div>)}
        <div className={`${styles.SmallCardTaxSec} ${styles.SmallCardTaxSec2}`}>
          <p className={`${styles.SmallCardTaxTitle}`}>Sub Total</p>
          <p className={`${styles.SmallCardTaxAmm}`}> {!currencyLoading && currency + " " + (subTotalAmount * currencyRate).toFixed(2)}</p>
        </div>
        {!bookingData?.promotionalCode?.code && bookingData?.isDiscount === 1 && (<div className={`${styles.SmallCardTaxSec} ${styles.SmallCardTaxSec2}`}>
          <p className={`${styles.SmallCardTaxTitle}`}>Discount</p>
          <p className={`${styles.SmallCardTaxAmm}`}> {!currencyLoading && currency + " -" + (discountAmount * currencyRate).toFixed(2)}</p>
        </div>)}
        {bookingData?.promotionalCode?.code && (<div className={`${styles.SmallCardTaxSec} ${styles.SmallCardTaxSec2}`}>
          <p className={`${styles.SmallCardTaxTitle}`}>Promotional Code <br /><span style={{ fontSize: "12px", fontWeight: 500 }}>{bookingData?.promotionalCode?.code}</span></p>
          <p className={`${styles.SmallCardTaxAmm}`}> {!currencyLoading && currency + " -" + (discountAmount2 * currencyRate).toFixed(2)}</p>
        </div>)}
        {(bookingData?.dropOffLocation_is_airport === 0 || bookingData?.days < 7) && (<div className={`${styles.SmallCardTaxSec} ${styles.SmallCardTaxSec2}`}>
          <p className={`${styles.SmallCardTaxTitle}`}>Delivery Fee</p>
          <p className={`${styles.SmallCardTaxAmm}`}> {!currencyLoading && currency + " " + (dropOffAmount * currencyRate).toFixed(2)}</p>
        </div>)}
        <div className={currentStep === 4 ? `${styles.SmallCardTaxSec} ${styles.SmallCardTaxSec2}` : `${styles.SmallCardTaxSec}`}>
          <p className={`${styles.SmallCardTaxTitle}`}>Security Amount</p>
          <p className={`${styles.SmallCardTaxAmm}`}> {!currencyLoading && currency + " " + (bookingData?.securityDeposit * currencyRate).toFixed(2)}</p>
        </div>
        {currentStep === 4 && (<div className={`${styles.SmallCardTaxSec}`}>
          <p className={`${styles.SmallCardTaxTitle}`}>Payment Fee (3%)</p>
          <p className={`${styles.SmallCardTaxAmm}`}> {!currencyLoading && currency + " " + (paymentFee * currencyRate).toFixed(2)}</p>
        </div>)}
        <div className={`${styles.SmallCardTotal}`}>
          <p className={`${styles.SmallCardTaxTitle}`}>Grand Total</p>
          <p className={`${styles.SmallCardTOtalAmm}`}>{!currencyLoading && currency + " " + (totalAmount * currencyRate).toFixed(2)}</p>
        </div>
      </div>)}
    </div>

    <Dialog
      maxWidth="md"
      open={openReser}
      onClose={handleCloseReser}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <div className={`${styles.ImportantInfoSec}`}>
          <Button onClick={handleCloseReser} className={`${styles.InfoPopBU}`}><X /></Button>
          <div className={`${styles.ReserpopupSec}`}>
            <div className={`${styles.BannerSearchLocation}`}>
              <div className={`${styles.FixedFullSec}`}>
                <label className={`${styles.BannerFormLabel} ${styles.LabelCus}`}>Pick Up Location</label>
                <div className={`${styles.FormSec}`}>
                  <MapPin />
                  <Select
                    className={`${styles.FormInput}`}
                    placeholder={"Select Airport"}
                    options={pickupLocationList}
                    defaultValue={reservationData.location}
                    onChange={changeLocation}
                  />
                </div>
              </div>
              <div className={`${styles.FixedFullSec}`}>
                <label className={`${styles.BannerFormLabel} ${styles.LabelCus}`}>Drop Off Location</label>
                <div className={`${styles.FormSec}`}>
                  <MapPin />
                  <Select
                    className={`${styles.FormInput}`}
                    placeholder={"Select Airport"}
                    options={dropoffLocationList}
                    defaultValue={reservationData.dropOffLocation}
                    onChange={changeDropOffLocation}
                  />
                </div>
              </div>
            </div>
            <div className={`${styles.BannerSearchDate}`}>
              <div className={`${styles.FixedFullSec}`}>
                <label className={`${styles.BannerFormLabel}`}>Pick-Up Date</label>
                <div className={`${styles.FormSec}`}>
                  <Calendar />
                  <DatePicker
                    selected={reservationData.startDate}
                    minDate={moment().toDate()}
                    maxDate={moment().add(1, "y").toDate()}
                    onChange={(date) => setStartDate(this, date, "startDate")}
                    className={`${styles.FormInput}`}
                    dateFormat="eee, d MMM y"
                  />
                </div>
              </div>
              <div className={`${styles.FixedFullSec}`}>
                <label className={`${styles.BannerFormLabel}`}>Drop-Off Date</label>
                <div className={`${styles.FormSec}`}>
                  <Calendar />
                  <DatePicker
                    selected={reservationData.endDate}
                    minDate={reservationData.startDate}
                    maxDate={moment().add(1, "y").toDate()}
                    onChange={(date) => setStartDate(this, date, "endDate")}
                    className={`${styles.FormInput}`}
                    dateFormat="eee, d MMM y"
                  />
                </div>
              </div>
            </div>
            <div className={`${styles.BannerSearchTime}`}>
              <div className={`${styles.FixedFullSec}`}>
                <label className={`${styles.BannerFormLabel}`}>Pick-Up Time</label>
                <div className={`${styles.FormSec}`}>
                  <Clock />
                  <DatePicker
                    selected={reservationData.startTime}
                    onChange={(date) => setStartDate(this, date, "startTime")}
                    className={`${styles.FormInput}`}
                    dateFormat="p"
                    showTimeSelect
                    timeFormat="p"
                    timeIntervals={30}
                    showTimeSelectOnly
                  />
                </div>
              </div>
              <div className={`${styles.FixedFullSec}`}>
                <label className={`${styles.BannerFormLabel}`}>Drop-Off Time</label>
                <div className={`${styles.FormSec}`}>
                  <Clock />
                  <DatePicker
                    selected={reservationData.endTime}
                    onChange={(date) => setStartDate(this, date, "endTime")}
                    className={`${styles.FormInput}`}
                    dateFormat="p"
                    showTimeSelect
                    timeFormat="p"
                    timeIntervals={30}
                    showTimeSelectOnly
                  />
                </div>
              </div>
            </div>
            <div className={`${styles.BannerSearchButton}`}>
              <button type="buttom" className={`${styles.SearchBU}`} onClick={(e) => { setOpenReser(false); updateReservationInfo(reservationData); }}>
                <span>Continue Reservation</span>
              </button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  </>);
};

export default LeftPanelBlock;