//import * as React , { useState, useEffect } from 'react';
import React, { useEffect } from "react";
import styles from './faq.module.css';
import { Parallax } from 'react-parallax';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';

export default function FAQ() {
  const [expanded, setExpanded] = React.useState('panel1');
  const [faqList, setFaqList] = React.useState([]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    axios(process.env.REACT_APP_API_URL + 'v1/faq-list').then(response => {
      let allData = response.data.data;
      setFaqList(allData);

    }).catch(error => {
      setFaqList([]);
    });
  }, []);

  return (<React.Fragment>
    <div className="InnerBanner">
      <Parallax bgImage="/images/faq-banner.jpg" strength={400}>
        <div style={{ height: 450 }} className={`${styles.BannerConArea}`}>
          <div className='container'>
            <div className={`${styles.InnerBannerRow}`}>
              <h2 className={`${styles.InnerBannerTitle}`}>Frequently Asked Questions</h2>
            </div>
          </div>
        </div>
      </Parallax>
    </div>

    <div className={`${styles.FAQSec}`}>
      <div className="container">
        <div className={`${styles.FAQRow} FAQArea`}>
          {faqList?.map((item, index) => {
            return (<Accordion expanded={expanded === index} onChange={handleChange(index)}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                <Typography className={`${styles.FAQTitle}`}>{item.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <p className={`${styles.FAQCon}`}>{item.answer}</p>
              </AccordionDetails>
            </Accordion>)
          })}
        </div>
      </div>
    </div>
  </React.Fragment>)
}