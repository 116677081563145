import React, { useState, useEffect } from 'react';
import styles from './add.module.css';
import { Typography, Breadcrumbs, Button } from '@mui/material';
import { useNavigate, Link, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import axios from 'axios';
import { toast } from 'react-smart-toaster';
import { FromLoader } from '../../components/LoaderC';
import Loader from '../../components/Loader';
import withAdminAuth from "../../components/withAdminAuth";
import { authData } from "../../components/getAuth";
import { Upload, X, } from 'react-feather';

function BlogAdd({ history, match }) {
  const auth = authData();
  const audience = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  let { id } = useParams();
  const isAddMode = !id;
  const [loading, setLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(true);
  const [ImgClass, setImgClass] = useState({ progressBar: 'hide', progress: 0, imageBlock: 'hide', imagePathArr: '', imageNameArr: '' });
  const [ImgPathTemp, setImgPathTemp] = useState({ Files: '', imagePathArr: '' });

  const schema = yup.object().shape({
    title: yup.string().required('This field is Required'),
    description: yup.string().required('This field is Required'),
    link: yup.string().required('This field is Required'),
    imagefile: yup.mixed().test("required", "Please upload a file", (file) => {
      if (ImgClass?.imagePathArr !== '') return true;
      if (ImgPathTemp?.imagePathArr !== '') return true;
      return false;
    })
  });

  const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm({
    resolver: yupResolver(schema), mode: 'onChange'
  });

  useEffect(() => {
    if (!isAddMode) {
      if (contentLoading) {
        axios.get(audience + 'v1/blogs/' + id, {
          headers: {
            Authorization: `Bearer ${auth?.api_token}`,
          }
        }).then(response => {
          setContentLoading(false);
          let itemData = response.data.data;
          setValue('title', itemData.title);
          setValue('description', itemData.description);
          setValue('link', itemData.link);
          setImgClass({ ...ImgClass, imagePathArr: itemData.image_url });

        }).catch(error => {
          setContentLoading(false);
        });
      }
    } else {
      setContentLoading(false);
    }
  }, [isAddMode, audience, id, setValue, contentLoading, auth, ImgClass]);

  async function onSubmit(data) {
    let formData = new FormData();
    Object.keys(data).map((key) => {
      if (key !== 'imagefile') {
        formData.append(key, data[key]);
      }
      return true;
    });

    if (ImgPathTemp.Files) {
      formData.append("image", ImgPathTemp.Files);
    }

    const postData = formData;

    setLoading(true);

    if (isAddMode) {
      return axios.post(audience + 'v1/blogs', postData, {
        headers: {
          Authorization: `Bearer ${auth?.api_token}`,
        }
      }).then(response => {
        setLoading(false);
        toast.success(response.data.message);
        navigate('/admin/blog/list');
      }).catch(error => {
        setLoading(false);
      });
    } else {
      return axios.post(audience + 'v1/blogs/' + id, postData, {
        headers: {
          Authorization: `Bearer ${auth?.api_token}`,
        }
      }).then(response => {
        setLoading(false);
        toast.success(response.data.message);
        navigate('/admin/blog/list');
      }).catch(error => {
        setLoading(false);
      });
    }
  }

  const fileHandler = (e) => {
    var validExtensions = ['jpg', 'jpeg', 'png'];
    var fileName = e.target.files[0].name;
    var fileNameExt = fileName.substr(fileName.lastIndexOf('.') + 1);
    if (validExtensions.indexOf(fileNameExt.toLowerCase()) === -1) {
      toast.error('File type is not allowed');
    } else if (e.target.files[0].size > 5242880) {
      toast.error('Image size should not be greater than 5 MB.');
    } else {
      let tempFiles = ImgPathTemp.Files;
      let tempImages = ImgPathTemp.imagePathArr;
      let file = e.target.files[0];
      tempFiles = file;
      tempImages = URL.createObjectURL(file);
      setImgPathTemp({ imagePathArr: tempImages, Files: tempFiles });

      deleteImage();
    }
  }

  function deleteTempImage() {
    setValue('imagefile', null);
    setImgPathTemp({ imagePathArr: '', Files: "" });
  }

  function deleteImage() {
    setImgClass({ ...ImgClass, imagePathArr: '', imageNameArr: '' });
  }

  function fileUploadButtonClickTemp() {
    document.getElementById('fileUploadID').getElementsByClassName('inputBtn')[0].click();
    return false;
  }
  return (<React.Fragment>
    {loading && <Loader />}

    {contentLoading && <div className="LoaderCard"><div className="Loader FromLoader"><FromLoader /></div></div>}

    {!contentLoading && <div className={`${styles.MainDiv}`}>
      <div className={`${styles.PageTitleWrap}`}>
        <p className={`${styles.PageTitle}`}>Blogs {isAddMode ? 'Add' : 'Edit'}</p>
        <Breadcrumbs aria-label="breadcrumb" className='breadcrumb'>
          <Link underline="hover" color="inherit" to="/admin/dashboard">Dashboard</Link>
          <Link underline="hover" color="inherit" to={'/admin/blog/list'}>Blogs</Link>
          <Typography color="text.primary">{isAddMode ? 'Add' : 'Edit'}</Typography>
        </Breadcrumbs>
      </div>

      <div className={`${styles.MainCard}`}>
        <div className={`${styles.AddUserMain}`}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroupFull}`}>
                <label className={`${styles.FormLabel}`}>Title*</label>
                <input {...register("title")} className={`${styles.FormControl}`} />
                {errors.title && !watch().title && <span className={`${styles.ErrorM}`}>{errors.title.message}</span>}
              </div>
            </div>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroupFull}`}>
                <label className={`${styles.FormLabel}`}>Link*</label>
                <input {...register("link")} className={`${styles.FormControl}`} />
                {errors.link && !watch().link && <span className={`${styles.ErrorM}`}>{errors.link.message}</span>}
              </div>
            </div>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroupFull}`}>
                <label className={`${styles.FormLabel}`}>Short Desacription*</label>
                <textarea {...register("description")} className={`${styles.FormControlTextArea}`} rows="5"></textarea>
                {errors.description && !watch().description && <span className={`${styles.ErrorM}`}>{errors.description.message}</span>}
              </div>
            </div>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroupFull}`}>
                <div className={`${styles.FileIMGDiv}`}>
                  <div className={`${styles.FileUpDuBUDiv}`}>
                    <label htmlFor="file-upload" className={`${styles.UploadLabel}`} id="fileUploadID">
                      <input type="file" className="inputBtn"  {...register('imagefile')} onChange={fileHandler} accept=".jpg,.png,.jpeg" inputprops={{ accept: 'image/*' }} />
                      <span onClick={fileUploadButtonClickTemp.bind()}>
                        <div className={`${styles.UpIcon}`}><Upload /></div> <p>Upload Photo</p>
                      </span>
                      <p className={`${styles.UploadText}`}>File size upto 100 KB</p>
                      <p className={`${styles.UploadText2}`}>JPEG, JPG, PNG</p>
                    </label>

                    <span className={'e-upload-progress-wrap ' + ImgClass.progressBar}>
                      <span className="e-progress-inner-wrap"></span>
                      <span className="e-progress-bar-text">{ImgClass.progress}%</span>
                    </span>
                  </div>
                  {ImgClass.imagePathArr && <div className={`${styles.ImgFile}`}>
                    <img src={ImgClass.imagePathArr} alt="" />
                    <Button onClick={deleteImage.bind(this)}><X /></Button>
                  </div>}
                  {ImgPathTemp.imagePathArr && ImgPathTemp.imagePathArr !== '' && <div className={`${styles.ImgFile}`}>
                    <img src={ImgPathTemp.imagePathArr} alt="" />
                    <Button onClick={deleteTempImage.bind(this)}><X /></Button>
                  </div>}
                </div>
                {errors.imagefile && <p className={`${styles.ErrorM}`}>{errors.imagefile.message}</p>}
              </div>
            </div>

            <div className={`${styles.FormButtonArea}`}>
              <Link to={`/admin/blog/list`} as={Button} className={`${styles.CancelBU}`}>Cancel</Link>
              <input className={`${styles.SubmitBU}`} value="Submit" type="submit" />
            </div>
          </form>
        </div>

      </div>
    </div>}
  </React.Fragment>
  )
}

export default withAdminAuth(BlogAdd);