import React from "react";
import styles from "./ferry-payment-failed.module.css";

export default function FerryPaymentFailed() {
  return (<React.Fragment>
    <div className={`${styles.BookingDone}`}>
      <img src="/images/check.png" alt="" />
      <p className={`${styles.BookingTitle}`}>Sorry!</p>
      <p className={`${styles.BookingSmTitle}`}>Your ferry slot booking is not completed</p>
    </div>
  </React.Fragment>);
}