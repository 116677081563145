import React, { useState, useEffect } from "react";
import styles from "./add.module.css";
import { Typography, Breadcrumbs, Button, Checkbox } from "@mui/material";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { NumericFormat } from "react-number-format";
import { toast } from "react-smart-toaster";
import { FromLoader } from "../../components/LoaderC";
import Loader from "../../components/Loader";
import withAdminAuth from "../../components/withAdminAuth";
import { authData } from "../../components/getAuth";
import moment from "moment/moment";

const timeSlots = () => {
  let slots = [];
  let cDate = moment().format('YYYY-MM-DD');
  let momentObj = cDate + ' 06:00:00';
  for (let i = 0; i <= 10; i++) {
    slots.push({ label: moment(momentObj).add(i, 'hour').format('hh:mm A'), value: moment(momentObj).add(i, 'hour').format('HH:mm:ss') });
  }
  return slots;
}

function FerryTicketAdd({ history, match }) {
  const auth = authData();
  const audience = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  let { id } = useParams();
  const isAddMode = !id;
  const [loading, setLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(true);

  const schema = yup.object().shape({
    destination_name1: yup.string().required("This field is Required").max(100, "Name cannot be more than 100 characters"),
    destination_name2: yup.string().required("This field is Required").max(100, "Name cannot be more than 100 characters"),
    amount: yup.string().required("This field is Required"),
  });

  const { register, handleSubmit, control, setValue, formState: { errors }, watch, getValues } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      destination_name1: '',
      destination_name2: '',
      available_days1: [{ 'label': 'Sunday' }, { 'label': 'Monday' }, { 'label': 'Tuesday' }, { 'label': 'Wednesday' }, { 'label': 'Thursday' }, { 'label': 'Friday' }, { 'label': 'Saturday' }],
      available_days2: [{ 'label': 'Sunday' }, { 'label': 'Monday' }, { 'label': 'Tuesday' }, { 'label': 'Wednesday' }, { 'label': 'Thursday' }, { 'label': 'Friday' }, { 'label': 'Saturday' }],
    }
  });

  const { fields: fields1 } = useFieldArray({ control, name: "available_days1" });
  const { fields: fields2 } = useFieldArray({ control, name: "available_days2" });

  useEffect(() => {
    if (!isAddMode) {
      if (contentLoading) {
        axios.get(audience + "v1/ferry-tickets/" + id, {
          headers: {
            Authorization: `Bearer ${auth?.api_token}`
          }
        }).then((response) => {
          setContentLoading(false);
          let itemData = response.data.data;

          console.log('itemData');
          console.log(itemData);

          setValue("destination_name1", itemData.destination_name1);
          setValue("destination_name2", itemData.destination_name2);
          setValue("amount", itemData.amount);
          let available_days1 = itemData.available_days1;
          let available_days2 = itemData.available_days2;

          console.log('available_days1');
          console.log(available_days1);
          console.log('available_days2');
          console.log(available_days2);

          available_days1 = available_days1?.map((item, index) => {
            let weekName;
            if (index === 1) {
              weekName = 'Monday';
            } else if (index === 2) {
              weekName = 'Tuesday';
            } else if (index === 3) {
              weekName = 'Wednesday';
            } else if (index === 4) {
              weekName = 'Thursday';
            } else if (index === 5) {
              weekName = 'Friday';
            } else if (index === 6) {
              weekName = 'Saturday';
            } else {
              weekName = 'Sunday';
            }
            let slots = timeSlots()?.map(subItem => {
              if (item.indexOf(subItem?.value) > -1)
                return subItem?.value;
              return false;
            });
            return { 'label': weekName, 'slots': slots };
          });
          available_days2 = available_days2?.map((item, index) => {
            let weekName;
            if (index === 1) {
              weekName = 'Monday';
            } else if (index === 2) {
              weekName = 'Tuesday';
            } else if (index === 3) {
              weekName = 'Wednesday';
            } else if (index === 4) {
              weekName = 'Thursday';
            } else if (index === 5) {
              weekName = 'Friday';
            } else if (index === 6) {
              weekName = 'Saturday';
            } else {
              weekName = 'Sunday';
            }
            let slots = timeSlots()?.map(subItem => {
              if (item.indexOf(subItem?.value) > -1)
                return subItem?.value;
              return false;
            });
            return { 'label': weekName, 'slots': slots };
          });

          console.log('available_days1');
          console.log(available_days1);
          console.log('available_days2');
          console.log(available_days2);

          setValue("available_days1", available_days1);
          setValue("available_days2", available_days2);
        }).catch((error) => {
          setContentLoading(false);
        });
      }
    } else {
      setContentLoading(false);
    }
  }, [isAddMode, audience, id, setValue, contentLoading, auth]);

  async function onSubmit(data) {
    let available_days1 = [];
    let available_days2 = [];

    for (let i in data?.available_days1) {
      let aDays = data?.available_days1[i];

      let slots = aDays?.slots?.filter(i => i !== false).map(i => i);
      available_days1.push(slots);
    }

    for (let i in data?.available_days2) {
      let aDays = data?.available_days2[i];

      let slots = aDays?.slots?.filter(i => i !== false).map(i => i);
      available_days2.push(slots);
    }

    data = { ...data, available_days1: available_days1, available_days2: available_days2 };

    setLoading(true);

    if (isAddMode) {
      return axios.post(audience + "v1/ferry-tickets", data, {
        headers: {
          Authorization: `Bearer ${auth?.api_token}`
        }
      }).then((response) => {
        setLoading(false);
        toast.success(response.data.message);
        navigate("/admin/ferry-ticket/list");
      }).catch((error) => {
        setLoading(false);
      });
    } else {
      return axios.post(audience + "v1/ferry-tickets/" + id, data, {
        headers: {
          Authorization: `Bearer ${auth?.api_token}`
        }
      }).then((response) => {
        setLoading(false);
        toast.success(response.data.message);
        navigate("/admin/ferry-ticket/list");
      }).catch((error) => {
        setLoading(false);
      });
    }
  }

  return (<React.Fragment>
    {loading && <Loader />}

    {contentLoading && (<div className="LoaderCard">
      <div className="Loader FromLoader"><FromLoader /></div>
    </div>)}

    {!contentLoading && (<div className={`${styles.MainDiv}`}>
      <div className={`${styles.PageTitleWrap}`}>
        <p className={`${styles.PageTitle}`}>Ferry Ticket {isAddMode ? "Add" : "Edit"}</p>
        <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
          <Link underline="hover" color="inherit" to="/admin/dashboard">Dashboard</Link>
          <Link underline="hover" color="inherit" to={"/admin/ferry-ticket/list"}>Ferry Ticket</Link>
          <Typography color="text.primary">{isAddMode ? "Add" : "Edit"}</Typography>
        </Breadcrumbs>
      </div>

      <div className={`${styles.MainCard}`}>
        <div className={`${styles.AddUserMain}`}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroup}`}>
                <label className={`${styles.FormLabel}`}>Destination 1*</label>
                <input {...register("destination_name1")} className={`${styles.FormControl}`} />
                {errors.destination_name1 && (<span className={`${styles.ErrorM}`}>{errors.destination_name1.message}</span>)}
              </div>
              <div className={`${styles.FormGroup}`}>
                <label className={`${styles.FormLabel}`}>Destination 2*</label>
                <input {...register("destination_name2")} className={`${styles.FormControl}`} />
                {errors.destination_name2 && (<span className={`${styles.ErrorM}`}>{errors.destination_name2.message}</span>)}
              </div>
            </div>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroup}`}>
                <label className={`${styles.FormLabel}`}>Charge(WST)*</label>
                <Controller
                  control={control}
                  name="amount"
                  className={`${styles.FormControl}`}
                  render={({ field: { onChange, name, value } }) => (<NumericFormat
                    decimalScale={2}
                    name={name}
                    value={value}
                    onChange={onChange}
                    className={`${styles.FormControl}`}
                  />)}
                />
                {errors.amount && (<span className={`${styles.ErrorM}`}>{errors.amount.message}</span>)}
              </div>
            </div>

            <div>
              <div>
                <label className={`${styles.FormLabel}`}>Select Available Slots {(watch('destination_name1') && watch('destination_name2') && getValues('destination_name1') !== '' && getValues('destination_name2') !== '') && '(' + getValues('destination_name1') + " -> " + getValues('destination_name2') + ')'}*</label>
                <div className={`${styles.SlotSecMain}`}>
                  {fields1?.map((field, index) => {
                    return (<div className={`${styles.WeeklySlotSec}`}>
                      <div className={`${styles.WeeklySlotDay}`}>
                        <h5>{field?.label}</h5>
                        <p>{watch('available_days1') && getValues(`available_days1.${index}.slots`) ? (getValues(`available_days1.${index}.slots`)?.filter(i => i))?.length : 0} Slots are selected.</p>
                      </div>
                      <div className={`${styles.SelectSec}`}>
                        {timeSlots()?.map((item, subIndex) => {
                          return <div className={watch('available_days1') && getValues(`available_days1.${index}.slots.${subIndex}`) ? `${styles.SelectBoxSelected}` : `${styles.SelectBox}`}>
                            <Checkbox {...register(`available_days1.${index}.slots.${subIndex}`)} value={item?.value} defaultChecked={field?.slots && field?.slots[subIndex]} />
                            {item?.label}
                          </div>
                        })}
                      </div>
                    </div>);
                  })}
                </div>
              </div>
            </div>

            <div>
              <div>
                <label className={`${styles.FormLabel}`}>Select Available Slots {(watch('destination_name1') && watch('destination_name2') && getValues('destination_name1') !== '' && getValues('destination_name2') !== '') && '(' + getValues('destination_name2') + " -> " + getValues('destination_name1') + ')'}*</label>
                <div className={`${styles.SlotSecMain}`}>
                  {fields2?.map((field, index) => {
                    return (<div className={`${styles.WeeklySlotSec}`}>
                      <div className={`${styles.WeeklySlotDay}`}>
                        <h5>{field?.label}</h5>
                        <p>{watch('available_days2') && getValues(`available_days2.${index}.slots`) ? (getValues(`available_days2.${index}.slots`)?.filter(i => i))?.length : 0} Slots are selected.</p>
                      </div>
                      <div className={`${styles.SelectSec}`}>
                        {timeSlots()?.map((item, subIndex) => {
                          return <div className={watch('available_days2') && getValues(`available_days2.${index}.slots.${subIndex}`) ? `${styles.SelectBoxSelected}` : `${styles.SelectBox}`}>
                            <Checkbox {...register(`available_days2.${index}.slots.${subIndex}`)} value={item?.value} defaultChecked={field?.slots && field?.slots[subIndex]} />
                            {item?.label}
                          </div>
                        })}
                      </div>
                    </div>);
                  })}
                </div>
              </div>
            </div>

            <div className={`${styles.FormButtonArea}`}>
              <Link to={`/admin/ferry-ticket/list`} as={Button} className={`${styles.CancelBU}`}>Cancel</Link>
              <input className={`${styles.SubmitBU}`} value="Submit" type="submit" />
            </div>
          </form>
        </div>
      </div>
    </div>)}
  </React.Fragment>);
}

export default withAdminAuth(FerryTicketAdd);