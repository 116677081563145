import React, { useState, useEffect } from 'react';
import styles from './add.module.css';
import { Breadcrumbs } from '@mui/material';
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import axios from 'axios';
import { toast } from 'react-smart-toaster';
import { FromLoader } from '../../components/LoaderC';
import Loader from '../../components/Loader';
import withAdminAuth from "../../components/withAdminAuth";
import { authData } from "../../components/getAuth";
import { Editor } from '@tinymce/tinymce-react';

function PrivacyPolicy({ history, match }) {
  const auth = authData();
  const audience = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(true);
  const [editorVal, setEditorVal] = useState('');
  const schema = yup.object().shape({
    description: yup.string().nullable(),
  });

  const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm({
    resolver: yupResolver(schema), mode: 'onChange'
  });

  useEffect(() => {
    if (contentLoading) {
      axios.get(audience + 'v1/page-manager/privacy-policy', {
        headers: {
          Authorization: `Bearer ${auth?.api_token}`,
        }
      }).then(response => {
        setContentLoading(false);
        let itemData = response.data.data;
        setEditorVal(itemData.description);
      }).catch(error => {
        setContentLoading(false);
      });
    }
  }, [audience, setValue, contentLoading, auth]);

  async function onSubmit(data) {
    data.title = 'privacy-policy';
    const postData = data;
    setLoading(true);
    return axios.post(audience + 'v1/page-manager', postData, {
      headers: {
        Authorization: `Bearer ${auth?.api_token}`,
      }
    }).then(response => {
      setLoading(false);
      toast.success(response.data.message);
      navigate('/admin/page-manager/privacy-policy');
    }).catch(error => {
      setLoading(false);
    });
  }

  const handleEditorChange = (content, editor) => {
    setEditorVal(content);
    setValue('description', content);
  }

  return (<React.Fragment>

    {loading && <Loader />}

    {contentLoading && <div className="LoaderCard"><div className="Loader FromLoader"><FromLoader /></div></div>}

    {!contentLoading && <div className={`${styles.MainDiv}`}>
      <div className={`${styles.PageTitleWrap}`}>
        <p className={`${styles.PageTitle}`}>Privacy Policy Update</p>
        <Breadcrumbs aria-label="breadcrumb" className='breadcrumb'>
          <Link underline="hover" color="inherit" to="/admin/dashboard">Dashboard</Link>
        </Breadcrumbs>
      </div>

      <div className={`${styles.MainCard}`}>
        <div className={`${styles.AddUserMain}`}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={`${styles.FormRow}`}>
              <div className={`${styles.FormGroupFull}`}>
                <label className={`${styles.FormLabel}`}>Description*</label>
                <input type="hidden" {...register("description")} className={`${styles.FormControl}`} />
                <Editor onEditorChange={handleEditorChange} value={editorVal}
                  apiKey='esnq02ea10s4qrcjx6m5z9h03c3iln9jfml86ysvw8legkpo'
                  init={{
                    height: 400,
                    menubar: false,
                    plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
                    toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat |  fullscreen  | image media link | ltr rtl | code'
                  }}
                />
                {errors.description && !watch().description && <span className={`${styles.ErrorM}`}>{errors.description.message}</span>}
              </div>
            </div>
            <div className={`${styles.FormButtonArea}`}>
              <input className={`${styles.SubmitBU}`} value="Update" type="submit" />
            </div>
          </form>
        </div>
      </div>
    </div>}
  </React.Fragment>)
}

export default withAdminAuth(PrivacyPolicy);