import React from "react";
import styles from "./ferry-payment-success.module.css";

export default function FerryPaymentSuccess() {

  return (<React.Fragment>
    <div className={`${styles.BookingDone}`}>
      <img src="/images/check.png" alt="" />
      <p className={`${styles.BookingTitle}`}>Thank You!</p>
      <p className={`${styles.BookingSmTitle}`}>You successfully booked ferry slot.</p>
    </div>
  </React.Fragment>);
}
