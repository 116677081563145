import React from "react";
import styles from "./list.module.css";
import { Link } from "react-router-dom";
import { Typography, Breadcrumbs, TextField, Button } from "@mui/material";
import DataTable from "react-data-table-component";
import { X, ChevronDown, Eye, Trash2, Calendar } from "react-feather";
import axios from "axios";
import Dropdown from "react-bootstrap/Dropdown";
import { TableLoader } from "../../components/LoaderC";
import Loader from "../../components/Loader";
import { toast } from "react-smart-toaster";
import withAdminAuth from "../../components/withAdminAuth";
import { authData } from "../../components/getAuth";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";

export const ExportCSV = (props) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [selectdData, setSelectdData] = React.useState({ value: "all", label: "All" });
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());
  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ";";
    const lineDelimiter = "\n";

    const keys = ["submitted_date", "reference_id", "car", "booking_start_date", "booking_end_date", "amount", "amount_usd", "security_amount", "customer", "customer_email", "customer_phone", "type", "status"];

    result = "";
    keys.forEach((key) => {
      if (key === "submitted_date") {
        result += "Submitted Date";
      } else if (key === "reference_id") {
        result += "Reference Id";
      } else if (key === "car") {
        result += "Car";
      } else if (key === "booking_start_date") {
        result += "Booking Start Date";
      } else if (key === "booking_end_date") {
        result += "Booking End Date";
      } else if (key === "amount") {
        result += "Amount (WST)";
      } else if (key === "amount_usd") {
        result += "Amount (USD)";
      } else if (key === "security_amount") {
        result += "Security Amount (USD)";
      } else if (key === "customer") {
        result += "Customer Name";
      } else if (key === "customer_email") {
        result += "Customer Email";
      } else if (key === "customer_phone") {
        result += "Customer Phone";
      } else if (key === "type") {
        result += "Type";
      } else if (key === "status") {
        result += "Status";
      } else {
        result += key;
      }
      result += columnDelimiter;
    });
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;
        result += item[key];
        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  const Export = ({ onExport }) => (<Button onClick={(e) => onExport(e.target.value)} className="PopUpSubmitBU2">Export</Button>);

  function downloadCSV() {
    setModalShow(false);
    let newArray = props.data.map((item) => {
      let securityAmountUSD = 0;
      if (item.total_amount > 0) {
        securityAmountUSD = (item.security_amount * item.total_amount_usd) / item.total_amount;
        securityAmountUSD = securityAmountUSD.toFixed(2);
      }

      let itemObj = {
        reference_id: item?.reference_id,
        submitted_date: moment(item.created_at).format("L"),
        car: item?.carNames?.join(', '),
        booking_start_date: moment(item?.start_date).format("L") + " " + moment(item?.start_date + " " + item?.start_time).format("hh:mm A"), booking_end_date: moment(item?.end_date).format("L") + " " + moment(item?.end_date + " " + item?.end_time).format("hh:mm A"),
        amount: item.total_amount,
        amount_usd: item.total_amount_usd,
        customer: item?.user_info?.first_name + " " + item?.user_info?.last_name,
        customer_email: item?.user_info?.email,
        customer_phone: item?.user_info?.phone,
        type: item.type,
        status: item.status,
        security_amount: securityAmountUSD,
      };

      if (selectdData.value === "all") {
        return itemObj;
      }
      if (selectdData.value === "upcoming") {
        if (moment().diff(item?.start_date, "days") < 0) {
          return itemObj;
        }
      }
      if (selectdData.value === "month") {
        if (moment().startOf("month").format("YYYY-MM-DD") <= item?.start_date && moment().endOf("month").format("YYYY-MM-DD") >= item?.start_date) {
          return itemObj;
        }
      }

      if (selectdData.value === "custom") {
        if (moment(startDate).format("YYYY-MM-DD") <= item?.start_date && moment(endDate).format("YYYY-MM-DD") >= item?.start_date) {
          return itemObj;
        }
      }
      return null;
    });
    newArray = newArray.filter((el) => {
      return el != null;
    });

    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(newArray);
    if (csv == null) return;

    const filename = "booking-list.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }
    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  function openPopup() {
    setModalShow(true);
  }

  function modalClose() {
    setModalShow(false);
  }

  const actionsMemo = React.useMemo(() => <Export onExport={() => openPopup()} />, []);

  return (<>
    <DataTable
      className="DataTable"
      columns={props.columns}
      data={props.data}
      pagination
      actions={actionsMemo}
    />

    <Modal show={modalShow} onHide={modalClose} centered className="CusModal">
      <Modal.Header closeButton>
        <Modal.Title>Export Item</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ width: "50%" }}>
          <label>Filter Data</label>
          <Select
            defaultValue={selectdData}
            options={[
              { value: "all", label: "All" },
              { value: "upcoming", label: "Only Upcoming" },
              { value: "month", label: "Current Month" },
              { value: "custom", label: "Custom Date Range" },
            ]}
            onChange={(e) => setSelectdData(e)}
          />
        </div>
        {selectdData?.value === "custom" && (<div className={`${styles.BannerSearchDate}`}>
          <div className={`${styles.FixedFullSec}`}>
            <label className={`${styles.BannerFormLabel}`}>Start Date</label>
            <div className={`${styles.FormSec}`}>
              <Calendar />
              <DatePicker
                selected={startDate}
                className={`${styles.FormControl}`}
                onChange={(date) => {
                  setStartDate(date);
                  if (date > endDate) {
                    setEndDate(date);
                  }
                }}
              />
            </div>
          </div>
          <div className={`${styles.FixedFullSec}`}>
            <label className={`${styles.BannerFormLabel}`}>End Date</label>
            <div className={`${styles.FormSec}`}>
              <Calendar />
              <DatePicker
                selected={endDate}
                minDate={startDate}
                className={`${styles.FormControl}`}
                onChange={(date) => setEndDate(date)}
              />
            </div>
          </div>
        </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button className="PopUpCanBU" onClick={modalClose}>Cancel</Button>
        <Button className="PopUpSubmitBU2" onClick={downloadCSV.bind()}>Export</Button>
      </Modal.Footer>
    </Modal>
  </>
  );
};

class BookingList extends React.Component {
  constructor({ match, ...props }) {
    super(...arguments);
    this.state = { allData: [], data: [], filterText: "", modalShow: false, modalShow2: false, loading: false, contentLoading: true, currentItem: {} };

    this.audience = process.env.REACT_APP_API_URL;
    this.app_folder_path = process.env.REACT_APP_FOLDER_PATH;
    this.authData = authData();

    this.columns = [{
      name: "Submitted Date",
      selector: (row) => moment(row.created_at).format("L"),
      sortable: false,
    }, {
      name: "Reference Id",
      selector: (row) => row.reference_id,
      sortable: false,
    }, {
      name: "Car",
      cell: (row) => (<div>
        {row.carNames.map((c) => {
          return <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '160px' }}>{c}</div>
        })}
      </div>),
      sortable: false,
    }, {
      name: "Date",
      cell: (tableProps) => (<div>
        <span>{moment(tableProps?.start_date).format("L")} {moment(tableProps?.start_date + " " + tableProps?.start_time).format("hh:mm A")}</span>
        <br />
        <span>{tableProps?.days + " day(s)"}</span>
      </div>),
      sortable: false,
    }, {
      name: "Amount (USD)",
      selector: (row) => row.total_amount_usd.toFixed(2),
      sortable: true,
    }, {
      name: "Customer",
      cell: (tableProps) => (<div>
        <span>{tableProps?.user_info?.first_name + " " + tableProps?.user_info?.last_name}</span>
        <br />
        <span>{tableProps?.user_info?.email}</span>
      </div>),
      sortable: false,
    }, {
      name: "Type",
      selector: (row) => row.type,
      sortable: false,
    }, {
      name: "Status",
      cell: (tableProps) => (<Dropdown>
        <Dropdown.Toggle className={tableProps.status === "Pending" ? `${styles.ArchiveBU}` : tableProps.status === "Confirmed" ? `${styles.ActiveBU}` : `${styles.CompletedBU}`} id={"dropdown-basic" + tableProps.id} size="sm">{tableProps.status} <ChevronDown /></Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={this.statusChange.bind(this, tableProps.id, "Pending")}>Pending</Dropdown.Item>
          <Dropdown.Item onClick={this.statusChange.bind(this, tableProps.id, "Confirmed")}>Confirmed</Dropdown.Item>
          <Dropdown.Item onClick={this.statusChange.bind(this, tableProps.id, "Completed")}>Completed</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>),
      sortable: false,
    }, {
      name: "Action",
      cell: (tableProps) => (<div className={`${styles.ActionDiv}`}>
        <Button onClick={this.modalOpen.bind(this, tableProps)} style={{ marginRight: "10px" }}><Eye /></Button>
        <Button onClick={this.modalOpen2.bind(this, tableProps.id)}><Trash2 /></Button>
      </div>),
      sortable: false,
    }];
  }

  async componentDidMount() {
    try {
      axios(this.audience + "v1/bookings", {
        headers: {
          Authorization: `Bearer ${this.authData?.api_token}`,
        }
      }).then((response) => {
        let allData = response.data.data;

        allData = allData.map(i => {
          let carNames = i?.cars.map(c => {
            return c?.title;
          });

          return { ...i, carNames: carNames };
        })

        this.setState({ ...this.state, allData: allData, data: allData, contentLoading: false });
      }).catch((error) => {
        this.setState({ ...this.state, contentLoading: false });
      });
    } catch (error) {
      this.setState({ ...this.state, contentLoading: false });
    }
  }

  onFilter(e) {
    this.setState({ ...this.state, filterText: e.target.value }, this.filterHandler);
  }

  clearFilter() {
    this.setState({ ...this.state, filterText: "" }, this.filterHandler);
  }

  filterHandler() {
    let filteredData = this.state.allData;

    if (this.state.filterText !== "") {
      let inputVal = this.state.filterText;
      inputVal = inputVal.toLowerCase();
      filteredData = filteredData.filter((item) => {
        let carNamesStr = item?.carNames.join(' ');
        return (item.reference_id.toLowerCase().includes(inputVal) || (item?.user_info?.first_name + " " + item?.user_info?.last_name).toLowerCase().includes(inputVal) || item?.user_info?.email.toLowerCase().includes(inputVal) || carNamesStr.toLowerCase().includes(inputVal));
      });
    }

    this.setState({ ...this.state, data: filteredData });
  }

  statusChange(id, status) {
    this.setState({ ...this.state, loading: true });
    axios.put(this.audience + "v1/bookings/" + id, { status: status }, {
      headers: {
        Authorization: `Bearer ${this.authData?.api_token}`,
      }
    }).then((response) => {
      toast.success(response.data.message);
      let allData = this.state.allData;
      let data = this.state.data;
      let allDataTemp = allData.map((i) => {
        if (i.id === id) return { ...i, status: status };
        return i;
      });
      let dataTemp = data.map((i) => {
        if (i.id === id) return { ...i, status: status };
        return i;
      });

      this.setState({ ...this.state, allData: allDataTemp, data: dataTemp, loading: false });
    }).catch((error) => {
      this.setState({ ...this.state, loading: false });
    });
  }

  modalOpen(tableProps) {
    this.setState({ ...this.state, modalShow: true, currentItem: tableProps });
  }

  modalOpen2(tableProps) {
    this.setState({ ...this.state, modalShow2: true, currentItem: tableProps });
  }

  modalClose() {
    this.setState({ ...this.state, modalShow: false, modalShow2: false, currentItem: {} });
  }

  deleteConfirm() {
    let currentItem = this.state.currentItem;
    this.setState({ ...this.state, modalShow2: false, currentItem: 0, loading: true, });
    axios.delete(this.audience + "v1/bookings/" + currentItem, {
      headers: {
        Authorization: `Bearer ${this.authData?.api_token}`,
      }
    }).then((response) => {
      toast.success(response.data.message);
      let allData = this.state.allData;
      let data = this.state.data;
      let allDataTemp = allData.filter((i) => i.id !== currentItem);
      let dataTemp = data.filter((i) => i.id !== currentItem);
      this.setState({ ...this.state, allData: allDataTemp, data: dataTemp, loading: false });
    }).catch((error) => {
      this.setState({ ...this.state, loading: false });
    });
  }

  render() {
    return (<>
      {this.state.loading && <Loader />}

      {this.state.contentLoading && (<div className="LoaderCard"><div className="Loader TableLoader"><TableLoader /></div></div>)}

      {!this.state.contentLoading && (<div className={`${styles.MainDiv}`}>
        <div className={`${styles.PageTitleWrap}`}>
          <p className={`${styles.PageTitle}`}>Bookings List</p>
          <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
            <Link color="inherit" to="/admin/dashboard">Dashboard</Link>
            <Link color="inherit" to={"/admin/booking/list"}>Bookings</Link>
            <Typography color="text.primary">List</Typography>
          </Breadcrumbs>
        </div>

        <div className={`${styles.MainCard}`}>
          <div className="TableFilterHead">
            <div className="DataTableSearch">
              <TextField
                id="search"
                type="text"
                placeholder="Search"
                aria-label="Search Input"
                value={this.state.filterText}
                onChange={this.onFilter.bind(this)}
              />
              {this.state.filterText && (
                <Button
                  type="button"
                  className="CloseBU"
                  onClick={this.clearFilter.bind(this)}
                >
                  <X />
                </Button>
              )}
            </div>
          </div>
          <div className="TableContent">
            <ExportCSV columns={this.columns} data={this.state.data} />
          </div>
        </div>
      </div>)}

      <Modal
        show={this.state.modalShow}
        onHide={this.modalClose.bind(this)}
        centered
        className="CusModal"
        size="lg"
      >
        <Modal.Body>
          <p className={`${styles.RefID}`}>
            <span>Reference Id:</span> {this.state?.currentItem?.reference_id}
          </p>
          <div className={`${styles.PopBodyRow}`}>
            <div className={`${styles.PopFullSec}`}>
              <p className={`${styles.PopHalfSecTitle}`}>Customer Details</p>
              <p className={`${styles.PopHalfSecText}`}><span>Name:</span>{this.state?.currentItem?.user_info?.first_name} {this.state?.currentItem?.user_info?.last_name}</p>
              <p className={`${styles.PopHalfSecText}`}><span>Address:</span>{this.state?.currentItem?.user_info?.address}, {this.state?.currentItem?.user_info?.city}, {this.state?.currentItem?.user_info?.state} {this.state?.currentItem?.user_info?.zip}</p>
              <p className={`${styles.PopHalfSecText}`}><span>Contact no.:</span>{this.state?.currentItem?.user_info?.phone}</p>
              <p className={`${styles.PopHalfSecText}`}><span>Email:</span>{this.state?.currentItem?.user_info?.email}</p>
              <p className={`${styles.PopHalfSecText}`}><span>No of Passenger(s):</span>{this.state?.currentItem?.user_info?.no_of_people} People</p>
              <p className={`${styles.PopHalfSecText}`}><span>Driver's Age:</span>{this.state?.currentItem?.user_info?.age} yrs</p>
              <p className={`${styles.PopHalfSecText}`}><span>Arrival Airline Carrier:</span>{this.state?.currentItem?.user_info?.airline_carrier !== "" ? this.state?.currentItem?.user_info?.airline_carrier : "N/A"}</p>
              <p className={`${styles.PopHalfSecText}`}><span>Arrival Vessel Number:</span>{this.state?.currentItem?.user_info?.vessel_number !== "" ? this.state?.currentItem?.user_info?.vessel_number : "N/A"}</p>
              <p className={`${styles.PopHalfSecText}`}><span>Departure Airline Carrier:</span>{this.state?.currentItem?.user_info?.departure_airline_carrier !== "" ? this.state?.currentItem?.user_info?.departure_airline_carrier : "N/A"}</p>
              <p className={`${styles.PopHalfSecText}`}><span>Departure Vessel Number:</span>{this.state?.currentItem?.user_info?.departure_vessel_number !== "" ? this.state?.currentItem?.user_info?.departure_vessel_number : "N/A"}</p>
            </div>
          </div>
          {this.state?.currentItem?.ferry_info && this.state?.currentItem?.ferry_info.length !== 0 && (<div className={`${styles.PopBodyRow}`}>
            <div className={`${styles.PopFullSec}`}>
              <p className={`${styles.PopHalfSecTitle}`}>Ferry Details</p>
              <table>
                <tr>
                  <td>
                    <p className={`${styles.PopHalfSecTitle}`}>Description</p>
                  </td>
                  <td>
                    <p className={`${styles.PopHalfSecTitle}`}>Date</p>
                  </td>
                  <td>
                    <p className={`${styles.PopHalfSecTitle}`}>Time</p>
                  </td>
                </tr>
                {this.state?.currentItem?.ferry_info?.map((itemFerry, indexFerry) => {
                  return (<tr index={indexFerry}>
                    <td>
                      <p className={`${styles.PopHalfSecText}`}>{itemFerry?.ferry_name}</p>
                    </td>
                    <td>
                      <p className={`${styles.PopHalfSecText}`}>{moment(itemFerry?.ferry_booking_date).format("L")}</p>
                    </td>
                    <td>
                      <p className={`${styles.PopHalfSecText}`}>
                        <strong>{moment(itemFerry?.ferry_booking_slot, "HH:mm:ss").format("hh:mm A")}</strong>
                      </p>
                    </td>
                  </tr>);
                })}
              </table>
            </div>
          </div>)}

          <div className={`${styles.PopBodyRow}`}>
            <div className={`${styles.PopHalfSec}`}>
              <p className={`${styles.PopHalfSecTitle}`}>Pick up location</p>
              <p className={`${styles.PopHalfSecText}`}>{this.state?.currentItem?.location_name}</p>
              <p className={`${styles.PopHalfSecText}`}>{moment(this.state?.currentItem?.start_date).format("L")} {moment(this.state?.currentItem?.start_date + " " + this.state?.currentItem?.start_time).format("hh:mm A")}</p>
            </div>
            <div className={`${styles.PopHalfSec}`}>
              <p className={`${styles.PopHalfSecTitle}`}>Drop off location</p>
              <p className={`${styles.PopHalfSecText}`}>{this.state?.currentItem?.dropoff_location_name}</p>
              <p className={`${styles.PopHalfSecText}`}>{moment(this.state?.currentItem?.end_date).format("L")} {moment(this.state?.currentItem?.end_date + " " + this.state?.currentItem?.end_time).format("hh:mm A")}</p>
            </div>
          </div>
          <div className={`${styles.PopBodyRow}`}>
            <div className={`${styles.PopFullSec}`}>
              <table>
                <tr>
                  <td>
                    <p className={`${styles.PopHalfSecTitle}`}>Description</p>
                  </td>
                  <td>
                    <p className={`${styles.PopHalfSecTitle}`}>Qtn</p>
                  </td>
                  <td>
                    <p className={`${styles.PopHalfSecTitle}`}>Price</p>
                  </td>
                </tr>
                {this.state?.currentItem?.cars?.map((itemCar, indexCar) => {
                  return (<tr index={indexCar}>
                    <td>
                      <p className={`${styles.PopHalfSecText}`}>{itemCar?.title}</p>
                    </td>
                    <td>
                      <p className={`${styles.PopHalfSecText}`}>{itemCar?.quantity}</p>
                    </td>
                    <td>
                      <p className={`${styles.PopHalfSecText}`}>
                        <strong>WST {itemCar?.sub_amount.toFixed(2)}</strong>
                      </p>
                    </td>
                  </tr>);
                })}
                {this.state?.currentItem?.addons?.map((item, index) => {
                  return (<tr key={index}>
                    <td>
                      <p className={`${styles.PopHalfSecText}`}>{item?.title}</p>
                    </td>
                    <td>
                      <p className={`${styles.PopHalfSecText}`}>{item?.quantity}</p>
                    </td>
                    <td>
                      <p className={`${styles.PopHalfSecText}`}><strong>WST {item?.sub_amount.toFixed(2)}</strong></p>
                    </td>
                  </tr>);
                })}
                <tr>
                  <td colSpan="2">
                    <p className={`${styles.PopHalfSecTitle} ${styles.BorderTop} ${styles.PopHalfSecTitleNew} ${styles.PopHalfSecTitleNew3}`}>Sub Total</p>
                  </td>
                  <td>
                    <p className={`${styles.PopHalfSecTitle} ${styles.BorderTop}  ${styles.PopHalfSecTitleNew2} ${styles.PopHalfSecTitleNew3}`}>WST {this.state?.currentItem?.amount?.toFixed(2)}</p>
                  </td>
                </tr>
                {this.state?.currentItem?.discount_amount > 0 && (<tr>
                  <td colSpan="2">
                    <p className={`${styles.PopHalfSecTitle} ${styles.BorderTop} ${styles.PopHalfSecTitleNew}`}>Discount</p>
                  </td>
                  <td>
                    <p className={`${styles.PopHalfSecTitle} ${styles.BorderTop} ${styles.PopHalfSecTitleNew2}`}>WST - {this.state?.currentItem?.discount_amount?.toFixed(2)}</p>
                  </td>
                </tr>)}
                {this.state?.currentItem?.promo_discount_amount > 0 && (<tr>
                  <td colSpan="2">
                    <p className={`${styles.PopHalfSecTitle} ${styles.BorderTop} ${styles.PopHalfSecTitleNew}`}>Promotional Code ({this.state?.currentItem?.promotional_code})</p>
                  </td>
                  <td>
                    <p className={`${styles.PopHalfSecTitle} ${styles.BorderTop} ${styles.PopHalfSecTitleNew2}`}>WST {this.state?.currentItem?.promo_discount_amount?.toFixed(2)}</p>
                  </td>
                </tr>)}
                {this.state?.currentItem?.dropoff_amount > 0 && (<tr>
                  <td colSpan="2">
                    <p className={`${styles.PopHalfSecTitle} ${styles.BorderTop} ${styles.PopHalfSecTitleNew}`}>Delivery Fee</p>
                  </td>
                  <td>
                    <p className={`${styles.PopHalfSecTitle} ${styles.BorderTop} ${styles.PopHalfSecTitleNew2}`}>WST {this.state?.currentItem?.dropoff_amount?.toFixed(2)}</p>
                  </td>
                </tr>)}
                <tr>
                  <td colSpan="2">
                    <p className={`${styles.PopHalfSecTitle} ${styles.BorderTop} ${styles.PopHalfSecTitleNew}`}>Security Amount</p>
                  </td>
                  <td>
                    <p className={`${styles.PopHalfSecTitle} ${styles.BorderTop} ${styles.PopHalfSecTitleNew2}`}>WST {this.state?.currentItem?.security_amount?.toFixed(2)}</p>
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <p className={`${styles.PopHalfSecTitle} ${styles.BorderTop} ${styles.PopHalfSecTitleNew}`}>Payment Fee (3%)</p>
                  </td>
                  <td>
                    <p className={`${styles.PopHalfSecTitle} ${styles.BorderTop} ${styles.PopHalfSecTitleNew2}`}>WST {this.state?.currentItem?.payment_fee?.toFixed(2)}</p>
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <p className={`${styles.PopHalfSecTitle} ${styles.BorderTop} ${styles.PopHalfSecTitleNew5}`}>Grand Total</p>
                  </td>
                  <td>
                    <p className={`${styles.PopHalfSecTitle} ${styles.BorderTop} ${styles.PopHalfSecTitleNew6}`}>WST {this.state?.currentItem?.total_amount?.toFixed(2)}</p>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <Button className={`${styles.PopUpCanBU}`} onClick={this.modalClose.bind(this)}><X /></Button>
        </Modal.Body>
      </Modal>

      <Modal
        show={this.state.modalShow2}
        onHide={this.modalClose.bind(this)}
        centered
        className="CusModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="Message">Are you sure you want to delete this item?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button className="PopUpCanBU" onClick={this.modalClose.bind(this)}>Cancel</Button>
          <Button className="PopUpSubmitBU" onClick={this.deleteConfirm.bind(this)}>Confirm Delete</Button>
        </Modal.Footer>
      </Modal>
    </>);
  }
}

export default withAdminAuth(BookingList);