import React, { useState } from 'react';
import { CheckCircle, ChevronDown, Clock, Mail } from 'react-feather';
import { Link } from 'react-router-dom';
import styles from './footer.module.css';
import moment from 'moment';

export default function Footer() {
  const [showQLDropdownMenu, setshowQLDropdownMenu] = useState(false);
  const [showCUDropdownMenu, setshowCUDropdownMenu] = useState(false);
  const [showFHDropdownMenu, setshowFHDropdownMenu] = useState(false);

  return (<footer className={`${styles.FooterMain}`}>
    <img src="/images/moto-iconW.svg" alt="" className={`${styles.GraImg}`} />
    <div className='container'>
      <div className={`${styles.FootRow}`}>
        <div className={`${styles.FootLogo}`}>
          <img src="/images/moto-logoW.svg" alt="" />
        </div>
        <div onClick={() => setshowQLDropdownMenu(v => !v)} className={`${styles.FootLinkArea} ${showQLDropdownMenu ? styles.Open : ''}`}>
          <p className={`${styles.FootLinkTitle}`}>QUICK LINKS</p>
          <span className={`${styles.FootDRIcon}`}><ChevronDown /></span>
          <Link className={`${styles.FootLink}`} to={'/about-us'}><CheckCircle /> ABOUT MOTU CAR RENTALS</Link>
          <Link className={`${styles.FootLink}`} to={'/terms'}><CheckCircle /> RENTAL AGREEMENT</Link>
          <Link className={`${styles.FootLink}`} to={'/privacy'}><CheckCircle /> PRIVACY POLICY</Link>
          <Link className={`${styles.FootLink}`} to={'/faq'}><CheckCircle /> FREQUENTLY ASKED QUESTIONS</Link>
          <Link className={`${styles.FootLink}`} to={'/cancellation-policy'}><CheckCircle /> CANCELLATION POLICY</Link>
          <Link className={`${styles.FootLink}`} to={'/contact-us'}><CheckCircle /> CONTACT US</Link>
        </div>

        <div onClick={() => setshowCUDropdownMenu(v => !v)} className={`${styles.FootLinkArea} ${showCUDropdownMenu ? styles.Open : ''}`}>
          <p className={`${styles.FootLinkTitle}`}>Contact Us</p>
          <span className={`${styles.FootDRIcon}`}><ChevronDown /></span>
          <a className={`${styles.FootLink}`} href="mailto:motucarrentals@gmail.com"><Mail /> motucarrentals@gmail.com</a>
          <p className={`${styles.FootLink} ${styles.TopAlign}`} to={'/'}>
            <CheckCircle />
            <div className={`${styles.FootTwoSec}`}>
              <a className={`${styles.FootLink2}`} href="tel:+1 808 6004536">+1-(808) 600 - 4536 (US)</a>
              <a className={`${styles.FootLink2}`} href="tel:+685 7707537">+685 7707537 (SAM)</a>
            </div>
          </p>
          <Link className={`${styles.FootLink}`} to={'/'}><img src="/images/paypal.svg" alt="" />Payment Method</Link>

        </div>

        <div onClick={() => setshowFHDropdownMenu(v => !v)} className={`${styles.FootHours} ${showFHDropdownMenu ? styles.Open : ''}`}>
          <p className={`${styles.FootLinkTitle}`}>Office Hours</p>
          <span className={`${styles.FootDRIcon}`}><ChevronDown /></span>
          <p className={`${styles.FootLink} ${styles.TopAlign}`} to={'/'}>
            <Clock />
            <div className={`${styles.FootTwoSec}`}>
              <p className={`${styles.FootLinkNormal}`}>
                Mon - Fri <br />
                Open 8:30 am - 5:30 pm <br /><br />
                Sat<br />
                Open 8:30 am - 3:30 pm <br /><br />
              </p>
              <p className={`${styles.FootLinkNormal}`}>Closed Sundays</p>
            </div>
          </p>
        </div>

        <div className={`${styles.FootSocialSec}`}>
          <ul>
            <li>
              <p className={`${styles.SocialLinkTitle}`}>Follow on:</p>
            </li>
            <li>
              <a href={'https://www.facebook.com/profile.php?id=100090011311023'} target="_blank" rel="noreferrer" className={`${styles.SocialIconName}`}>
                <img src="/images/facebook.svg" alt="" />
                <p>Facebook</p>
              </a>
            </li>
            <li>
              <a href='http://wasap.my/+6857707537' target="_blank" rel="noreferrer" className={`${styles.SocialIconName}`}>
                <img src="/images/whatsapp.svg" alt="" />
                <p>WhatsApp</p>
              </a>
            </li>
          </ul>
        </div>

      </div>
      <p className={`${styles.CopyRightText}`}>Motu Car Rentals, All Rights Reserved, {moment().format('YYYY')}</p>
    </div>
  </footer>)
}