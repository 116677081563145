import React from 'react';
import styles from './cancel.module.css';
import axios from 'axios';
import { authData } from "../../../admin/components/getAuth";
import { Parallax } from 'react-parallax';
//import {authData} from "../../components/getAuth";
class Cancel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      arr: []
    };
    this.props = props;
    this.authData = authData();
  }
  componentWillMount() {
    axios(process.env.REACT_APP_API_URL + 'v1/page-manager/cancellation-policy', {
      headers: {
        Authorization: `Bearer ${this.authData?.api_token}`,
      }
    }).then(response => {
      let allData = response.data.data;
      this.setState({ ...this.state, arr: allData });
    }).catch(error => {
      this.setState({ ...this.state, arr: [] });
    });

  }

  render() {
    return (<React.Fragment>
      <div className="InnerBanner">
        <Parallax bgImage="/images/faq-banner.jpg" strength={400}>
          <div style={{ height: 450 }} className={`${styles.BannerConArea}`}>
            <div className='container'>
              <div className={`${styles.InnerBannerRow}`}>
                <h2 className={`${styles.InnerBannerTitle}`}>Cancellation Policy</h2>
              </div>
            </div>
          </div>
        </Parallax>
      </div>
      <div className={`${styles.FAQSec}`}>
        <div className="container">
          <div className={`${styles.FAQRow} FAQArea`}>
            <div dangerouslySetInnerHTML={{ __html: this.state.arr?.description }}></div>
          </div>
        </div>
      </div>
    </React.Fragment>)
  }
}

export default Cancel;