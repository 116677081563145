import React from "react";
import Footer from "../frontend/components/footer";
import Header from "../frontend/components/header";

const FrontLayout = ({ children }) => {
  return (<React.Fragment>
    <Header />
    {children}
    <Footer />
  </React.Fragment>);
};

export default FrontLayout;