import { useEffect, useState } from "react";
import styles from "../reservation.module.css";
import LeftPanelBlock from "./LeftPanelBlock";
import CarBlock from "./CarBlock";

const Step1 = ({ handleSelect, bookingData, carList, pickupLocationList, dropoffLocationList, addOnList, selectCar, updateCar, removeCar, currency, currencyRate, currencyLoading, bookingCars, updateReservationInfo }) => {
  const [selectedItem, setSelectedItem] = useState([]);
  const [tempCars, setTempCars] = useState([]);
  const [carWithQuantity, setCarWithQuantity] = useState([]);

  useEffect(() => {
    if (bookingData?.cars && bookingData?.cars?.length) {
      let carListTemp = carList.map((item) => {
        let isCar = bookingData?.cars.find((i) => i.car_id === item.id);
        if (isCar) {
          return item;
        }
        return null;
      });
      carListTemp = carListTemp.filter((el) => {
        return el != null;
      });
      setSelectedItem(carListTemp);
    } else {
      setSelectedItem([]);
    }
  }, [bookingData, carList, pickupLocationList, dropoffLocationList]);

  useEffect(() => {
    let carWithQuantityTemp = carList?.map((item) => {
      if (item?.quantity > 0) {
        let quantityTemp = item?.quantity;
        if (bookingCars?.length > 0) {
          let bookingCarstemp = bookingCars?.filter((i) => i?.id === item?.id);
          if (bookingCarstemp?.length > 0) {
            quantityTemp = quantityTemp - bookingCarstemp[0]?.total;
          }
        }
        return { id: item.id, quantity: quantityTemp };
      }
      return null;
    });

    carWithQuantityTemp = carWithQuantityTemp?.filter((i) => i !== null);

    setCarWithQuantity(carWithQuantityTemp);
  }, [bookingCars, carList]);

  useEffect(() => {
    if (bookingData?.cars && bookingData?.cars?.length) {
      let carListTemp = carList.map((item) => {
        let isCar = bookingData?.cars.find((i) => i.car_id === item.id);
        if (isCar) {
          return { ...item, selected: true, quantity: isCar.quantity };
        }
        return { ...item, selected: false, quantity: 0 };
      });
      setTempCars(carListTemp);
    } else {
      let carListTemp = carList.map((item) => {
        return { ...item, selected: false };
      });
      setTempCars(carListTemp);
    }
  }, [bookingData, carList]);

  return (<div className={`${styles.SelectCarSec}`}>
    <LeftPanelBlock
      handleSelect={handleSelect}
      bookingData={bookingData}
      carList={carList}
      pickupLocationList={pickupLocationList}
      dropoffLocationList={dropoffLocationList}
      addOnList={addOnList}
      currency={currency}
      currencyRate={currencyRate}
      currencyLoading={currencyLoading}
      currentStep={1}
      updateReservationInfo={updateReservationInfo}
    />

    <div className={`${styles.ReservationBigSide}`}>
      <div className={`${styles.BigSideHead}`}>
        <div className={`${styles.BigHeadTextSec}`}>
          <p className={`${styles.BigHeadTitleText}`}>Choose a Vehicle</p>
          <p className={`${styles.BigHeadSmallText}`}>{carList?.length} Results</p>
        </div>
      </div>
      <div className={`${styles.ReservationBigListSec}`}>
        {tempCars?.map((item, index) => {
          let isSelected = selectedItem.includes(item);
          let isSoldOut = false;
          let addQuantity = 0;
          let soldOutTemp = carWithQuantity?.filter((i) => i?.id === item?.id);
          if (soldOutTemp?.length) {
            addQuantity = soldOutTemp[0]?.quantity;
            if (soldOutTemp[0]?.quantity <= 0) {
              isSoldOut = true;
            }
          }
          return (
            <CarBlock
              key={index}
              item={item}
              isSelected={isSelected}
              selectCar={selectCar}
              updateCar={updateCar}
              removeCar={removeCar}
              currency={currency}
              currencyRate={currencyRate}
              currencyLoading={currencyLoading}
              isDisabled={bookingCars.indexOf(item.id) > -1}
              addQuantity={addQuantity}
              isSoldOut={isSoldOut}
            />
          );
        })}
      </div>
    </div>
  </div>);
};

export default Step1;
