import React from 'react';
import styles from './blog.module.css';
import { Parallax } from 'react-parallax';
import Fade from 'react-reveal/Fade';
import { ArrowRight } from 'react-feather';
import withNavigateHook from '../../../customer/components/withNavigateHook';
import axios from 'axios';

class Blog extends React.Component {
  constructor(props) {
    super(props);
    this.state = { blogList: [] };
    this.props = props;
  }

  componentWillMount() {
    axios(process.env.REACT_APP_API_URL + 'v1/blog-list').then(response => {
      let allData = response.data.data;
      this.setState({ ...this.state, blogList: allData });
    }).catch(error => {
      this.setState({ ...this.state, blogList: [] });
    });
  }

  render() {
    return (<React.Fragment>
      <div className="InnerBanner">
        <Parallax bgImage="/images/blog-banner.jpg" strength={400}>
          <div style={{ height: 450 }} className={`${styles.BannerConArea}`}>
            <div className='container'>
              <div className={`${styles.InnerBannerRow}`}>
                <h2 className={`${styles.InnerBannerTitle}`}>Talofa lava.<br /> Welcome to Quinney’s Blog!</h2>
              </div>
            </div>
          </div>
        </Parallax>
      </div>
      <div className={`${styles.ServiceSec}`}>
        <img src="/images/CarBG.png" alt="" className={`${styles.CarBG}`} />
        <Fade bottom>
          <div className='container'>
            <div className={`${styles.ProductHead}`}>
              <h3 class={`${styles.ServiceTitle}`}>Our Blogs</h3>
              <p class={`${styles.ProductSmallTitle}`}>I appreciate you visiting. I'm an experienced blogger and I love writing. My writings reflect my Samoan culture, areas of expertise and interests.I write to inform, amuse, inspire, and educate.</p>
            </div>
            <div className={`${styles.ServicesRow}`}>
              {this.state?.blogList?.map((item, index) => {
                return (<div className={`${styles.ServicesCard}`}>
                  <img src={item.image_url} alt="" />
                  <div className={`${styles.BlogBody}`}>
                    <p className={`${styles.ServiceCardTitle}`}>{item.title}</p>
                    <p className={`${styles.ServiceText}`}>{item.description}</p>
                    <a href={item.link} rel="noreferrer" target="_blank" className={`${styles.BlogDeBU}`}>Learn More <ArrowRight /></a>
                  </div>
                </div>)
              })}
            </div>
          </div>
        </Fade>
      </div>

    </React.Fragment>)
  }
}

export default withNavigateHook(Blog);