import React from "react";
import styles from "./carservices.module.css";
import { Parallax } from "react-parallax";
import Fade from "react-reveal/Fade";
import Carousel from "react-multi-carousel";
import axios from "axios";
import withNavigateHook from "../../../customer/components/withNavigateHook";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet2: {
    breakpoint: { max: 767, min: 300 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

class CarServices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      carList: [],
      contentList: [],
      currency: "",
      currencyRate: 1,
      currencyLoading: false,
    };
    this.props = props;
    this.handleDocumentClick = this.handleDocumentClick.bind(this);
  }

  handleDocumentClick(event) {
    if (event.target.getAttribute("cngCurrency")) {
      let currentCurrency = localStorage.getItem("currentCurrency");
      if (currentCurrency) {
        this.setState({ ...this.state, currency: currentCurrency });
        this.getCurrencyRate(currentCurrency);
      } else {
        this.setState({ ...this.state, currency: "USD" });
        this.getCurrencyRate("USD");
      }
    }
  }

  componentWillMount() {
    let carList = [];
    let contentList = [];
    window.addEventListener("click", this.handleDocumentClick);
    axios(process.env.REACT_APP_API_URL + "v1/car-list").then((response) => {
      let allData = response.data.data;
      carList = allData;
      this.setPagealue(carList, contentList);
    }).catch((error) => {
      this.setPagealue(carList, contentList);
    });

    axios(process.env.REACT_APP_API_URL + "v1/page-manager/car").then((response) => {
      let itemData = response.data.data;
      itemData = JSON.parse(itemData.description);
      contentList = itemData;
      this.setPagealue(carList, contentList);
    }).catch((error) => {
      this.setPagealue(carList, contentList);
    });

    let currentCurrency = localStorage.getItem("currentCurrency");
    if (currentCurrency) {
      setTimeout(() => {
        this.setState({ ...this.state, currency: currentCurrency });
        this.getCurrencyRate(currentCurrency);
      }, 500);
    } else {
      setTimeout(() => {
        this.setState({ ...this.state, currency: "USD" });
        this.getCurrencyRate("USD");
      }, 500);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.handleDocumentClick);
  }

  setPagealue(carList, contentList) {
    this.setState({ ...this.state, carList: carList, contentList: contentList });
  }

  getCurrencyRate(selectcurrency) {
    this.setState({ ...this.state, currency: selectcurrency, currencyLoading: true, });
    axios(process.env.REACT_APP_API_URL + "v1/currency-convertter/WST/" + selectcurrency).then((response) => {
      this.setState({ ...this.state, currencyRate: response.data.data, currencyLoading: false });
    }).catch((error) => {
      this.setState({ ...this.state, currencyLoading: false });
    });
  }

  goToBookNow() {
    this.props.navigation("/reservation");
  }

  render() {
    return (<React.Fragment>
      <div className="InnerBanner">
        <Parallax bgImage="/images/car-service-banner.jpg" strength={400}>
          <div style={{ height: 450 }} className={`${styles.BannerConArea}`}>
            <div className="container">
              <div className={`${styles.InnerBannerRow}`}>
                <h2 className={`${styles.InnerBannerTitle}`}>Cars & Services</h2>
              </div>
            </div>
          </div>
        </Parallax>
      </div>

      <div className={`${styles.ProductSec}`}>
        <div className="container">
          <Fade bottom>
            <div className={`${styles.BodyMRow}`}>
              <h3 class={`${styles.ProductTitle}`}>{this.state.contentList.title}</h3>
              <div class={`${styles.InsurSmallTitle}`} dangerouslySetInnerHTML={{ __html: this.state.contentList.description, }}></div>
              <div className={`${styles.ProductSlider} ProductSlider`}>
                <Carousel
                  swipeable={true}
                  arrows={true}
                  draggable={true}
                  showDots={false}
                  responsive={responsive}
                  ssr={true}
                  infinite={true}
                  autoPlay={false}
                  autoPlaySpeed={4000}
                  keyBoardControl={true}
                  customTransition="2000ms all"
                  transitionDuration={500}
                  containerClass="carousel-container"
                  deviceType={this.props.deviceType}
                  dotListClass="custom-dot-list-style"
                  itemClass="carousel-item-padding-40-px"
                >
                  {this.state?.carList?.map((item, index) => {
                    return (<div className={`${styles.Item}`} key={index}>
                      <div className={`${styles.ItemUnder}`}>
                        <img src={"/images/CarBG.png"} alt="" className={`${styles.CardTexture}`} />
                        <img src={item.image_url} className={`${styles.CardIMG}`} alt="" />
                        <p className={`${styles.CarItemTitle}`}>{item.title}</p>
                        <p className={`${styles.CarItemText}`}>{item.type}</p>
                        <p className={`${styles.CarItemText2}`}>{item.transmission}</p>
                        <p className={`${styles.CarItemPrice}`}>
                          {this.state?.currency}{" "}
                          <span>{!this.state.currencyLoading && (item.price * this.state.currencyRate).toFixed(2) + "/Day"}</span>
                        </p>
                        <button className={`${styles.TrendingBUGreen}`} onClick={this.goToBookNow.bind(this)}>
                          <span>Book Now</span>
                        </button>
                      </div>
                    </div>);
                  })}
                </Carousel>
              </div>
            </div>
          </Fade>
        </div>
      </div>

      <div className={`${styles.ServiceSec}`}>
        <img src="/images/CarBG.png" alt="" className={`${styles.CarBG}`} />
        <Fade bottom>
          <div className="container">
            <div className={`${styles.ProductHead}`}>
              <h3 class={`${styles.ServiceTitle}`}>{this.state.contentList.service_title}</h3>
              <div class={`${styles.InsurSmallTitle}`} dangerouslySetInnerHTML={{ __html: this.state.contentList.service_description }}></div>
            </div>
            <div className={`${styles.ServicesRow}`}>
              <div className={`${styles.ServicesCard}`}>
                <img src="/images/baby-car-seat.png" alt="" />
                <p className={`${styles.ServiceCardTitle}`}>{this.state.contentList.service_1_title}</p>
                <p className={`${styles.ServiceText}`}>{this.state.contentList.service_1_description}</p>
              </div>
              <div className={`${styles.ServicesCard}`}>
                <img src="/images/flights.png" alt="" />
                <p className={`${styles.ServiceCardTitle}`}>{this.state.contentList.service_3_title}</p>
                <p className={`${styles.ServiceText}`}>{this.state.contentList.service_3_description}</p>
              </div>
              <div className={`${styles.ServicesCard}`}>
                <img src="/images/power-bank.png" alt="" />
                <p className={`${styles.ServiceCardTitle}`}>{this.state.contentList.service_5_title}</p>
                <p className={`${styles.ServiceText}`}>{this.state.contentList.service_5_description}</p>
              </div>
              <div className={`${styles.ServicesCard}`}>
                <img src="/images/sunbed.png" alt="" />
                <p className={`${styles.ServiceCardTitle}`}>{this.state.contentList.service_6_title}</p>
                <p className={`${styles.ServiceText}`}>{this.state.contentList.service_6_description}</p>
              </div>
            </div>
          </div>
        </Fade>
      </div>

      <div className={`${styles.InsuranceSec}`}>
        <img src="/images/waveInsur.svg" alt="" className={`${styles.waveInsur}`} />
        <Fade bottom>
          <div className="container">
            <div className={`${styles.ProductHead}`}>
              <h3 class={`${styles.InsurTitle}`}>{this.state.contentList.insurance_title}</h3>
              <p class={`${styles.InsurSmallTitle}`} dangerouslySetInnerHTML={{ __html: this.state.contentList.insurance_description }}></p>
            </div>
          </div>
        </Fade>
      </div>
    </React.Fragment>);
  }
}

export default withNavigateHook(CarServices);