import { useEffect, useState } from "react";
import styles from "./home.module.css";
import { Parallax } from "react-parallax";
import Fade from "react-reveal/Fade";
import { Calendar, Check, Clock, MapPin, Star, X } from "react-feather";
import "react-multi-carousel/lib/styles.css";
import Select from "react-select";
import DatePicker from "react-datepicker";
import axios from "axios";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import withNavigateHook from "../../../customer/components/withNavigateHook";
import { useNavigate } from "react-router-dom";
import Carousel from "react-multi-carousel";
import { Rating } from "@mui/material";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet2: {
    breakpoint: { max: 767, min: 300 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const style = {
  control: (base) => ({ ...base, border: 0, boxShadow: "none" })
}

const Home = (props) => {
  const navigate = useNavigate();
  const [isBoxFixed, setIsBoxFixed] = useState(false);
  const [location, setLocation] = useState(null);
  const [dropOffLocation, setDropOffLocation] = useState(null);
  const [locationError, setLocationError] = useState(false);
  const [dropOffLocationError, setDropOffLocationError] = useState(false);
  const [startDate, setStartDate] = useState(moment().toDate());
  const [startTime, setStartTime] = useState(moment().set("hour", 9).set("minute", 0).set("second", 0).set("millisecond", 0).toDate());
  const [endDate, setEndDate] = useState(moment().toDate());
  const [endTime, setEndTime] = useState(moment().set("hour", 9).set("minute", 0).set("second", 0).set("millisecond", 0).toDate());
  const [carList, setCarList] = useState([]);
  const [currency, setCurrency] = useState("");
  const [currencyRate, setCurrencyRate] = useState(1);
  const [currencyLoading, setCurrencyLoading] = useState(false);
  const [isDiscount, setIsDiscount] = useState(0);
  const [isOfferShow, setIsOfferShow] = useState(true);
  const [isShowAllCoupons, setIsShowAllCoupons] = useState(false);
  const [showedCoupon, setShowedCoupon] = useState({});
  const [coupons, setCoupons] = useState([]);
  const [testimonials, setTestimonials] = useState([]);
  const [pickupLocationList, setPickupLocationList] = useState([]);
  const [dropoffLocationList, setDropoffLocationList] = useState([]);

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (event.target.getAttribute("cngCurrency")) {
        let currentCurrency = localStorage.getItem("currentCurrency");
        if (currentCurrency) {
          setCurrency(currentCurrency);
          getCurrencyRate(currentCurrency);
        } else {
          setCurrency("USD");
          getCurrencyRate("USD");
        }
      }
    }

    window.removeEventListener('click', handleDocumentClick);
    window.addEventListener('click', handleDocumentClick, { passive: true });
    return () => window.removeEventListener('click', handleDocumentClick);
  }, []);

  useEffect(() => {
    const setBoxFixed = () => {
      window.scrollY > 500 ? setIsBoxFixed(true) : setIsBoxFixed(false);
    }

    window.removeEventListener('scroll', setBoxFixed);
    window.addEventListener('scroll', setBoxFixed, { passive: true });
    return () => window.removeEventListener('scroll', setBoxFixed);
  }, []);

  useEffect(() => {
    let currentCurrency = localStorage.getItem("currentCurrency");
    if (currentCurrency) {
      setCurrency(currentCurrency);
      getCurrencyRate(currentCurrency);
    } else {
      setCurrency("USD");
      getCurrencyRate("USD");
    }
  }, []);

  useEffect(() => {
    let bookingsData = localStorage.getItem("bookingData");
    if (bookingsData) {
      let bookingDataArr = JSON.parse(bookingsData);

      let pickUpTime = moment().format("YYYY-MM-DD") + " " + bookingDataArr.pickUpTime;
      let dropOffTime = moment().format("YYYY-MM-DD") + " " + bookingDataArr.dropOffTime;

      setLocation({
        value: bookingDataArr?.location,
        label: bookingDataArr?.location_name,
        delivery_fee: bookingDataArr?.dropOffLocation_delivery_fee,
        is_airport: bookingDataArr?.dropOffLocation_is_airport,
      });

      setDropOffLocation({
        value: bookingDataArr?.dropOffLocation,
        label: bookingDataArr?.dropOffLocation_name
      });
      setStartDate(moment(bookingDataArr.pickUpDate).toDate());
      setStartTime(moment(pickUpTime).toDate());
      setEndDate(moment(bookingDataArr.dropOffDate).toDate());
      setEndTime(moment(dropOffTime).toDate());
      setIsDiscount(bookingDataArr.isDiscount);

      let todayDate = moment();
      if (!todayDate.isBefore(moment(bookingDataArr.pickUpDate))) {
        setStartDate(moment().toDate());
      }
    }
  }, []);

  useEffect(() => {
    axios(process.env.REACT_APP_API_URL + "v1/location-list").then((response) => {
      let itemPickup = response.data.data.filter((i) => i.type === "Pick-Up Only" || i.type === "Both").map((i) => {
        return { value: i.id, label: i.name, delivery_fee: i.delivery_fee, is_airport: i?.is_airport };
      });
      let itemDropoff = response.data.data.filter((i) => i.type === "Drop-Off Only" || i.type === "Both").map((i) => {
        return { value: i.id, label: i.name };
      });

      setPickupLocationList(itemPickup);
      setDropoffLocationList(itemDropoff);
    }).catch((error) => {
      setPickupLocationList([]);
      setDropoffLocationList([]);
    });
  }, []);

  useEffect(() => {
    axios(process.env.REACT_APP_API_URL + "v1/car-list").then((response) => {
      setCarList(response.data.data);
    }).catch((error) => {
      setCarList([]);
    });
  }, []);

  useEffect(() => {
    axios(process.env.REACT_APP_API_URL + "v1/testimonial-list").then((response) => {
      setTestimonials(response.data.data);
    }).catch((error) => {
      setTestimonials([]);
    });
  }, []);

  useEffect(() => {
    axios(process.env.REACT_APP_API_URL + "v1/coupon-list").then((response) => {
      let couponData = response.data.data;
      let coupon1 = couponData[0];
      couponData = couponData.slice(1);

      setCoupons(couponData);
      setShowedCoupon(coupon1);
    }).catch((error) => {
      setCoupons([]);
    });
  }, []);

  const changeAirport = (obj) => {
    setLocation(obj);
    setLocationError(false);
  };

  const changeDropOffLocation = (obj) => {
    setDropOffLocation(obj);
    setDropOffLocationError(false);
  };

  const getCurrencyRate = (selectcurrency) => {
    setCurrency(selectcurrency);
    setCurrencyLoading(true);
    axios(process.env.REACT_APP_API_URL + "v1/currency-convertter/WST/" + selectcurrency).then((response) => {
      setCurrencyLoading(false);
      setCurrencyRate(response.data.data);
      setCurrencyRate(response.data.data);
    }).catch((error) => {
      setCurrencyLoading(false);
    });
  }

  const changeDate = (event, date, type) => {
    if (type === "startDate") {
      let dayDiff2 = moment(date).diff(moment(), "days");
      let endDate = new Date(new Date(date).setDate(new Date(date).getDate() + 1));
      if (dayDiff2 > 9) {
        setStartDate(date);
        setEndDate(endDate);
        setIsDiscount(1);
      } else {
        setStartDate(date);
        setEndDate(endDate);
        setIsDiscount(0);
      }
    }
    if (type === "endDate") {
      setEndDate(date);
    }
    if (type === "startTime") {
      setStartTime(date);
    }
    if (type === "endTime") {
      setEndTime(date);
    }
  };

  const addBooking = () => {
    if (location && dropOffLocation) {
      let startDate1 = moment(startDate).format("YYYY-MM-DD") + " " + moment(startTime).format("HH:mm:ss");
      let endDate1 = moment(endDate).format("YYYY-MM-DD") + " " + moment(endTime).format("HH:mm:ss");
      let dayDiff = moment(endDate1, "YYYY-MM-DD HH:mm:ss").diff(moment(startDate1, "YYYY-MM-DD HH:mm:ss"), "minutes");
      let dayDiff2 = moment(startDate).diff(moment(), "days");
      dayDiff = dayDiff / (24 * 60);
      if (dayDiff > parseInt(dayDiff)) {
        dayDiff = parseInt(dayDiff) + 1;
      }
      if (dayDiff < 1) {
        dayDiff = 1;
      }
      if (dayDiff2 >= 9) {
        setLocationError(false);
        setDropOffLocationError(false);
        setIsDiscount(1);
      } else {
        setLocationError(false);
        setDropOffLocationError(false);
        setIsDiscount(0);
      }

      let bookingData = {
        location: location?.value,
        location_name: location?.label,
        dropOffLocation: dropOffLocation?.value,
        dropOffLocation_name: dropOffLocation?.label,
        dropOffLocation_delivery_fee: location?.delivery_fee,
        dropOffLocation_is_airport: location?.is_airport,
        pickUpDate: moment(startDate).format("YYYY-MM-DD"),
        dropOffDate: moment(endDate).format("YYYY-MM-DD"),
        pickUpTime: moment(startTime).format("HH:mm:ss"),
        dropOffTime: moment(endTime).format("HH:mm:ss"),
        days: dayDiff,
        isDiscount: dayDiff2 >= 9 ? 1 : 0,
        discountAmount: 0,
        activeStep: 0,
      };

      localStorage.setItem("bookingData", JSON.stringify(bookingData));
      navigate("/reservation");
    } else {
      if (!location) {
        setLocationError(true);
      }
      if (!dropOffLocation) {
        setDropOffLocationError(true);
      }
    }
  };

  const goToBookNow = () => {
    navigate("/reservation");
  };

  const showAllCoupons = () => {
    setIsShowAllCoupons(true);
  };

  return (<>
    <div className="Banner">
      {isOfferShow && showedCoupon?.discount && (<div className={`${styles.OfferSec}`}>
        <p className={`${styles.OfferTitle}`}>
          <span className={`${styles.Span2}`}>SAVE {showedCoupon?.discount}%</span>
        </p>
        {showedCoupon?.code && (<p className={`${styles.OfferCouponText}`}>Coupon:{" "}<span className={`${styles.OfferCouponBold}`}>{showedCoupon?.code}</span></p>)}
        <p className={`${styles.OfferText}`}>{showedCoupon?.description}</p>
        {coupons.length > 0 && !isShowAllCoupons && (<p className={`${styles.OfferText} ${styles.OfferTextClick}`} onClick={showAllCoupons.bind(this)}>See all offers</p>)}
        {isShowAllCoupons && (<>
          {coupons.map((item, index) => {
            return (<>
              <p className={`${styles.OfferDiv}`}></p>
              <p className={`${styles.OfferTitle}`}>
                <span className={`${styles.Span2}`}>SAVE {item?.discount}%</span>
              </p>
              {item?.code && (<p className={`${styles.OfferCouponText}`}>
                Coupon:{" "}<span className={`${styles.OfferCouponBold}`}>{item?.code}</span>
              </p>)}
              <p className={`${styles.OfferText}`}>{item?.description}</p>
            </>);
          })}
        </>)}
        <button className={`${styles.OfferSecCloseBU}`} onClick={() => setIsOfferShow(false)}><X /></button>
      </div>)}

      <Parallax bgImage="/images/HomeBanner.jpg" strength={400}>
        <div style={{ height: 850 }} className={`${styles.BannerConArea} ${styles.Top}`}>
          <div className="container">
            <div className={`${styles.BannerContent}`}>

              <div className={`${styles.BannerConText}`}>
                <Fade bottom>
                  <p className={`${styles.BannerConTextB}`}>MOTU Car Rentals</p>
                </Fade>
                <Fade bottom>
                  <p className={`${styles.BannerConTextS}`}>made simple for you to rent a car!</p>
                </Fade>
              </div>

              <div className={isBoxFixed ? `${styles.BannerSearchArea} ${styles.BannerSearchAreaFixed}` : `${styles.BannerSearchArea}`}>
                <div className={`${styles.BannerSearchContainer}`}>
                  <div className={`${styles.BannerSearchLocation}`}>
                    <div className={`${styles.FixedFullSec}`}>
                      <label className={`${styles.BannerFormLabel} ${styles.LabelCus}`}>Pick Up Location</label>
                      <div className={`${styles.FormSec}`}>
                        <MapPin />
                        <Select
                          className={locationError ? `${styles.FormInput} ${styles.FormInputError}` : `${styles.FormInput}`}
                          value={location}
                          placeholder={"Select Pick Up Location"}
                          options={pickupLocationList}
                          onChange={changeAirport.bind(this)}
                          styles={style}
                        />
                      </div>
                    </div>
                    <div className={`${styles.FixedFullSec}`}>
                      <label className={`${styles.BannerFormLabel} ${styles.LabelCus}`}>Drop Off Location</label>
                      <div className={`${styles.FormSec}`}>
                        <MapPin />
                        <Select
                          className={dropOffLocationError ? `${styles.FormInput} ${styles.FormInputError}` : `${styles.FormInput}`}
                          value={dropOffLocation}
                          placeholder={"Select Drop Off Location"}
                          options={dropoffLocationList}
                          onChange={changeDropOffLocation.bind(this)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className={`${styles.BannerSearchDate}`}>
                    <div className={`${styles.FixedFullSec}`}>
                      <label className={`${styles.BannerFormLabel}`}>Pick-Up Date</label>
                      <div className={`${styles.FormSec}`}>
                        <Calendar />
                        <DatePicker
                          selected={startDate}
                          minDate={moment().toDate()}
                          maxDate={moment().add(1, "y").toDate()}
                          onChange={(date) => changeDate(this, date, "startDate")}
                          className={`${styles.FormInput}`}
                          dateFormat="eee, d MMM y"
                        />
                      </div>
                    </div>
                    <div className={`${styles.FixedFullSec}`}>
                      <label className={`${styles.BannerFormLabel}`}>Drop-Off Date</label>
                      <div className={`${styles.FormSec}`}>
                        <Calendar />
                        <DatePicker
                          selected={endDate}
                          minDate={startDate}
                          maxDate={moment().add(1, "y").toDate()}
                          onChange={(date) => changeDate(this, date, "endDate")}
                          className={`${styles.FormInput}`}
                          dateFormat="eee, d MMM y"
                        />
                      </div>
                    </div>
                  </div>

                  <div className={`${styles.BannerSearchTime}`}>
                    <div className={`${styles.FixedFullSec}`}>
                      <label className={`${styles.BannerFormLabel}`}>Pick-Up Time</label>
                      <div className={`${styles.FormSec}`}>
                        <Clock />
                        <DatePicker
                          selected={startTime}
                          onChange={(date) => changeDate(this, date, "startTime")}
                          className={`${styles.FormInput}`}
                          dateFormat="p"
                          showTimeSelect
                          timeFormat="p"
                          timeIntervals={30}
                          showTimeSelectOnly
                        />
                      </div>
                    </div>
                    <div className={`${styles.FixedFullSec}`}>
                      <label className={`${styles.BannerFormLabel}`}>Drop-Off Time</label>
                      <div className={`${styles.FormSec}`}>
                        <Clock />
                        <DatePicker
                          selected={endTime}
                          onChange={(date) => changeDate(this, date, "endTime")}
                          className={`${styles.FormInput}`}
                          dateFormat="p"
                          showTimeSelect
                          timeFormat="p"
                          timeIntervals={30}
                          showTimeSelectOnly
                        />
                      </div>
                    </div>
                  </div>

                  <div className={`${styles.BannerSearchButton}`}>
                    <button type="buttom" className={`${styles.SearchBU}`} onClick={addBooking.bind(this)}>
                      <span>Continue Reservation</span>
                    </button>
                    {isDiscount === 1 && (<p className={`${styles.CouponApplyText}`}><Check /> 10% off applied!</p>)}
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>
      </Parallax>
    </div>

    <div className={`${styles.BodyMessagesArea}`}>
      <img src="/images/CarBG.png" alt="" className={`${styles.CarBG}`} />
      <Fade right delay={800}>
        <img src="/images/BodyCar.png" alt="" className={`${styles.BodyCar}`} />
      </Fade>
      <div className="container">
        <div className={`${styles.BodyMRow}`}>
          <Fade bottom>
            <div className={`${styles.BodyFull}`}>
              <h3 className={`${styles.WhyChooseTitleM}`}>Why Choose Us</h3>
              <div className={`${styles.WhyChooseRow}`}>
                <div className={`${styles.WhyChooseDiv}`}>
                  <div className={`${styles.WhyChooseUnder}`}>
                    <img src="/images/why_choose_us_img1.svg" alt="" />
                    <h4 className={`${styles.WhyChooseTitle}`}>Pay online</h4>
                    <p className={`${styles.WhyChooseText}`}>Book a car, pay online, and receive a confirmation in real-time.</p>
                    <img src="/images/car_steering.svg" className={`${styles.WhyBGGra}`} alt="" />
                  </div>
                </div>

                <div className={`${styles.WhyChooseDiv}`}>
                  <div className={`${styles.WhyChooseUnder}`}>
                    <img src="/images/why_choose_us_img2.svg" alt="" />
                    <h4 className={`${styles.WhyChooseTitle}`}>Insured</h4>
                    <p className={`${styles.WhyChooseText}`}>All of the vehicles in our fleet have premium insurance.</p>
                    <img src="/images/car_steering.svg" className={`${styles.WhyBGGra}`} alt="" />
                  </div>
                </div>

                <div className={`${styles.WhyChooseDiv}`}>
                  <div className={`${styles.WhyChooseUnder}`}>
                    <img src="/images/why_choose_us_img3.svg" alt="" />
                    <h4 className={`${styles.WhyChooseTitle}`}>Add-Ons</h4>
                    <p className={`${styles.WhyChooseText}`}>Convenience at the click of a button. Choose from our extensive inventory to enhance your trip experience.</p>
                    <img src="/images/car_steering.svg" className={`${styles.WhyBGGra}`} alt="" />
                  </div>
                </div>

                <div className={`${styles.WhyChooseDiv}`}>
                  <div className={`${styles.WhyChooseUnder}`}>
                    <img src="/images/why_choose_us_img4.svg" alt="" />
                    <h4 className={`${styles.WhyChooseTitle}`}>Airport Delivery/ Return</h4>
                    <p className={`${styles.WhyChooseText}`}>Convenience delivery and return at the Faleolo International Airport.</p>
                    <img src="/images/car_steering.svg" className={`${styles.WhyBGGra}`} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </div>

    <Fade bottom>
      <div className="TrendingSec">
        <Parallax bgImage="/images/TrendingBanner.jpg" strength={400}>
          <div style={{ height: "100%" }} className={`${styles.BannerConArea} ${styles.Black}`}>
            <img src="/images/TrendingWaveUp.svg" alt="" className={`${styles.TrendingWaveTop}`} />
            <div className="container">
              <div className={`${styles.TrendingContent}`}>
                <h3 className={`${styles.TrendingTitle}`}>Feel the best experience with our rentals deals</h3>
                <div className={`${styles.TrendingCarRow}`}>
                  <div className={`${styles.ProductSlider} ProductSlider`}>
                    <Carousel
                      swipeable={true}
                      arrows={true}
                      draggable={true}
                      showDots={false}
                      responsive={responsive}
                      ssr={true}
                      infinite={true}
                      autoPlay={true}
                      autoPlaySpeed={5000}
                      keyBoardControl={true}
                      customTransition="2000ms all"
                      transitionDuration={100}
                      containerClass="carousel-container"
                      deviceType={props.deviceType}
                      dotListClass="custom-dot-list-style"
                      itemClass="carousel-item-padding-40-px"
                    >
                      {carList?.map((item, index) => {
                        return (<Fade delay={400} key={index}>
                          <div className={`${styles.TrendingCarSec}`}>
                            <img src={item.image_url} alt="" />
                            <div className={`${styles.TrendingCarConSec}`}>
                              <p className={`${styles.TrendingCarConTitle}`}>{item.title}</p>
                              <p className={`${styles.TrendingCarConTitleSM}`}>{item.type}</p>
                              <p className={`${styles.TrendingCarConTitleSM}`}><span>{item.transmission}</span></p>
                              <div className={`${styles.TrendingBUSec}`}>
                                <button className={`${styles.TrendingBUBrown}`}>
                                  <span>{currency}{" "}{!currencyLoading && (item.price * currencyRate).toFixed(2) + "/Day"}</span>
                                </button>
                                <button className={`${styles.TrendingBUGreen}`} onClick={goToBookNow.bind(this)}>
                                  <span>Book Now</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </Fade>);
                      })}
                    </Carousel>
                  </div>
                </div>
              </div>
            </div>
            <img src="/images/TrendingWaveDown.svg" alt="" className={`${styles.TrendingWaveDown}`} />
          </div>
        </Parallax>
      </div>
    </Fade>

    <div className={`${styles.CustomerReview} CustomerReview`}>
      <Fade bottom>
        <div className="container">
          <h3 className={`${styles.CustomerReviewTitle}`}>Hear it from Our Customers</h3>
          <div className={`${styles.ReviewSlider}`}>
            <Carousel
              swipeable={true}
              arrows={true}
              draggable={true}
              showDots={false}
              responsive={responsive}
              ssr={true}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={5000000}
              keyBoardControl={true}
              customTransition="2000ms all"
              transitionDuration={400}
              containerClass="carousel-container"
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
            >
              {testimonials.map((item) => {
                return (<div className={`${styles.ReviewSlide}`} key={item?.id}>
                  <div className={`${styles.ReviewSlideInner}`}>
                    <p className={`${styles.ReviewSlideInnerTitle}`}>{item?.car_with_image?.title}</p>
                    <div className={`${styles.ReviewProfile}`}>
                      <div className={`${styles.ReviewProfileSec}`}>
                        <img src="/images/user_avatar_new.png" alt="" />
                        <div className={`${styles.ReviewProfileDetails}`}>
                          <p className={`${styles.Title}`}>{item?.name}</p>
                        </div>
                      </div>
                      <p className={`${styles.ReviewRatingSec}`}>
                        <Rating value={item?.rating} readOnly emptyIcon={<Star style={{ opacity: 0.4 }} />} />
                        <span className={`${styles.Rating}`}>{item?.rating}/5</span>
                      </p>
                    </div>
                    <p className={`${styles.ReviewSlideInnerTitleSM}`}>{item?.comments}</p>
                  </div>
                </div>);
              })}
            </Carousel>
          </div>
        </div>
      </Fade>
    </div>
  </>);
};

export default withNavigateHook(Home);